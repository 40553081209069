/* eslint-disable import/prefer-default-export */
import React from 'react';
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import Link from 'components/Link';
import ToastContents from 'components/ToastContents';
import { SUBSCRIPTION_STATUS } from 'constants/subscription';
import {
  USER_REFRESH_START,
  USER_REFRESH_SUCCESS,
  USER_REFRESH_ERROR,
  MARKETING_NUMBER_OF_RECIPES,
} from '../../constants';
import SubscriptionFields from './SubscriptionFields.graphql';

const USER_QUERY = gql`
  query GetUser {
    User {
      auth0_id
      author_id
      avatar
      bsh_assisted_cooking_enabled
      collection_tags
      display_name
      email_confirmed
      email
      geolocation {
        countryCode
        continent
        isEU
        corporate
      }
      home_connect_enabled
      home_connect_preferred_oven
      home_connect_token_expires
      home_connect_token
      home_connect_used
      home_country
      id
      issued_at
      last_login_at
      logged_in
      oven_brands
      unit_system
      owned_books
      permissions
      registered_at
      subscription_status
      temperature_unit
      subscription {
        ...SubscriptionFields
      }
      settings {
        id
        search_filters_visible
        show_alacarte_content
        ingredient_shop_vendor
        collection_picker_sort_order
        collection_view_sort_order
      }
    }
  }
  ${SubscriptionFields}
`;

function toastNewSubscription({ oldUser, newUser }) {
  if (
    oldUser.id === newUser.id &&
    ((oldUser.id > 0 &&
      !oldUser.subscription &&
      newUser.subscription &&
      newUser.subscription.is_valid) ||
      (oldUser.subscription?.id &&
        oldUser.subscription.status === SUBSCRIPTION_STATUS.DELETED &&
        newUser.subscription.status !== SUBSCRIPTION_STATUS.DELETED))
  ) {
    toast.success(
      <ToastContents headline="You are now a ckbk Premium Member">
        You are now free to explore over{' '}
        <Link to="/search?q=recipes" className="link link--energized">
          {MARKETING_NUMBER_OF_RECIPES} recipes
        </Link>{' '}
        from the world&apos;s best{' '}
        <Link to="/books" className="link link--energized">
          cookbooks
        </Link>. Happy cooking!
      </ToastContents>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 8000,
        hideProgressBar: true,
      },
    );
  }
}

export function refreshUser(user, extraSubscriptionParams = {}, shouldToastNewSubscription = true) {
  return async (dispatch, getState, { client }) => {
    if (getState().user.loading) {
      // Prevent parallel requests
      return;
    }
    dispatch({
      type: USER_REFRESH_START,
    });
    try {
      if (!user) {
        const { data } = await client.query({
          query: USER_QUERY,
          variables: { _authenticated: true },
          fetchPolicy: 'network-only',
        });
        // eslint-disable-next-line no-param-reassign
        user = data.User;
      }
      const oldUser = getState().user;
      if (oldUser.id && oldUser.id > 0 && !user.id && !window.cordova) {
        // Logout in another tab?
        window.location = `/logout?returnTo=${encodeURIComponent(getState().router.pathname)}`;
      }
      if (oldUser.id && oldUser.id > 0 && oldUser.id !== user.id && !window.cordova) {
        console.info('User changed', oldUser.id, user.id);
        client.clearStore();
      }
      await dispatch({
        type: USER_REFRESH_SUCCESS,
        payload: {
          user: {
            ...user,
            subscription: user.subscription
              ? { ...extraSubscriptionParams, ...user.subscription }
              : null,
          },
        },
      });
      if (shouldToastNewSubscription && user.subscription?.id) {
        toastNewSubscription({
          oldUser,
          newUser: user,
        });
      }
      const newUser = getState().user;
      try {
        localStorage.setItem('user', JSON.stringify({ ...newUser, collections: {} }));
      } catch (error) {
        console.error('Failed to store user', error);
      }
      if (window?.cordova?.plugins?.firebase?.analytics && newUser.id > 0) {
        window.cordova.plugins.firebase.analytics.setUserId(`${newUser.id}`);
        window.cordova.plugins.firebase.analytics.setUserProperty(
          'subscription_status',
          newUser.subscription_status,
        );
      }
    } catch (error) {
      dispatch({
        type: USER_REFRESH_ERROR,
        payload: {
          error: error.graphQLErrors && error.graphQLErrors.length ? error.graphQLErrors[0] : error,
        },
      });
      throw error;
    }
  };
}

export function pollUser(extraSubscriptionParams) {
  return async dispatch => {
    // After a checkout completed various updates will occur to the subscription
    // on the backend, in a seemingly random order.
    // To ensure we give the right info we need to poll for the next 20 seconds.
    // GraphQL subscriptions would be useful here.
    for (let delay = 1000; delay <= 20000; delay += 2000) {
      setTimeout(() => dispatch(refreshUser(null, extraSubscriptionParams)), delay);
    }
  };
}

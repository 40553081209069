function getSystemTheme() {
  return new Promise(resolve => {
    if (window.cordova && window.cordova.plugins && window.cordova.plugins.ThemeDetection) {
      window.cordova.plugins.ThemeDetection.isDarkModeEnabled(
        res => {
          resolve(res.value ? 'dark' : 'light');
        },
        err => {
          console.error('Error detecting theme:', err);
          resolve('system');
        },
      );
    }
  });
}

export default getSystemTheme;

/* eslint-disable camelcase */

// node modules
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// app modules: services
import history from 'services/history';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

class Intercom extends Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    displayType: PropTypes.string.isRequired,
  };

  /**
   * Updates Intercom when URL changes in app.
   */
  static onLocationChange() {
    if (window.Intercom) {
      window.Intercom('update');
    }
  }

  constructor(props) {
    super(props);

    this.handler = {
      onLocationChange: null,
    };
  }

  componentDidMount() {
    this.handler.onLocationChange = history.listen(Intercom.onLocationChange);
    this.initIntercom();
    this.updateIntercom('boot');
  }

  componentDidUpdate() {
    this.updateIntercom('update');
  }

  /**
   * Unbinds events, shutdown Intercom, removes global objects.
   */
  componentWillUnmount() {
    if (!canUseDOM || !window.Intercom) {
      return;
    }

    this.handler.onLocationChange();
    window.Intercom('shutdown');
    delete window.intercomSettings;
    delete window.Intercom;
  }

  initIntercom() {
    const { appId } = this.props;

    if (!appId || !canUseDOM) {
      return;
    }
    /* eslint-disable */
    if (typeof window.Intercom === 'undefined') {
      const i = function() {
        i.c(arguments);
      };

      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      window.Intercom = i;

      function onload() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = `https://widget.intercom.io/widget/${appId}`;
        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(script, x);
      }

      if (window.attachEvent) {
        window.attachEvent('onload', onload);
      } else {
        window.addEventListener('load', onload, false);
      }
      /* eslint-disable */
    }
  }

  /**
   * @param  {String} command ['boot', 'update']
   */
  updateIntercom(command) {
    const { appId, userData, displayType } = this.props;

    if (!appId || !canUseDOM) {
      return;
    }

    // Don't display on the mobile and app display
    const shouldHide = displayType === 'mobile';
    window.intercomSettings = Object.assign(
      {
        app_id: appId,
        hide_default_launcher: shouldHide,
      },
      userData,
    );

    if (window.Intercom) {
      window.Intercom(command);
    }
  }

  render() {
    return false;
  }
}

const mapStateToProps = state => ({
  appId: state.config.intercom.appId,
  displayType: state.dimensions.displayType,
});

export default connect(
  mapStateToProps,
  null,
)(Intercom);

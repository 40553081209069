/* eslint-disable import/prefer-default-export */

/**
 * @param  {Object}   params
 * @param  {Function} params.action
 * @param  {Object}   params.params
 * @param  {Function} params.payload
 * @param  {Object}   params.states
 * @param  {String}   params.states.start
 * @param  {String}   params.states.success
 * @param  {String}   params.states.error
 * @param  {Object}   params.events
 */
export function genericAction({ action, params, payload, states }) {
  return async (dispatch, getState, context) => {
    dispatch({
      type: states.start,
      payload: params,
    });

    try {
      const { data, errors } = await action(context, getState);

      if (errors && errors.length > 0) {
        dispatch({
          type: states.error,
          payload: {
            errors: errors[0].graphQLErrors,
          },
        });
      } else {
        dispatch({
          type: states.success,
          payload: payload(data),
        });
      }
    } catch (error) {
      dispatch({
        type: states.error,
        payload: {
          errors: error.graphQLErrors,
        },
      });
    }
  };
}

/* eslint-disable import/prefer-default-export */
export const SUBSCRIPTION_STAGE = {
  IDENTIFICATION: 'identification',
  PLAN: 'plan',
  BILLING: 'billing',
  COMPLETE: 'complete',
};

export const SUBSCRIPTION_STATUS = {
  // The user has recently purchased a subscription and the status will be updated shortly.
  PENDING: 'pending',
  // The user has a subscription and payments are up to date.
  ACTIVE: 'active',
  // The user has a trial subscription
  TRIALING: 'trialing',
  // The user has a subscription, but the previous payment failed.
  PAST_DUE: 'past_due',
  // The subscription was cancelled.
  DELETED: 'deleted',
  // The subscription was paused.
  PAUSED: 'paused',
};

export const PAUSED_REASONS = {
  DELINQUENT: 'delinquent',
  VOLUNTARY: 'voluntary',
};

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".layout-user-menu{font-size:13.712px;font-size:0.857rem;min-width:80px;width:190px;color:#000}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .layout-user-menu{color:#fff}}:root[data-theme=dark] .layout-user-menu{color:#fff}.layout-user-menu__header{align-items:center;display:flex;padding:10px}.layout-user-menu__avatar{flex-shrink:0}.layout-user-menu__username{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;font-weight:600;margin-left:10px}.layout-user-menu__item{border-top:1px solid #dddddd}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .layout-user-menu__item{border-top-color:#333}}:root[data-theme=dark] .layout-user-menu__item{border-top-color:#333}.layout-user-menu__icon{margin-top:-4px}.layout-user-menu__link{display:flex;justify-content:space-between;padding:12px 15px;transition:background-color .195s ease-in-out}.layout-user-menu__link:hover{background-color:#f4f5f7}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .layout-user-menu__link:hover{background-color:#2a2c2f}}:root[data-theme=dark] .layout-user-menu__link:hover{background-color:#2a2c2f}.layout-user-menu__link--last{border-radius:0 0 6px 6px}\n", ""]);

// exports
exports.locals = {
	"layout-user-menu": "layout-user-menu",
	"layout-user-menu__header": "layout-user-menu__header",
	"layout-user-menu__avatar": "layout-user-menu__avatar",
	"layout-user-menu__username": "layout-user-menu__username",
	"layout-user-menu__item": "layout-user-menu__item",
	"layout-user-menu__icon": "layout-user-menu__icon",
	"layout-user-menu__link": "layout-user-menu__link",
	"layout-user-menu__link--last": "layout-user-menu__link--last"
};
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import createLogger from './logger';
import createRootReducer from '../reducers';

let store = null;

export function getStore() {
  return store;
}

export function getState() {
  return store ? store.getState() : {};
}

export default function configureStore(initialState, helpers) {
  const rootReducer = createRootReducer();
  const middleware = [thunk.withExtraArgument(helpers)];

  if (__DEV__) {
    middleware.push(createLogger());
  }

  const enhancer = applyMiddleware(...middleware);

  // See https://github.com/reactjs/redux/releases/tag/v3.1.0
  store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('../reducers', () =>
      // Don't forget to remove `()` if you change reducers back to normal rootReducer.
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default()),
    );
  }

  return store;
}

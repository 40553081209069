import {
  PAYWALL_CONFIGURE,
  CHECKOUT_EVENT,
  APPLY_COUPON,
  APPLY_PRODUCT_START,
  APPLY_PRODUCT_SUCCESS,
  LOAD_PRODUCT_PRICES_SUCCESS,
} from '../constants';

const NON_NUMERIC = /[^0-9.]/g;

function toNumericPrice(amount) {
  if (!amount) {
    return null;
  }
  return parseFloat(`${amount}`.replace(',', '.').replace(NON_NUMERIC, ''));
}

function processPaddleEvent(event, state) {
  if (!event.event || event.event === 'Checkout.Coupon.Submit') {
    // Ignore this event because it sets the coupon code before it has been validated.
    // Wait instead for the Checkout.Coupon.Applied event
    return state;
  }
  if (event.event === 'LOADED') {
    return { ...state, paddleLoaded: true };
  }
  const { checkout, product } = event.eventData;
  const { coupon } = event.checkoutData;
  const checkoutPrices = checkout.recurring_prices || checkout.prices;
  const totalPriceNumeric = toNumericPrice(checkoutPrices?.customer?.total);
  const listPrice = state.products[product.id] ? state.products[product.id].listPrice : null;
  const isDiscount = listPrice && listPrice > totalPriceNumeric;

  const newState = {
    recurrance: checkoutPrices?.interval?.type,
    totalPrice: totalPriceNumeric,
    currency: checkoutPrices?.customer?.currency,
    coupon: isDiscount ? coupon : null,
    // The above fields are deprecated in favour of this map of products.
    products: {
      ...state.products,
      [product.id]: {
        ...state.products[product.id],
        id: product.id,
        name: product.name,
        recurringPrice: totalPriceNumeric,
        recurrance: checkoutPrices?.interval?.type,
        currency: checkoutPrices?.customer?.currency,
        coupon: isDiscount ? coupon : null,
      },
    },
  };
  switch (event.event) {
    case 'Checkout.Coupon.Applied':
      newState.coupon = event.eventData.coupon.coupon_code;
      newState.requestedCoupon = event.eventData.coupon.coupon_code;
      newState.couponWasManuallySet = true;
      break;

    case 'Checkout.Coupon.Remove':
      newState.coupon = null;
      newState.requestedCoupon = null;
      newState.couponWasManuallySet = true;
      break;

    case 'Checkout.Location.Submit':
      newState.postcode = event.eventData.postcode;
      newState.country = event.eventData.country;
      break;

    default:
  }

  return newState;
}

function updateProductPrices(productId, prices, state) {
  // eslint-disable-next-line camelcase
  const { trial_days, length, type } = prices.recurring.subscription;
  const { gross } = prices.recurring.price;
  return {
    ...state.products,
    [productId]: {
      ...state.products[productId],
      id: productId,
      trialDays: trial_days,
      length,
      lengthUnit: type,
      listPrice: toNumericPrice(gross),
    },
  };
}

export default function paywallReducers(state = {}, action) {
  const payload = action.payload || {};
  switch (action.type) {
    case PAYWALL_CONFIGURE:
      return Object.assign({}, state, payload);

    case CHECKOUT_EVENT:
      return Object.assign({}, state, processPaddleEvent(payload, state));

    case APPLY_COUPON:
      return Object.assign({}, state, { requestedCoupon: payload });

    case APPLY_PRODUCT_START:
      return Object.assign({}, state, {
        productId: payload.productId,
        canSkip: payload.canSkip,
      });

    case APPLY_PRODUCT_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          productId: payload.productId,
          canSkip: payload.canSkip,
        },
        { products: updateProductPrices(payload.productId, payload.prices, state) },
      );

    case LOAD_PRODUCT_PRICES_SUCCESS:
      return { ...state, products: updateProductPrices(payload.productId, payload.prices, state) };

    default:
      return state;
  }
}

// node modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';

// assets
import CssHeading from './Heading.scss';

import NON_WESTERN_LANGUAGES from '../../constants/non-western-languages';

class Heading extends Component {
  static propTypes = {
    level: PropTypes.number,
    className: PropTypes.string,
    subtitleLang: PropTypes.string,
    children: PropTypes.node,
    withDash: PropTypes.bool,
  };

  static defaultProps = {
    level: 2,
    className: '',
    subtitleLang: 'en',
    children: '',
    withDash: false,
  };

  getLanguageSpecificFont(subtitleLang) {
    const robotoLangs = ['vi', 'ru', 'uk', 'ja'];

    if (robotoLangs.includes(subtitleLang)) {
      return 'heading--non-western-roboto-condensed';
    }

    if (NON_WESTERN_LANGUAGES.includes(subtitleLang)) {
      return 'heading--non-western-font';
    }

    return '';
  }

  render() {
    const { level, withDash, className, children, subtitleLang, ...others } = this.props;
    const CustomTag = `h${level}`;
    const headingClassName = classNames(
      'heading',
      className,
      this.getLanguageSpecificFont(subtitleLang),
      {
        'heading--with-dash': withDash,
      },
    );
    return (
      <CustomTag className={headingClassName} {...others}>
        {children}
      </CustomTag>
    );
  }
}

export default withStyles(CssHeading)(Heading);

/* eslint-disable camelcase */
import React, { useEffect, useContext } from 'react';
import { createRoot } from 'react-dom';
import { gql, getApolloContext } from '@apollo/client';

// app: components
import Price from 'components/Price';

const QUERY = gql`
  query GetPriceForPlaceholder($provider: String!, $productId: String!, $coupon: String) {
    Price(provider: $provider, productId: $productId, coupon: $coupon) {
      currency
      list_price
      offer_price
    }
  }
`;

function usePriceInjector(containerRef, selector = '.ckbk-price') {
  const { client } = useContext(getApolloContext());
  useEffect(() => {
    let root;
    if (containerRef.current) {
      const prices = Array.from(containerRef.current.querySelectorAll(selector));
      prices.forEach(price => {
        const { productId, coupon, showOriginal } = price.dataset;
        client
          .query({
            query: QUERY,
            variables: {
              productId,
              coupon,
              provider: 'paddle',
            },
          })
          .then(({ loading, error, data }) => {
            const { currency, list_price, offer_price } = data.Price;
            if (loading) {
              return;
            }
            if (error) {
              console.error('Cannot render price into placeholder', error);
              return;
            }
            root = createRoot(price);
            root.render(
              <Price
                currency={currency}
                amount={offer_price}
                originalPrice={showOriginal ? list_price : null}
              />,
            );
          });
      });
    }
    return () => {
      if (root) {
        root.unmount();
        root = null;
      }
    };
  });
}

export default usePriceInjector;

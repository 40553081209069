exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".videoWrapper{height:0;padding-bottom:56.25%;padding-top:25px;position:relative}.videoWrapper iframe{height:100%;left:0;position:absolute;top:0;width:100%}\n", ""]);

// exports
exports.locals = {
	"videoWrapper": "videoWrapper"
};
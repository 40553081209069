export default [
  'ab',
  'am',
  'ar',
  'as',
  'ba',
  'be',
  'bg',
  'bh',
  'bn',
  'bo',
  'ce',
  'cr',
  'cu',
  'cv',
  'dz',
  'el',
  'fa',
  'gu',
  'ha',
  'he',
  'hi',
  'hy',
  'ii',
  'iu',
  'ja',
  'ka',
  'kk',
  'km',
  'kn',
  'ko',
  'ks',
  'kv',
  'ky',
  'lo',
  'mk',
  'ml',
  'mn',
  'mr',
  'my',
  'ne',
  'oj',
  'or',
  'pa',
  'pi',
  'ps',
  'sa',
  'sd',
  'si',
  'ta',
  'te',
  'tg',
  'th',
  'ti',
  'tl',
  'tt',
  'ug',
  'uk',
  'ur',
  'uz',
  'vi',
  'yi',
  'za',
  'zh',
];

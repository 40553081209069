/* eslint-disable  consistent-return */

// node modules
import React from 'react';
import UniversalRouter from 'universal-router';

// app modules: error components
import Generic from 'components/Error/Generic';
import NotFound from 'components/Error/NotFound';
import NoLongerAvailable from 'components/Error/NoLongerAvailable';
import TerritorialRestriction from 'components/Error/TerritorialRestriction';

// app modules: collection error components
import CollectionUnauthenticatedError from 'components/Error/Collection/Unauthenticated';
import CollectionForbiddenError from 'components/Error/Collection/Forbidden';

import routes from './routes';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (typeof context.route.load === 'function') {
      return context.route.load().then(action => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
  async errorHandler(error) {
    // This should be an error handler for exceptional conditions that happen during routing.
    // As we move towards all GQL being done via hooks in the render cycle these error handlers should move into
    // The component that generates the error. That seems better separation of concerns too
    // (e.g, collections error handled in the collections view)
    if (error.graphQLErrors) {
      const graphQLError = error.graphQLErrors[0];

      const {
        default: Layout,
      } = await import('components/Layout' /* webpackChunkName: "router-error-layout", webpackMode: "lazy" */);

      if (graphQLError && graphQLError.extensions && graphQLError.extensions.code) {
        switch (graphQLError.extensions.code) {
          case 'UNAUTHENTICATED': {
            return {
              title: graphQLError.message,
              component: (
                <Layout>
                  <Generic>
                    <p>{graphQLError.message}</p>
                  </Generic>
                </Layout>
              ),
              status: 401,
            };
          }

          case 'UNAUTHENTICATED_COLLECTION': {
            return {
              title: graphQLError.message,
              component: (
                <Layout>
                  <CollectionUnauthenticatedError />
                </Layout>
              ),
              status: 401,
            };
          }

          case 'FORBIDDEN': {
            return {
              title: graphQLError.message,
              component: (
                <Layout>
                  <Generic>
                    <p>{graphQLError.message}</p>
                  </Generic>
                </Layout>
              ),
              status: 403,
            };
          }

          case 'FORBIDDEN_COLLECTION': {
            return {
              title: graphQLError.message,
              component: (
                <Layout>
                  <CollectionForbiddenError />
                </Layout>
              ),
              status: 403,
            };
          }

          // TODO handle all the below in the Recipe view component.
          // Recipe already done. Still need to do sections and references.
          case 'TERRITORIAL_RESTRICTION': {
            return {
              title: 'Content not available',
              component: (
                <Layout>
                  <TerritorialRestriction />
                </Layout>
              ),
            };
          }

          case 'NO_LONGER_AVAILABLE': {
            return {
              title: 'Content no longer available',
              component: (
                <Layout>
                  <NoLongerAvailable />
                </Layout>
              ),
              status: 404,
            };
          }

          default: {
            return {
              chunks: ['not-found'],
              title: 'Page Not Found',
              component: (
                <Layout>
                  <NotFound />
                </Layout>
              ),
              status: 404,
            };
          }
        }
      }
    } else {
      console.error('Unhandled error in router', error);
      if (typeof window !== 'undefined' && window.Cypress) {
        // This should cause a failure in cypress
        throw error;
      }
    }
  },
});

import { SHOW_METERED_ACCESS_BANNER, HIDE_METERED_ACCESS_BANNER } from '../constants';

export default function modalsReducers(state = {}, action) {
  const { extended, accessPolicy } = action.payload || {};
  switch (action.type) {
    case SHOW_METERED_ACCESS_BANNER:
      return { ...state, visible: true, accessPolicy, extended };

    case HIDE_METERED_ACCESS_BANNER:
      return { ...state, visible: false, extended: false, accessPolicy };

    default:
      return state;
  }
}

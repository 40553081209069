/* eslint-disable camelcase */
import foldToAscii from 'fold-to-ascii';
import { stringify } from 'qs';
import { BOOK_TOP_LEVEL_CATEGORIES } from '../constants';

const REGEX = {
  SOURCE: /(\w+)(b|c|f)\d+\w[a-z]*\d+/,
  RECIPE: /\w+(b|c|f)\d+\w*r[a-z]*\d+/,
  REFERENCE: /\w+(b|c|f)\d+\w*\d+e(ntry)?\d+/,
  IMAGE: /g\d+$/,
  CHAPTER: /^[a-z]+\d+(b|c|f)\d+$/,
  CHAPTER_SECTION: /^[a-z]+\d+(b|c|f)\d+s001$/,
  MULTI_PART_ID: /^(.*\d{2,3})((ff|n|sv|v|fn|t|bib)\d+|step[A-Z0-9]+|sp\d+|ch\d+(?:ff|n|sv|v|fn|t|bib)\d+)$/i,
  RELATIVE: /^ch\d+(?:f+|n|sv|v|fn)\d+$/i,
};

export function escapeTitle(value) {
  let title = value;
  if (typeof value !== 'string') {
    title = value.length ? value[0] : '';
  }
  return foldToAscii
    .fold(title.toLowerCase())
    .replace(/[^\w\d- ]+/g, '')
    .replace(/\s$/g, '')
    .replace(/\s+/g, '-')
    .substring(0, 500);
}

export function booksUrl({ tab, category, viewType, orderBy, orderByDirection, query }) {
  const path = [];
  const qs = {};
  if (!tab || (tab === 'home' && !category) || BOOK_TOP_LEVEL_CATEGORIES.includes(category)) {
    path.push('/books');
  } else if (tab === 'authors' && !category) {
    path.push('/authors');
  } else {
    path.push('/books', encodeURIComponent(tab));
  }
  if (category) {
    path.push(encodeURIComponent(category));
    if (viewType) {
      path.push(viewType);
    }
    if (orderBy) {
      qs.sort = orderBy;
    }
    if (orderByDirection) {
      qs.dir = orderByDirection;
    }
  } else {
    if (orderBy) {
      path.push(orderBy);
    }
    if (orderByDirection) {
      path.push(orderByDirection);
    }
  }
  if (query) {
    qs.q = query;
  }
  if (Object.keys(qs).length > 0) {
    return `${path.join('/')}?${stringify(qs, { skipNulls: true })}`;
  }
  return path.join('/');
}

export function personUrl(id, isAuthor) {
  if (isAuthor) {
    return `/authors/${id}`;
  }
  return `/people/${id}`;
}

export function voucherClaim(voucher, partner) {
  if (partner) {
    return `/claim/${partner}?voucher=${voucher}`;
  }
  return `/claim?voucher=${voucher}`;
}

export default {
  contentSource(id) {
    const match = id.match(REGEX.SOURCE);
    return match && match[1];
  },

  contentType(id) {
    if (REGEX.IMAGE.test(id)) {
      return 'image';
    } else if (REGEX.RECIPE.test(id)) {
      return 'recipe';
    } else if (REGEX.REFERENCE.test(id)) {
      return 'reference';
    }

    return 'section';
  },

  isChapterSection(id) {
    return REGEX.CHAPTER_SECTION.test(id);
  },

  /**
   * @param  {String} id
   * @return {String}
   */
  content(id, title) {
    const type = this.contentType(id);

    // Feature-Fixed and Notes and Steps should link to the main page with a hash.
    // For example, thes35650c10s001r019ff001 should become thes35650c10s001r019#ff001
    let hash = '';
    let idWithoutHash = id;
    const multiPartId = id.match(REGEX.MULTI_PART_ID);

    if (multiPartId) {
      // eslint-disable-next-line no-param-reassign
      [id, idWithoutHash, hash] = multiPartId;
      hash = `#${hash}`;
    } else if (REGEX.RELATIVE.test(idWithoutHash)) {
      return `#${idWithoutHash}`;
    }
    // Experimental - we don't have pages for chapters,
    // but linking to the first section in the chapter should work in most cases.
    if (REGEX.CHAPTER.test(idWithoutHash)) {
      idWithoutHash = `${idWithoutHash}s001`;
    }

    const titleSuffix = title ? `/${escapeTitle(title)}` : '';

    switch (type) {
      case 'recipe':
        return `/recipe/${idWithoutHash}${titleSuffix}${hash}`;
      case 'reference':
        return `/reference/${idWithoutHash}${titleSuffix}${hash}`;
      case 'image':
        return `/images/${id}.jpg`;
      default:
        return `/section/${idWithoutHash}${titleSuffix}${hash}`;
    }
  },

  /**
   * @param  {String} isbn
   * @param  {String} signature_
   * @return {String}
   */
  book(isbn, signature_) {
    return `/book/${isbn}/${signature_}`;
  },

  alacartePurchase(isbn, signature_) {
    if (signature_) {
      return `/book/${isbn}/${signature_}/buy`;
    }
    return `/book/${isbn}/buy`;
  },

  books: booksUrl,

  /**
   * @param  {String} id
   * @param  {Boolean} isAuthor
   * @return {String}
   */
  person: personUrl,

  blogPost(id) {
    return `/features/${id}`;
  },

  blogPosts(params) {
    if (params?.category) {
      return `/features/category/${encodeURIComponent(params.category).replace(/%20/g, '+')}`;
    }
    if (params?.tag) {
      if (Array.isArray(params.tag)) {
        if (params.tag.length) {
          return `/features/tag/${params.tag
            .map(tag => encodeURIComponent(tag).replace(/%20/g, '+'))
            .join(',')}`;
        }
        return '/features';
      }
      return `/features/tag/${encodeURIComponent(params.tag).replace(/%20/g, '+')}`;
    }
    return '/features';
  },

  /**
   * @type {Object}
   */
  collection: {
    /**
     * @param  {String} id
     * @return {String}
     */
    view({ id, name, share_key, visibility }, tab) {
      const base = `/collection/${id}`;
      const escapedTitle = name ? escapeTitle(name) : '';
      let titlePath = '';
      // avoid collisions between :tab and :title parts of the route
      if (
        escapedTitle &&
        escapedTitle !== 'books' &&
        escapedTitle !== 'people' &&
        escapedTitle !== 'edit'
      ) {
        titlePath = `/${escapedTitle}`;
      }
      const tabPath = tab ? `/${tab}` : '';
      const shareKeyPath = share_key && visibility === 'unlisted' ? `/${share_key}` : '';
      return `${base}${shareKeyPath}${titlePath}${tabPath}`;
    },

    /**
     * @param  {String} id
     * @return {String}
     */
    edit(id) {
      return `/collection/${id}/edit`;
    },
  },
};

import PropTypes from 'prop-types';
import { SUBSCRIPTION_STATUS } from 'constants/subscription';

export const SubscriptionProps = PropTypes.shape({
  status: PropTypes.oneOf([
    SUBSCRIPTION_STATUS.PENDING,
    SUBSCRIPTION_STATUS.ACTIVE,
    SUBSCRIPTION_STATUS.TRIALING,
    SUBSCRIPTION_STATUS.PAST_DUE,
    SUBSCRIPTION_STATUS.DELETED,
    SUBSCRIPTION_STATUS.PAUSED,
  ]),
  next_bill_date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  cancellation_effective_date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  is_valid: PropTypes.bool,
  update_url: PropTypes.string,
});

export default PropTypes.shape({
  avatar: PropTypes.string,
  display_name: PropTypes.string,
  email: PropTypes.string,
  logged_in: PropTypes.bool,
  geolocation: PropTypes.shape({
    countryCode: PropTypes.string,
  }),
  provider: PropTypes.string,
  provider_id: PropTypes.string,
  subscription: SubscriptionProps,
});

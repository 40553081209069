// node modules
import React, { PureComponent, Fragment } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';

import CssPrice from './Price.scss';

const NON_NUMERIC = /[^0-9]/g;

class Price extends PureComponent {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    originalPriceClassName: PropTypes.string,
    language: PropTypes.string,
    reverse: PropTypes.bool,
  };

  static defaultProps = {
    originalPrice: null,
    originalPriceClassName: null,
    language: undefined,
    reverse: false,
  };

  render() {
    // Various APIs return prices in different formats... some as strings, others as numbers.
    // Some with currency symbols/codes, others without.
    // This aims to work with whatever we are given, hence some awkward conversions back and forth.
    const {
      currency,
      amount,
      originalPrice,
      originalPriceClassName,
      language,
      reverse,
    } = this.props;

    if (!amount && amount !== 0) {
      return null;
    }

    let convertedAmount = amount;
    if (typeof amount === 'string') {
      convertedAmount = parseFloat(amount) || amount;
    }

    let convertedOriginalPrice = originalPrice;
    if (typeof originalPrice === 'string') {
      convertedOriginalPrice = parseFloat(originalPrice) || originalPrice;
    }

    let formattedOriginalPrice = null;
    if (convertedOriginalPrice && convertedOriginalPrice !== convertedAmount) {
      formattedOriginalPrice = (
        <Fragment>
          {reverse ? null : ' '}
          <s className={originalPriceClassName}>
            <Price currency={currency} amount={convertedOriginalPrice} />
          </s>
          {reverse ? ' ' : null}
        </Fragment>
      );
    }

    let formattedAmount =
      typeof convertedAmount === 'number'
        ? convertedAmount.toLocaleString(
            language || undefined,
            { style: 'currency', currency },
          )
        : convertedAmount;

    // If its USD, always display as $ to match the nodeJS rendering
    if (formattedAmount.startsWith('US$')) {
      formattedAmount = formattedAmount.slice(2);
    }

    let shouldShowOriginal = false;
    if (convertedOriginalPrice) {
      // Force comparison to only consider numeric part of the string
      const numericOriginalPrice = `${convertedOriginalPrice}`.replace(NON_NUMERIC, '');
      const numericAmount = `${formattedAmount}`.replace(NON_NUMERIC, '');
      shouldShowOriginal = numericOriginalPrice !== numericAmount;
    }

    try {
      if (reverse) {
        return (
          <span className="price">
            {shouldShowOriginal ? formattedOriginalPrice : null}
            {formattedAmount}
          </span>
        );
      }
      return (
        <span className="price">
          {formattedAmount}
          {shouldShowOriginal ? formattedOriginalPrice : null}
        </span>
      );
    } catch (error) {
      return (
        <span className="price">
          ${amount} ${currency}
        </span>
      );
    }
  }
}

export default withStyles(CssPrice)(Price);

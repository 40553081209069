import {
  CONVERSION_SET_INITIAL_DATA,
  CONVERSION_SET_CONVERTED_INGREDIENTS,
  CONVERSION_SWITCH_CURRENT_MEASUREMENT_SYSTEM,
  CONVERSION_TOGGLE_EDIT_OPEN,
  CONVERSION_DELETE,
} from '../constants';

import checkMeasurementSystem from '../utils/check-measurement-system';

function getOpenAiPrompt(measurementSystem, openAIPrompts = []) {
  if (measurementSystem === 'onlyMetric') {
    return openAIPrompts.find(p => p.direction === 'toUs')?.text || '';
  }
  if (measurementSystem === 'onlyUs') {
    return openAIPrompts.find(p => p.direction === 'toMetric')?.text || '';
  }
  return '';
}

const initialState = {
  allowedToConvertRecipe: false,
  allowedToEditConversion: false,
  canConvertRecipe: false,
  conversionExists: false,
  convertedIngredients: null,
  currentMeasurementSystem: null,
  hasConverted: false,
  id: null,
  ingredientsToShow: null,
  ingredients_raw: null,
  isConversionEditOpen: false,
  isShowingOriginalRecipe: true,
  measurementSystem: null,
  model: 'gpt-4-turbo-preview',
  openAIPrompt: null,
  originalMeasurementSystem: null,
  unitConversionEnabled: false,
  ingredients_list_units: null,
};

export default function conversionReducer(state = initialState, action) {
  let data = {};
  switch (action.type) {
    case CONVERSION_SET_INITIAL_DATA: {
      const {
        openAIPrompts,
        allowedToConvertRecipe, // eslint-disable-next-line camelcase
        ingredients_raw,
        id,
        allowedToEditConversion,
        unitConversionEnabled, // eslint-disable-next-line camelcase
        ingredients_list_units,
      } = action.payload;

      const measurementSystem = checkMeasurementSystem({
        ingredients: ingredients_raw,
        ingredientsListUnits: ingredients_list_units,
      });

      const openAIPrompt = getOpenAiPrompt(measurementSystem, openAIPrompts);
      const canConvertRecipe = ['onlyUs', 'onlyMetric'].includes(measurementSystem);

      data = {
        ...initialState,
        openAIPrompts,
        allowedToConvertRecipe,
        canConvertRecipe,
        measurementSystem,
        ingredients_raw,
        id,
        allowedToEditConversion,
        openAIPrompt,
        currentMeasurementSystem: measurementSystem,
        originalMeasurementSystem: measurementSystem,
        ingredientsToShow: ingredients_raw,
        unitConversionEnabled,
        ingredients_list_units,
      };

      return Object.assign({}, state, data);
    }

    case CONVERSION_SET_CONVERTED_INGREDIENTS: {
      const { withoutSwitching, ingredients, userUnitSystem } = action.payload;
      let _withoutSwitching = withoutSwitching;

      if (userUnitSystem === 'us_cups' && state.measurementSystem === 'onlyMetric') {
        _withoutSwitching = false;
      }
      if (userUnitSystem === 'metric' && state.measurementSystem === 'onlyUs') {
        _withoutSwitching = false;
      }

      data = { convertedIngredients: ingredients, conversionExists: true };
      if (!_withoutSwitching) {
        data.hasConverted = true;
        data.isShowingOriginalRecipe = false;
        data.ingredientsToShow = ingredients;
        data.currentMeasurementSystem =
          state.measurementSystem === 'onlyUs' ? 'onlyMetric' : 'onlyUs';
      }
      return Object.assign({}, state, data);
    }

    case CONVERSION_SWITCH_CURRENT_MEASUREMENT_SYSTEM: {
      data.currentMeasurementSystem =
        state.currentMeasurementSystem === 'onlyUs' ? 'onlyMetric' : 'onlyUs';
      data.isShowingOriginalRecipe = !state.isShowingOriginalRecipe;
      data.ingredientsToShow = state.isShowingOriginalRecipe
        ? state.convertedIngredients
        : state.ingredients_raw;
      return Object.assign({}, state, data);
    }

    case CONVERSION_TOGGLE_EDIT_OPEN: {
      data.isConversionEditOpen = !state.isConversionEditOpen;
      return Object.assign({}, state, data);
    }

    case CONVERSION_DELETE: {
      return Object.assign({}, state, {
        hasConverted: false,
        convertedIngredients: null,
        isShowingOriginalRecipe: true,
        conversionExists: false,
        ingredientsToShow: state.ingredients_raw,
        currentMeasurementSystem: state.originalMeasurementSystem,
      });
    }

    default:
      return state;
  }
}

import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';

const mergeListPages = (existing, incoming, { args: { offset = 0 }, canRead }) => {
  if (!incoming) {
    return existing;
  }
  const merged = existing ? existing.filter(canRead) : [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < incoming.length; ++i) {
    const targetIndex = offset + i;
    if (targetIndex < 0) {
      merged.unshift(incoming[incoming.length - 1 - i]);
    } else {
      merged[targetIndex] = incoming[i];
    }
  }
  return merged;
};

export default function createCache() {
  // https://www.apollographql.com/docs/react/basics/caching.html#configuration
  return new InMemoryCache({
    possibleTypes: {
      Output: ['OutputText', 'OutputMeasure'],
    },
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Award':
          return `Award:${object.name}`;
        case 'Book':
          return object.isbn ? `Book:${object.isbn}` : `BookCover:${object.image_name}`;
        case 'Classification':
          return `Classification:${object.type}${object.name}`;
        case 'Content':
          return `Content:${object.id}${object.is_preview === false ? ':full' : ':preview'}`;
        case 'Price':
          return `Price:${object.id || object.product_id}`;
        case 'SampleSearch':
          return `SampleSearch:${object.url}`;
        case 'Recipe':
          return `Recipe:${object.id}${object.is_preview === false ? ':full' : ':preview'}`;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    },
    typePolicies: {
      Query: {
        fields: {
          RecipeComments: {
            keyArgs: ['content_id', 'type'],
            merge: mergeListPages,
          },
          BookIndex: {
            keyArgs: ['bookId'],
            merge: mergeListPages,
          },
          Books: {
            keyArgs: ['includeTags', 'orderBy', 'orderByDirection', 'filters', 'query', 'country'],
            merge: mergeListPages,
          },
        },
      },
      Content: {
        fields: {
          primary_image: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      Comment: {
        fields: {
          responses: {
            keyArgs: [],
            merge: mergeListPages,
          },
        },
      },
      Category: {
        fields: {
          children: {
            keyArgs: ['orderBy', 'orderByDirection', 'country'],
            merge: mergeListPages,
          },
        },
      },
      Recipe: {
        fields: {
          primary_image: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
    },
  });
}

/* eslint-disable jsx-a11y/anchor-has-content */

// node modules
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

// app modules: components
import Heading from 'components/Heading';

// assets
import CssTerritorialRestriction from './TerritorialRestriction.scss';

class TerritorialRestriction extends PureComponent {
  render() {
    return (
      <div className="territorial-restriction">
        <header className="margin-bottom-30">
          <Heading className="heading--size-48">Sorry</Heading>
        </header>
        <p className="territorial-restriction__paragraph">
          This content is not currently available in your country (<a
            href="//www.ckbk.com/faq"
            className="link link--energized"
            target="_blank"
            rel="noopener noreferrer"
          >
            more info
          </a>).
        </p>
      </div>
    );
  }
}

export default withStyles(CssTerritorialRestriction)(TerritorialRestriction);

import { AVATAR_MENU_OPEN, AVATAR_MENU_CLOSE, OVEN_MENU_OPEN, OVEN_MENU_CLOSE } from '../constants';

export default function avatarMenuReducers(state = {}, action) {
  switch (action.type) {
    case AVATAR_MENU_OPEN:
      return Object.assign({}, state, {
        isOpen: true,
        ovenMenuOpen: false,
      });

    case AVATAR_MENU_CLOSE:
      return Object.assign({}, state, {
        isOpen: false,
      });

    case OVEN_MENU_OPEN:
      return Object.assign({}, state, {
        isOpen: false,
        ovenMenuOpen: true,
      });

    case OVEN_MENU_CLOSE:
      return Object.assign({}, state, {
        ovenMenuOpen: false,
      });

    default:
      return state;
  }
}

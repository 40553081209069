import PropTypes from 'prop-types';

export default {
  dimensions: PropTypes.shape({
    body: PropTypes.number,
    layout: PropTypes.shape({
      panel: PropTypes.number,
    }),
    tableOfContents: PropTypes.number,
  }),
};

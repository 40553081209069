/* eslint-disable react/no-danger */
/* eslint-disable react/no-did-update-set-state, import/no-named-as-default */

// node modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import NoSsr from '@material-ui/core/NoSsr';

// app modules: actions
import { openAvatarMenu, closeAvatarMenu } from 'actions/avatarMenu';

// app modules: components
import Image from 'components/Image';
import LayoutUserMenu from 'components/LayoutUserMenu';

// app modules: props
import LoginProps from 'components/Login/LoginProps';

// app modules: services
import history from 'services/history';

// assets
import CssUserMenu from './UserMenu.scss';

class UserMenu extends Component {
  static propTypes = {
    user: LoginProps.isRequired,
    avatarMenuVisible: PropTypes.bool.isRequired,
    openAvatarMenu: PropTypes.func.isRequired,
    closeAvatarMenu: PropTypes.func.isRequired,
    className: PropTypes.string,
    loginLinkClassName: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    loginLinkClassName: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      avatarLoadFailed: false,
    };
  }

  handleAvatarMenuClick = () => {
    const { avatarMenuVisible } = this.props;
    if (avatarMenuVisible) {
      this.props.closeAvatarMenu();
    } else {
      this.props.openAvatarMenu();
    }
  };

  handleAvatarImageFail = () => {
    const { avatarLoadFailed } = this.state;
    if (!avatarLoadFailed) {
      this.setState({ avatarLoadFailed: true });
    }
  };

  render() {
    const { user, avatarMenuVisible, className, loginLinkClassName } = this.props;
    const { avatarLoadFailed } = this.state;
    // /login/avatar.png should be a redirection to the correct avatar for this user.
    // The idea being that the image can be rendered without waiting for JS execution.
    // If that fails to load then we can correct it by loading the avatar direct.
    const avatarImageUrl = avatarLoadFailed && user.avatar ? user.avatar : '/login/avatar.png';
    const avatarImage =
      avatarImageUrl && user.logged_in ? (
        <Image
          image={avatarImageUrl}
          width="30"
          height="30"
          alt="Avatar"
          shape="circle"
          onError={this.handleAvatarImageFail}
        />
      ) : null;
    const loading = (
      <div className="user-menu user-menu__fallback">
        <div className={className}>{avatarImage}</div>
      </div>
    );
    if (user.logged_in) {
      return (
        <NoSsr fallback={loading}>
          <Tooltip
            overlay={<LayoutUserMenu />}
            visible={avatarMenuVisible}
            trigger={['click']}
            placement="bottomRight"
            align={{ offset: [-15, 2] }}
            mouseEnterDelay={0.75}
            overlayClassName="rc-tooltip--light"
            overlayStyle={{ position: 'fixed', zIndex: 1310 }}
          >
            <button className={`user-menu ${className}`} onClick={this.handleAvatarMenuClick}>
              {avatarImage}
            </button>
          </Tooltip>
        </NoSsr>
      );
    }

    return (
      <button
        onClick={() => history.push('/login', { returnTo: history.location })}
        className={`user-menu__login ${loginLinkClassName}`}
      >
        Login
      </button>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  avatarMenuVisible: state.avatarMenu.isOpen,
});

const mapDispatchToProps = {
  openAvatarMenu,
  closeAvatarMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(CssUserMenu)(UserMenu));

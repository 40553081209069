const englishDefaults = {
  Favorites: 'Favourites',
  favorite: 'favourite',
  favorites: 'favourites',
};

const i18n = {
  preload: ['en-US', 'en-GB', 'en-NZ', 'en-AU'],
  resources: {
    'en-US': {
      translation: {
        Favorites: 'Favorites',
        favorite: 'favorite',
        favorites: 'favorites',
      },
    },
    'en-GB': {
      translation: englishDefaults,
    },
    'en-NZ': {
      translation: englishDefaults,
    },
    'en-AU': {
      translation: englishDefaults,
    },
  },
  fallbackLng: 'en-US',
  debug: false,

  interpolation: {
    escapeValue: false,
  },
};

export default i18n;

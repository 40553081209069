import { gql } from '@apollo/client';

export const GET_POSITIVE_FEEDBACK_COUNT = gql`
  query GetUserPositiveFeedbackCount($user_id: String) {
    UserPositiveFeedbackCount(user_id: $user_id)
  }
`;

export const LOG_RATE_POPUP_EVENTS = gql`
  mutation LogRatePopupEvents($user_id: Int!, $event_type: String) {
    LogRatePopupEvents(user_id: $user_id, event_type: $event_type)
  }
`;

export const GET_RATE_POPUP_EVENTS = gql`
  query GetRatePopupEvents($user_id: Int!) {
    GetRatePopupEvents(user_id: $user_id) {
      userId
      firstLaunchIOS
      firstLaunchAndroid
      lastGooglePlayReviewInvite
      lastAppStoreReviewInvite
      fullLog
    }
  }
`;

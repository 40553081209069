import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import NoSsr from '@material-ui/core/NoSsr';
import Image from 'components/Image';

import { openOvenMenu } from 'actions/avatarMenu';

const HomeConnectMenu = React.lazy(() =>
  import(/* webpackChunkName: 'home-connect-menu' */ 'components/HomeConnectMenu'),
);

function LazyHomeConnectMenu(props) {
  const dispatch = useDispatch();
  const ovenMenuOpen = useSelector(state => state.avatarMenu.ovenMenuOpen);
  const homeConnectEnabled = useSelector(state => state.user.home_connect_enabled);

  if (homeConnectEnabled) {
    const facade = (
      <button
        className={props.className}
        onClick={() => dispatch(openOvenMenu())}
        data-cy="home-connect-menu-icon"
      >
        <Image image="images/homeconnect.png" height={22} width={22} alt="Home Connect" />
      </button>
    );
    if (ovenMenuOpen) {
      return (
        <NoSsr fallback={facade}>
          <Suspense fallback={facade}>
            <HomeConnectMenu {...props} />
          </Suspense>
        </NoSsr>
      );
    }
    return facade;
  }
  return null;
}

LazyHomeConnectMenu.propTypes = {
  className: PropTypes.string,
};

LazyHomeConnectMenu.defaultProps = {
  className: null,
};

export default LazyHomeConnectMenu;

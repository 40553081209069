exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".heading{font-family:\"Bebas Neue\",\"bebas-fallback\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif;font-size:1.714em;font-weight:400;line-height:1.2;text-transform:uppercase}.heading--size-15{font-size:1.071em}.heading--size-18{font-size:1.286em}.heading--size-32{font-size:2.286em}.heading--size-48{font-size:3.429em}.heading--size-64{font-size:4.571em}.heading--with-dash::before{background-color:#fa6422;content:'';display:block;height:2px;margin-bottom:15px;width:50px}.heading--non-western-roboto-condensed{font-family:\"Roboto Condensed\", sans-serif !important;font-optical-sizing:auto;font-weight:600;font-style:normal;font-size:1.6em}.heading--non-western-font{font-family:\"bebas-fallback\",sans-serif !important}.heading strong,.heading .link{font-weight:400}\n", ""]);

// exports
exports.locals = {
	"heading": "heading",
	"heading--size-15": "heading--size-15",
	"heading--size-18": "heading--size-18",
	"heading--size-32": "heading--size-32",
	"heading--size-48": "heading--size-48",
	"heading--size-64": "heading--size-64",
	"heading--with-dash": "heading--with-dash",
	"heading--non-western-roboto-condensed": "heading--non-western-roboto-condensed",
	"heading--non-western-font": "heading--non-western-font",
	"link": "link"
};
import {
  TABLE_OF_CONTENTS_OPEN,
  TABLE_OF_CONTENTS_CLOSE,
  TABLE_OF_CONTENTS_SET_SELECTED,
} from '../constants';

export default function tableOfContentsReducers(state = {}, action) {
  switch (action.type) {
    case TABLE_OF_CONTENTS_OPEN:
      return Object.assign({}, state, {
        isOpen: true,
      });

    case TABLE_OF_CONTENTS_CLOSE:
      return Object.assign({}, state, {
        isOpen: false,
      });

    case TABLE_OF_CONTENTS_SET_SELECTED:
      return Object.assign({}, state, {
        selected: action.payload.selected,
      });

    default:
      return state;
  }
}

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".banner-wrapper--reserve-space{height:40px}.banner-wrapper--overlay{height:0;position:relative;z-index:2}.banner{align-items:center;background-color:rgba(51,51,51,0.9);color:#fff;display:flex;flex:auto 1 1;flex-direction:row;justify-content:space-between;min-height:40px;padding:10px}.banner--light{background-color:#e6e6e6;color:#333;font-weight:600}.banner--light .banner__close{background:linear-gradient(-45deg, transparent 0%, transparent 48%, #333 48%, #333 54%, transparent 54%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 48%, #333 48%, #333 54%, transparent 54%, transparent 100%)}.banner__left{width:15px}.banner__content{max-width:1024px}.banner__close{background:linear-gradient(-45deg, transparent 0%, transparent 48%, #959595 48%, #959595 54%, transparent 54%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 48%, #959595 48%, #959595 54%, transparent 54%, transparent 100%);height:15px;margin-right:10px;width:15px}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .banner__close{background:linear-gradient(-45deg, transparent 0%, transparent 48%, #fff 48%, #fff 54%, transparent 54%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 48%, #fff 48%, #fff 54%, transparent 54%, transparent 100%)}}:root[data-theme=dark] .banner__close{background:linear-gradient(-45deg, transparent 0%, transparent 48%, #fff 48%, #fff 54%, transparent 54%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 48%, #fff 48%, #fff 54%, transparent 54%, transparent 100%)}.banner__close:hover{background:linear-gradient(-45deg, transparent 0%, transparent 48%, #767676 48%, #767676 54%, transparent 54%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 48%, #767676 48%, #767676 54%, transparent 54%, transparent 100%)}@media print{.banner{display:none}}\n", ""]);

// exports
exports.locals = {
	"banner-wrapper--reserve-space": "banner-wrapper--reserve-space",
	"banner-wrapper--overlay": "banner-wrapper--overlay",
	"banner": "banner",
	"banner--light": "banner--light",
	"banner__close": "banner__close",
	"banner__left": "banner__left",
	"banner__content": "banner__content"
};
/* eslint-disable no-param-reassign */

// node modules
import { SearchkitManager, encodeObjUrl } from 'searchkit';
import isEqual from 'lodash/isEqual';

class SearchkitManagerCustom extends SearchkitManager {
  /**
   * Adds third argument to the original method: updateHistory.
   * @override
   * @param  {Boolean} replaceState
   * @param  {Boolean} notifyState
   * @param  {Boolean} updateHistory
   * @return {Promise}
   */
  performSearch(replaceState = false, notifyState = true, updateHistory = this.options.useHistory) {
    if (notifyState && !isEqual(this.accessors.getState(), this.state)) {
      this.accessors.notifyStateChange(this.state);
    }

    const searchPromise = this._search();

    if (updateHistory) {
      const historyMethod = replaceState ? this.history.replace : this.history.push;
      const url = `${this.options.getLocation().pathname}?${encodeObjUrl(this.state)}`;
      historyMethod.call(this.history, url, this.history.location.state);
      // If this search is different from the last one, then report it as a new search session.
      // We ignore the p (page) parameter.
      const { p, ...currentSearch } = this.state;
      if (
        typeof this.searchSessionListener === 'function' &&
        !isEqual(this.searchSession, currentSearch)
      ) {
        this.searchSessionListener({ state: currentSearch, results: this.results });
        this.searchSession = currentSearch;
      }
    }

    return searchPromise;
  }

  /**
   * @override
   * @param {Object} results
   */
  setResults(results) {
    if (typeof this.query.getFrom() !== 'undefined') {
      results.hits = Object.assign({}, results.hits, {
        hits: [...this.results.hits.hits, ...results.hits.hits],
        hasChanged: false,
      });
      const mergedResults = Object.assign({}, this.results, {
        hits: results.hits,
      });

      this.results = mergedResults;
    } else {
      this.compareResults(this.results, results);
      this.results = results;
    }

    this.error = null;
    this.accessors.setResults(results);
    this.onResponseChange();
    this.resultsEmitter.trigger(this.results);
  }

  listenToHistory() {
    this._unlistenHistory = this.history.listen((location, action) => {
      if (action === 'POP' && location.pathname.startsWith('/search')) {
        this._searchWhenCompleted(location);
      }
    });
  }

  setSearchSessionListener(listener) {
    this.searchSessionListener = listener;
  }
}

export default SearchkitManagerCustom;

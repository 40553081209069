import { COLOURS } from 'constants/index';

const fontFamily = "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
const headingFont = "'Bebas Neue', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important";

export default function(prefersDarkMode = false, textSize = 16) {
  return {
    palette: {
      type: prefersDarkMode ? 'dark' : 'light',
      primary: { main: COLOURS.ENERGIZED },
      secondary: { main: prefersDarkMode ? COLOURS.DARK_MINE_SHAFT : COLOURS.MINE_SHAFT },
      error: { main: COLOURS.CARDINAL },
      background: { default: COLOURS.ENERGIZED, paper: COLOURS.ENERGIZED },
    },
    typography: {
      fontFamily,
      fontSize: textSize,
      h1: { fontSize: '36px', fontWeight: 400, fontFamily: headingFont },
      h2: { fontSize: '32px', fontWeight: 400, fontFamily: headingFont },
      button: { fontFamily: headingFont },
    },
    overrides: {
      ...(prefersDarkMode && { MuiDialog: { paper: { backgroundColor: COLOURS.DARK_ALABASTER } } }),
      MuiTouchRipple: { rippleVisible: { color: COLOURS.MINE_SHAFT } },
      MuiRadio: { root: { color: COLOURS.STABLE } },
      MuiButton: {
        root: {
          fontSize: '1.125rem',
          lineHeight: 1.4,
          whiteSpace: 'nowrap',
          minWidth: 32,
          padding: '8px 16px 4px 16px',
          transition: 'color .01s',
        },
        text: { padding: '8px 8px 4px 8px' },
        textPrimary: {
          '&:hover': { backgroundColor: prefersDarkMode ? COLOURS.DARK_ALABASTER : null },
        },
        contained: {
          color: COLOURS.ENERGIZED,
          backgroundColor: prefersDarkMode ? COLOURS.BLACK : COLOURS.WHITE,
          border: `solid 1px ${
            COLOURS.ENERGIZED // Workaround for https://github.com/mui-org/material-ui/issues/26251
          }`,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: prefersDarkMode ? COLOURS.BLACK : COLOURS.WHITE,
            borderColor: '#fb753b',
            boxShadow: 'none',
            color: '#fb753b',
          },
        },
        containedPrimary: {
          border: 'none',
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#f95309',
            color: prefersDarkMode ? COLOURS.BLACK : COLOURS.WHITE,
          },
        },
        containedSizeLarge: { fontSize: '1.25rem', paddingTop: '5px', paddingBottom: 0 },
        sizeLarge: { fontSize: '1.25rem', paddingTop: '5px', paddingBottom: 0 },
        iconSizeLarge: { fontSize: '24px' },
        startIcon: { marginBottom: '4px', marginRight: '5px' },
        label: {
          '& > svg': {
            // Selects the SVG directly in the label (not via startIcon)
            marginBottom: '4px',
          },
        },
      },
      MuiTab: { root: { fontSize: '1.125rem', whiteSpace: 'nowrap' } },
      MuiAlert: { root: { textAlign: 'left' } },
      MuiTooltip: {
        tooltip: { fontFamily, fontSize: '13px', backgroundColor: '#373737' },
        arrow: { color: '#373737' },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: prefersDarkMode ? COLOURS.BLACK : COLOURS.WHITE,
          color: prefersDarkMode ? COLOURS.WHITE : COLOURS.BLACK,
        },
        input: { padding: 14 },
      },
      MuiInputLabel: {
        outlined: { transform: 'translate(14px, 16px) scale(1)' },
        shrink: { fontFamily, fontSize: '13px', transform: 'translate(0, 1.5px)' },
      },
      MuiStepper: { root: { padding: '8px 0' } },
      MuiStepLabel: { label: { '&.MuiStepLabel-alternativeLabel': { marginTop: 2 } } },
      MuiStepIcon: {
        text: { fontSize: '14px', fontWeight: 'bold', transform: 'translate(0px,1px)' },
      },
      MuiSelect: {
        root: { fontFamily, fontSize: 13, paddingLeft: 5 },
        icon: { color: COLOURS.ENERGIZED },
      },
      MuiAutocomplete: {
        listbox: { backgroundColor: prefersDarkMode ? COLOURS.BLACK : COLOURS.WHITE },
      },
      MuiPaper: { root: { backgroundColor: prefersDarkMode ? COLOURS.BLACK : COLOURS.WHITE } },
      MuiBottomNavigationAction: {
        root: { color: prefersDarkMode ? COLOURS.WHITE : COLOURS.BLACK },
      },
    },
  };
}

/* eslint-disable import/prefer-default-export */

import { SIDEMENU_OPEN, SIDEMENU_CLOSE, AVATAR_MENU_CLOSE } from '../constants';

export function openSidemenu() {
  return dispatch => {
    dispatch({
      type: SIDEMENU_OPEN,
    });
  };
}

export function closeSidemenu() {
  return dispatch => {
    dispatch({
      type: SIDEMENU_CLOSE,
    });
    dispatch({
      type: AVATAR_MENU_CLOSE,
    });
  };
}

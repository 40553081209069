// node modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';

// assets
import CssImage from './Image.scss';

class Image extends Component {
  static propTypes = {
    alt: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    image: PropTypes.string,
    srcSet: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shape: PropTypes.string,
    loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
    overlay: PropTypes.node,
  };

  static defaultProps = {
    alt: '',
    image: null,
    children: null,
    className: '',
    height: 'auto',
    width: 'auto',
    shape: '',
    srcSet: null,
    loading: 'auto',
    overlay: null,
  };

  render() {
    const {
      alt,
      children,
      className,
      height,
      image,
      loading,
      overlay,
      shape,
      srcSet,
      width,
      ...otherProps
    } = this.props;
    const figureClassName = classNames(className, 'figure', {
      [`figure--${shape}`]: shape,
    });

    return (
      <figure className={figureClassName} style={{ width, height }}>
        {children}
        {overlay}
        {image ? (
          <img
            src={image}
            srcSet={srcSet}
            width={width}
            height={height}
            alt={alt}
            suppressHydrationWarning
            loading={loading}
            {...otherProps}
          />
        ) : null}
      </figure>
    );
  }
}

export default withStyles(CssImage)(Image);

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sidebar{display:flex;flex-direction:column;height:100%;padding:15px}.sidebar__logo{display:block;height:30px;width:auto}.sidebar__navigation{color:#333;flex:2 1;font-family:\"Bebas Neue\",\"bebas-fallback\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif;margin-bottom:15px;margin-top:15px}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .sidebar__navigation{color:#fff}}:root[data-theme=dark] .sidebar__navigation{color:#fff}.sidebar__navigation__item{padding:13px 0}.sidebar__navigation__link{display:block;font-size:25.136px;font-size:1.571rem;text-align:left;width:100%}.sidebar__navigation__text--active{border-bottom:3px solid #fa6422}.sidebar__navigation__icon{margin-top:5px;vertical-align:baseline}.sidebar__navigation--bottom{flex:none}.sidebar__login{display:block;font-family:\"Bebas Neue\",\"bebas-fallback\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif;font-size:25.136px;font-size:1.571rem;width:100%}.sidebar__user{align-items:center;display:flex;outline:none;position:relative;width:70px}.sidebar__user::after{border-bottom:3px solid rgba(0,0,0,0.85);border-right:3px solid rgba(0,0,0,0.85);content:' ';display:block;height:10px;margin-top:-5px;position:absolute;right:8px;top:50%;transform:rotate(-45deg);width:10px}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .sidebar__user::after{border-bottom:3px solid rgba(255,255,255,0.85);border-right:3px solid rgba(255,255,255,0.85)}}:root[data-theme=dark] .sidebar__user::after{border-bottom:3px solid rgba(255,255,255,0.85);border-right:3px solid rgba(255,255,255,0.85)}.sidebar__user-panel{font-size:18.288px;font-size:1.143rem;min-width:80px;text-align:center}@media (min-width: 769px){.sidebar{display:none}}\n", ""]);

// exports
exports.locals = {
	"sidebar": "sidebar",
	"sidebar__logo": "sidebar__logo",
	"sidebar__navigation": "sidebar__navigation",
	"sidebar__navigation__item": "sidebar__navigation__item",
	"sidebar__navigation__link": "sidebar__navigation__link",
	"sidebar__navigation__text--active": "sidebar__navigation__text--active",
	"sidebar__navigation__icon": "sidebar__navigation__icon",
	"sidebar__navigation--bottom": "sidebar__navigation--bottom",
	"sidebar__login": "sidebar__login",
	"sidebar__user": "sidebar__user",
	"sidebar__user-panel": "sidebar__user-panel"
};
/* eslint-disable camelcase */
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { updateUserLoginStatus } from 'actions/user';
import { getStore } from '../store/configureStore';

export function credentialsNeedRefresh({ expires_at }) {
  const store = getStore();
  const { user } = store.getState();

  if (user.clock_offset > 600000) {
    console.warn('User clock offset is high', user.clock_offset);
  }

  // Has the token expired, or will it soon?
  return dayjs()
    .add(30, 'seconds')
    .subtract(user.clock_offset, 'millisecond')
    .isAfter(dayjs(expires_at));
}

export function credentialsExist() {
  return !!Cookies.get('access_token_exp');
}

async function refreshCookieTokenIfNeeded() {
  const store = getStore();
  const { router } = store.getState();
  // For web clients we rely on cookies for auth - a necessity for SSR to work.
  const accessTokenExpiration = Cookies.get('access_token_exp');
  if (accessTokenExpiration && credentialsNeedRefresh({ expires_at: accessTokenExpiration })) {
    try {
      store.dispatch(updateUserLoginStatus({ refreshing: true }));
      const response = await fetch('/login/auth0/refresh', { method: 'POST' });
      if (response.status === 401) {
        // User logged out by the server, or there was no refresh token
        localStorage.clear();
        localStorage.setItem('last_logout', Date.now());
        window.location = `/logout?returnTo=${encodeURIComponent(router.pathname)}`;
      } else if (response.ok) {
        console.info('Auth refreshed');
      } else {
        console.error('Failed to get new auth token', response.status, response.statusText);
      }
    } catch (err) {
      console.error('Failed to refresh auth', err);
    } finally {
      store.dispatch(updateUserLoginStatus({ refreshing: false }));
    }
  }
}

export async function getAuthToken() {
  await refreshCookieTokenIfNeeded();
  return Cookies.get('access_token');
}

export default {
  getAuthToken,
  credentialsNeedRefresh,
};

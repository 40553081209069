import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useScript from 'react-script-hook';

function Profitwell() {
  const apiKey = useSelector(state => state.config.profitwell.apiKey);
  const email = useSelector(state => state.user.email);
  useScript({
    src:
      apiKey && apiKey !== 'DISABLED'
        ? `https://public.profitwell.com/js/profitwell.js?auth=${apiKey}`
        : null,
    checkForExisting: true,
    async: true,
    'data-pw-auth': apiKey,
    id: 'profitwell-js',
  });
  useEffect(() => {
    window.profitwell =
      window.profitwell ||
      // eslint-disable-next-line func-names
      function() {
        // eslint-disable-next-line prefer-rest-params
        (window.profitwell.q = window.profitwell.q || []).push(arguments);
      };
  }, []);
  useEffect(
    () => {
      if (email) {
        if (window.profitwell) {
          window.profitwell('start', { user_email: email });
        }
      }
    },
    [email],
  );
  return null;
}

export default Profitwell;

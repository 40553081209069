/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/heading-has-content */
// node modules
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';

// assets
import CssNoLongerAvailable from './NoLongerAvailable.scss';

class NoLongerAvailable extends PureComponent {
  render() {
    return (
      <div className="no-longer-available">
        <img
          className="no-longer-available__image margin-bottom-30"
          src="images/fridge.png"
          alt="fridge"
          height="400"
          width="250"
        />

        <header className="margin-bottom-30">
          <h1 className="no-longer-available__heading">
            When she got there, the cupboard was bare...
          </h1>
        </header>

        <p className="no-longer-available__paragraph">
          Sorry - this content is not currently available on ckbk (
          <a
            className="link link--energized"
            href="https://intercom.help/ckbk/en/articles/3580028-i-m-looking-for-a-book-which-was-previously-on-the-site-but-can-t-find-it"
            target="_blank"
            rel="noopener noreferrer"
          >
            more info
          </a>).
        </p>
      </div>
    );
  }
}

export default withStyles(CssNoLongerAvailable)(NoLongerAvailable);

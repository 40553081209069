exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".advert{height:auto !important;margin-left:auto;margin-right:auto;max-width:1024px;width:100%}.advert--default{width:300px}@media (min-width: 640px){.advert--default{max-width:964px;width:100%}}.advert--leaderboard{margin:0 auto;max-height:90px;min-height:90px;width:100%}@media (max-width: 640px){.advert--leaderboard{height:50px;max-width:320px;min-height:unset}}@media (max-width: 640px){.advert--hide-on-mobile{display:none}}.advert__fallback{margin-left:auto;margin-right:auto;text-align:center}.advert__fallback--leaderboard{height:90px;width:728px}@media (max-width: 640px){.advert__fallback--leaderboard{height:50px;margin-bottom:10px;min-height:unset;width:320px}}.advert__disclosure{color:#a1a1a1;font-size:13.712px;font-size:0.857rem;height:0;position:relative;text-align:center;top:-16px}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .advert__disclosure{color:#5e5e5e}}:root[data-theme=dark] .advert__disclosure{color:#5e5e5e}.adsbygoogle{display:block;margin-left:auto;margin-right:auto;overflow:hidden;text-align:center}.adsbygoogle--hide-when-unfilled[data-ad-status='unfilled']{display:none}\n", ""]);

// exports
exports.locals = {
	"advert": "advert",
	"advert--default": "advert--default",
	"advert--leaderboard": "advert--leaderboard",
	"advert--hide-on-mobile": "advert--hide-on-mobile",
	"advert__fallback": "advert__fallback",
	"advert__fallback--leaderboard": "advert__fallback--leaderboard",
	"advert__disclosure": "advert__disclosure",
	"adsbygoogle": "adsbygoogle",
	"adsbygoogle--hide-when-unfilled": "adsbygoogle--hide-when-unfilled"
};
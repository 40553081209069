// node modules
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet-async';
import PropTypes from 'prop-types';
import { LIVE_SITE_URL } from 'constants/index';

class DefaultHeadContent extends Component {
  static propTypes = {
    baseUrl: PropTypes.string.isRequired,
    staticBaseUrl: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
    facebookId: PropTypes.string.isRequired,
  };

  render() {
    const defaultTitle = "ckbk: the world's best cookbooks at your fingertips";
    const defaultDescription =
      'ckbk is the ultimate digital subscription service for anyone who loves to cook';
    const { baseUrl, staticBaseUrl, pathname, facebookId } = this.props;

    // Warning: this may not be the canonical URL. It should be overridden on each page,
    // especially for pages that are accessible via more than one URL.
    const url = `${baseUrl}${pathname || '/'}`;

    // Don't allow demo env to be indexed.
    let robotsMeta = <meta name="robots" content="none" />;
    if (baseUrl === LIVE_SITE_URL) {
      // For the live site, allow the site to be indexed but don't display the cached link.
      robotsMeta = <meta name="robots" content="noarchive, max-image-preview:large" />;
    }

    return (
      <Helmet>
        {robotsMeta}
        <title>{defaultTitle}</title>
        <meta name="site_name" content="ckbk" />
        <meta property="og:site_name" name="site_name" content="ckbk" />
        <meta name="title" content={defaultTitle} />
        <meta property="og:title" content={defaultTitle} />
        <meta name="description" content={defaultDescription} />
        <meta property="og:description" content={defaultDescription} />
        <meta name="url" content={url} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={defaultTitle} />
        <meta name="twitter:description" content={defaultDescription} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ckbkapp" />
        <meta name="facebook-domain-verification" content="ln2qefjp28z0lvqlx5y3pd1nc0ryc2" />
        <meta property="fb:app_id" content={facebookId} />
        <meta name="image" content={`${staticBaseUrl}/images/logo-social-share.jpg`} />
        <meta property="og:image" content={`${staticBaseUrl}/images/logo-social-share.jpg`} />
        <meta name="image:height" content="933" />
        <meta property="og:image:height" content="933" />
        <meta name="image:width" content="1301" />
        <meta property="og:image:width" content="1301" />
        <meta name="twitter:image" content={`${staticBaseUrl}/images/logo-social-share.jpg`} />
        <meta property="al:ios:url" content={`ckbk://app.ckbk.com${pathname}`} />
        <meta property="al:ios:app_store_id" content="1476621703" />
        <meta property="al:ios:app_name" content="ckbk" />
        <meta name="apple-itunes-app" content={`app-id=1476621703, app-argument=${url}`} />
        <link rel="alternate" href="android-app://com.ckbk.app/https/app.ckbk.com" />
        <link rel="alternate" href="android-app://com.ckbk.app/https/appdemo.ckbk.com" />
      </Helmet>
    );
  }
}

const mapStateToProps = state => ({
  baseUrl: state.config.baseUrl,
  pathname: state.router.pathname,
  facebookId: state.config.facebookId,
  staticBaseUrl: state.config.cdn.static.baseUrl,
});

export default connect(
  mapStateToProps,
  null,
)(DefaultHeadContent);

import {
  SET_THEME,
  SET_TEXT_SIZE,
  SET_USE_SYSTEM_TEXT_SIZE,
  SET_CACHED_SYSTEM_TEXT_SIZE,
} from '../constants';

export default function configReducers(state = {}, action) {
  switch (action.type) {
    case SET_THEME:
      return { ...state, theme: action.payload };
    case SET_TEXT_SIZE:
      return { ...state, textSize: action.payload };
    case SET_CACHED_SYSTEM_TEXT_SIZE:
      return { ...state, cachedSystemTextSize: action.payload };
    case SET_USE_SYSTEM_TEXT_SIZE:
      return { ...state, textSizeUseSystem: action.payload };
    default:
      return state;
  }
}

/* eslint-disable import/prefer-default-export */
import { AUTH_ERROR } from '../../constants';

export function authError(error) {
  const timestamp = Date.now();
  return {
    type: AUTH_ERROR,
    error,
    timestamp,
  };
}

/* eslint-disable react/no-danger */
/* eslint-disable react/no-did-update-set-state, import/no-named-as-default */

// node modules
import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import NoSsr from '@material-ui/core/NoSsr';
import get from 'lodash/get';

// app modules: actions
import { searchIsActive, loadSearchView } from 'actions/search';
import { openAvatarMenu, closeAvatarMenu } from 'actions/avatarMenu';

// app modules: components
import Hamburger from 'components/Hamburger';
import HomeConnectMenu from 'components/HomeConnectMenu/LazyHomeConnectMenu';
import Link from 'components/Link';
import NotificationButton from 'components/NotificationButton';
import SearchBoxCustom from 'components/SearchBoxCustom';
import SearchProvider from 'components/SearchProvider';
import UserMenu from 'components/UserMenu';

// app modules: services
import history from 'services/history';

// constants
import { OPERATOR, MIN_SEARCH_LENGTH } from 'constants/search';

// assets
import CssLayoutHeader from './LayoutHeader.scss';

const QUERY_OPTIONS = { default_operator: OPERATOR };

const SearchResultsLayout = React.lazy(() =>
  import(/* webpackChunkName: 'search-results' */ './SearchResultsLayout'),
);

class LayoutHeader extends Component {
  static propTypes = {
    className: PropTypes.string,
    displayType: PropTypes.string.isRequired,
    router: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.string,
    }).isRequired,
    search: PropTypes.shape({
      isEmpty: PropTypes.bool,
      isLoading: PropTypes.bool,
      isActive: PropTypes.bool,
      searchViewRequired: PropTypes.bool,
    }).isRequired,
    searchIsActive: PropTypes.func.isRequired,
    loadSearchView: PropTypes.func.isRequired,
    avatarMenuVisible: PropTypes.bool.isRequired,
    openAvatarMenu: PropTypes.func.isRequired,
    closeAvatarMenu: PropTypes.func.isRequired,
    homeConnectEnabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  constructor(props) {
    super(props);

    this.bound = {
      onResultsChange: this.onResultsChange.bind(this),
      onSearchBoxFocus: this.onSearchBoxFocus.bind(this),
      onSearchBoxChange: this.onSearchBoxChange.bind(this),
      handleAvatarMenuClick: this.handleAvatarMenuClick.bind(this),
    };

    this.state = {
      isSearchView: false,
      searchDirty: false,
    };

    // Hidden feature - full text search for experimental purposes. Don't link to this because it will be removed later.
    this.searchFields = []; // search fields now controlled by the server
    if (history.location.pathname === '/search/fulltext') {
      this.searchFields.push('_fulltext');
    }

    this.searchResultsRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.search.isActive && !this.props.search.searchViewRequired) {
      this.props.loadSearchView();
    }

    // location has changed
    if (prevProps.router.state === 'loading' && this.props.router.state === 'loaded') {
      document.body.style.overflow = '';
      const isSearchView = history.location.pathname.startsWith('/search');
      this.setState({
        isSearchView,
      });
      if (this.props.search.isActive !== isSearchView) {
        this.props.searchIsActive(isSearchView);
      }
    }
  }

  onResultsChange(results, searchkit) {
    const { isEmpty } = this.props.search;
    const { searchDirty } = this.state;

    document.body.style.overflow = isEmpty ? '' : 'hidden'; // enable / disable body scrolling.
    const isSearchView = !isEmpty || history.location.pathname.startsWith('/search');
    const isPageLoad = get(searchkit, 'accessors.statefulAccessors.p.state.value', 0) > 0;

    if (!isPageLoad) {
      this.setState({ isSearchView });
      this.props.searchIsActive(isSearchView);
    }
    if (this.searchResultsRef.current && searchDirty) {
      this.searchResultsRef.current.scrollTop = 0;
      this.setState({ searchDirty: false });
    }
  }

  onSearchBoxFocus() {
    const { isEmpty } = this.props.search;
    this.setState({
      isSearchView: !isEmpty,
    });
    this.props.searchIsActive(!isEmpty);

    this.props.loadSearchView();
  }

  onSearchBoxChange() {
    this.setState({ searchDirty: true });
  }

  handleAvatarMenuClick() {
    const { avatarMenuVisible } = this.props;
    if (avatarMenuVisible) {
      this.props.closeAvatarMenu();
    } else {
      this.props.openAvatarMenu();
    }
  }

  linkClassName(pathname) {
    const { router } = this.props;

    return classNames('layout-header__menu__link layout-header__menu__link--shifted', {
      'layout-header__menu__link--active': router.pathname.indexOf(pathname) === 0,
    });
  }

  renderPlaceholder() {
    const { displayType } = this.props;

    if (displayType !== 'desktop') {
      return 'Ingredients, dishes, authors, books...';
    }

    return 'Search ingredients, dishes, authors, books and more...';
  }

  render() {
    const { search, homeConnectEnabled, className } = this.props;
    const { isSearchView } = this.state;
    const searchOnLoad = history.location.pathname.startsWith('/search');

    const layoutHeaderClassName = classNames('layout-header', className, {
      'layout-header--search-active': isSearchView,
    });

    const linksClassName = {
      authors: this.linkClassName('/authors'),
      books: this.linkClassName('/book'),
      collections: this.linkClassName('/collection'),
      features: this.linkClassName('/features'),
    };
    return (
      <SearchProvider
        allowEmptySearch
        minimumSearchLength={MIN_SEARCH_LENGTH}
        onResultsChange={this.bound.onResultsChange}
        // Don't trigger the search yet - it will be fired from the effect after SearchResultsView has loaded.
        searchOnLoad={false}
      >
        <div className={layoutHeaderClassName}>
          <div className="layout-header__wrapper">
            <Hamburger />
            <Link to="/" className="layout-header__logo">
              <img src="images/logo.png" className="layout-header__logo__image" alt="Logo" />
            </Link>
            <SearchBoxCustom
              searchOnChange
              queryFields={this.searchFields}
              queryOptions={QUERY_OPTIONS}
              placeholder={this.renderPlaceholder()}
              onFocus={this.bound.onSearchBoxFocus}
              onChange={this.bound.onSearchBoxChange}
              autoFocus={false}
            />

            <nav className="layout-header__navigation">
              <ul className="layout-header__menu layout-header__menu--main">
                <li className="layout-header__menu__item">
                  <Link to="/authors" className={linksClassName.authors}>
                    Authors
                  </Link>
                </li>
                <li className="layout-header__menu__item">
                  <Link to="/books" className={linksClassName.books}>
                    Books
                  </Link>
                </li>

                <li className="layout-header__menu__item">
                  <Link to="/features" className={linksClassName.features}>
                    Features
                  </Link>
                </li>

                <li className="layout-header__menu__item">
                  <Link to="/collections" className={linksClassName.collections}>
                    Collections
                  </Link>
                </li>
              </ul>

              <ul className="layout-header__menu layout-header__menu--oven">
                <NoSsr defer>
                  {homeConnectEnabled ? (
                    <li className="layout-header__menu__item">
                      <HomeConnectMenu disableSwipeToOpen className="layout-header__hc" />
                    </li>
                  ) : null}
                </NoSsr>
                <li className="layout-header__menu__item">
                  <NotificationButton />
                </li>
              </ul>

              <ul className="layout-header__menu layout-header__menu--side">
                <li className="layout-header__menu__item">
                  <UserMenu loginLinkClassName="layout-header__menu__link layout-header__menu__link--shifted" />
                </li>
              </ul>
            </nav>
          </div>
          <NoSsr>
            {searchOnLoad || search.searchViewRequired ? (
              <Suspense fallback={null}>
                <SearchResultsLayout visible={isSearchView} ref={this.searchResultsRef} />
              </Suspense>
            ) : null}
          </NoSsr>
        </div>
      </SearchProvider>
    );
  }
}

const mapStateToProps = state => ({
  displayType: state.dimensions.displayType,
  examples: state.search.examples.items,
  router: state.router,
  search: state.search,
  avatarMenuVisible: state.avatarMenu.isOpen,
  homeConnectEnabled: !!state.user.home_connect_enabled,
});

const mapDispatchToProps = {
  searchIsActive,
  openAvatarMenu,
  closeAvatarMenu,
  loadSearchView,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(CssLayoutHeader)(LayoutHeader));

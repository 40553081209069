exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".not-found{display:block;font-family:\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif;margin:0 auto;text-align:center;width:60%}.not-found__image{height:auto;max-width:100%}.not-found__heading{font-size:45.712px;font-size:2.857rem;font-weight:700;line-height:1.2;text-align:left}.not-found__paragraph{font-size:22.864px;font-size:1.429rem;text-align:left}@media (max-width: 639px){.not-found{width:100%}.not-found__image{max-width:50%}.not-found__heading{font-size:1.786rem}}\n", ""]);

// exports
exports.locals = {
	"not-found": "not-found",
	"not-found__image": "not-found__image",
	"not-found__heading": "not-found__heading",
	"not-found__paragraph": "not-found__paragraph"
};
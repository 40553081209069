/* eslint-disable import/prefer-default-export */
import { MODAL_CLOSE, MODAL_OPEN, OVEN_MENU_CLOSE, AVATAR_MENU_CLOSE } from 'constants/index';

export function openModal(payload) {
  return dispatch => {
    dispatch({
      type: MODAL_OPEN,
      payload,
    });
    dispatch({
      type: OVEN_MENU_CLOSE,
    });
    dispatch({
      type: AVATAR_MENU_CLOSE,
    });
  };
}

export function closeModal() {
  return dispatch => {
    dispatch({
      type: MODAL_CLOSE,
    });
    dispatch({
      type: OVEN_MENU_CLOSE,
    });
    dispatch({
      type: AVATAR_MENU_CLOSE,
    });
  };
}

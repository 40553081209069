exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".generic-error{align-items:center;display:flex;height:75vh;justify-content:center;margin:0 auto;width:60%}.generic-error p{font-size:22.864px;font-size:1.429rem}@media (max-width: 639px){.generic-error{width:100%}}\n", ""]);

// exports
exports.locals = {
	"generic-error": "generic-error"
};
/* eslint-disable jsx-a11y/heading-has-content */

// node modules
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

// assets
import CssGeneric from './Generic.scss';

class Generic extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  };

  render() {
    const { children } = this.props;

    return <div className="generic-error">{children}</div>;
  }
}

export default withStyles(CssGeneric)(Generic);

import React from 'react';

import useFeatureToggle from 'hooks/useFeatureToggle';

function withFeatureToggle(WrappedComponent, id) {
  return props => {
    const [enabled] = useFeatureToggle(id);
    const featureToggleProp = { [`${id}Enabled`]: !!enabled };
    return <WrappedComponent {...featureToggleProp} {...props} />;
  };
}

export default withFeatureToggle;

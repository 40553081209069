import { SET_ADVERT_VISIBILITY } from '../constants';

export default function advertsReducers(state = {}, action) {
  switch (action.type) {
    case SET_ADVERT_VISIBILITY:
      return { ...state, visible: action.payload || false };

    default:
      return state;
  }
}

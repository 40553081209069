import {
  IN_APP_PAYMENT_ERROR,
  IN_APP_PAYMENT_INIT,
  IN_APP_PAYMENT_PRODUCT_UPDATED,
  IN_APP_PAYMENT_PURCHASE_CANCELLED,
  IN_APP_PAYMENT_PURCHASE_COMPLETE,
  IN_APP_PAYMENT_PURCHASE_ERROR,
  IN_APP_PAYMENT_PURCHASE_START,
  IN_APP_PAYMENT_READY,
  IN_APP_PAYMENT_RESTORE_COMPLETE,
  IN_APP_PAYMENT_RESTORE_ERROR,
  IN_APP_PAYMENT_RESTORE_START,
  IN_APP_PAYMENT_CLEAR_ERRORS,
} from '../constants';

export default function inAppPaymentReducer(state = {}, action) {
  const { payload } = action;

  switch (action.type) {
    case IN_APP_PAYMENT_INIT:
      return {
        ...state,
        ready: false,
        purchaseInProgress: null,
        lastPurchaseError: null,
        products: payload.reduce(
          (products, product) => ({ ...products, [product.id]: product }),
          {},
        ),
      };
    case IN_APP_PAYMENT_READY:
      return { ...state, ready: true };
    case IN_APP_PAYMENT_PRODUCT_UPDATED:
      return { ...state, products: { ...state.products, [payload.id]: { ...payload } } };
    case IN_APP_PAYMENT_ERROR:
      return { ...state, purchaseInProgress: null, lastPurchaseError: payload };
    case IN_APP_PAYMENT_PURCHASE_START:
      return {
        ...state,
        purchaseInProgress: payload,
        lastPurchaseError: null,
        lastPurchaseCancelled: false,
      };
    case IN_APP_PAYMENT_PURCHASE_ERROR:
      return {
        ...state,
        purchaseInProgress: null,
        lastPurchaseError: payload,
        lastPurchaseCancelled: false,
      };
    case IN_APP_PAYMENT_PURCHASE_COMPLETE:
    case IN_APP_PAYMENT_PURCHASE_CANCELLED:
      return {
        ...state,
        purchaseInProgress: null,
        lastPurchaseError: null,
        lastPurchaseCancelled: action.type === IN_APP_PAYMENT_PURCHASE_CANCELLED,
      };
    case IN_APP_PAYMENT_RESTORE_START:
      return { ...state, restoreInProgress: true, lastPurchaseError: null, restoreError: null };
    case IN_APP_PAYMENT_RESTORE_COMPLETE:
      return { ...state, restoreInProgress: false, lastPurchaseError: null, restoreError: null };
    case IN_APP_PAYMENT_RESTORE_ERROR:
      return { ...state, restoreInProgress: false, lastPurchaseError: null, restoreError: payload };
    case IN_APP_PAYMENT_CLEAR_ERRORS:
      return {
        ...state,
        purchaseInProgress: null,
        lastPurchaseError: null,
        lastPurchaseCancelled: false,
      };
    default:
      return state;
  }
}

import { ApolloClient, from, HttpLink } from '@apollo/client';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import apolloLogger from 'apollo-link-logger';
import createCache from './createCache';
import { getStore } from '../../store/configureStore';

const persistedQueryLink = createPersistedQueryLink({ useGETForHashedQueries: true });
const canUseIdleCallback = typeof requestIdleCallback === 'function';
let cleanupJob;

export const cleanupApolloCache = client => {
  const doGC = () => {
    client.cache.gc();
  };
  if (canUseIdleCallback) {
    cancelIdleCallback(cleanupJob);
    cleanupJob = requestIdleCallback(doGC);
  } else {
    setTimeout(doGC, 0);
  }
};

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: error => error && error.response && error.response.status > 499,
  },
});

const authLink = tokenFactory =>
  setContext(async (request, context) => {
    const isMutation = request.query.definitions.some(
      operationDefinition => operationDefinition.operation === 'mutation',
    );

    if (isMutation || request.variables.userId || request.variables._authenticated === true) {
      // Send authentication for any request containing a userId param, or that has set the _authenticated flag.
      // This increases the cacheability of our responses since most API requests don't require auth.
      delete request.variables._authenticated;
      const token = await tokenFactory();

      // DEMO uses basic auth. Safari on iOS replaces the auth header with the browser basic auth one. So we use a different header.
      const { environment } = getStore().getState().config;
      const authHeaderName =
        environment === 'DEMO' || environment === 'DEV' ? 'X-Auth' : 'authorization';
      return {
        headers: {
          ...context.headers,
          [authHeaderName]: token ? `Bearer ${token}` : '',
        },
      };
    }
    delete request.variables._authenticated;
    return {};
  });

const httpLink = (baseUrl, onAuthFailure, onNetworkError, tokenFactory) =>
  from([
    persistedQueryLink,
    retryLink,
    authLink(tokenFactory),
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors)
        graphQLErrors
          .filter(
            ({ message }) => !message || (message && !message.startsWith('PersistedQueryNotFound')),
          )
          .map(({ message, locations, path }) =>
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
      if (networkError) {
        console.warn(`[Network error]: ${networkError}`);
        if (typeof onNetworkError === 'function') {
          onNetworkError(networkError);
        }
      }
      const { response } = operation.getContext();
      if (response && response.status === 401 && typeof onAuthFailure === 'function') {
        onAuthFailure(response);
      }
    }),
    ...(__DEV__ ? [apolloLogger] : []),
    new HttpLink({
      uri: `${baseUrl}/graphql`,
      credentials: 'include',
      headers: {
        'X-Original-Referer': document.referrer,
      },
      includeUnusedVariables: true,
    }),
  ]);

const cache = createCache();

export default async function createApolloClient({
  baseUrl,
  debug,
  onNetworkError,
  onAuthFailure,
  tokenFactory,
}) {
  if (window.cordova) {
    // We previously persisted the cache to local storage, but this caused issues with the app
    // Clean up the old entries in localstorage
    Object.keys(localStorage)
      .filter(key => key.startsWith('apollo-cache-'))
      .forEach(key => {
        localStorage.removeItem(key);
      });
  }

  return new ApolloClient({
    link: httpLink(baseUrl, onAuthFailure, onNetworkError, tokenFactory),
    cache: window.cordova ? cache : cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: !!debug,
  });
}

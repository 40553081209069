import set from 'lodash/set';

import { DIMENSIONS_SET } from '../constants';

export default function dimensionsReducer(state = {}, action) {
  switch (action.type) {
    case DIMENSIONS_SET: {
      const newState = Object.assign({}, state);

      Object.keys(action.payload.dimensions).forEach(key => {
        const value = action.payload.dimensions[key];
        set(newState, key, value);
      });

      return newState;
    }

    default: {
      return state;
    }
  }
}

/* eslint-disable import/prefer-default-export */

import { DEVICE_CONNECTION_STATE_CHANGE, DEVICE_SET_INFO } from '../constants';

export function updateConnectionState({ connected, type }) {
  return dispatch => {
    dispatch({
      type: DEVICE_CONNECTION_STATE_CHANGE,
      payload: { connected, type },
    });
  };
}

export function setDeviceInfo(deviceInfo) {
  return dispatch => {
    dispatch({
      type: DEVICE_SET_INFO,
      payload: deviceInfo,
    });
  };
}

function uuidv4() {
  // crypto.randomUUID() only works in Chrome.
  // credit to https://stackoverflow.com/a/2117523 for this alternative
  return crypto.randomUUID
    ? crypto.randomUUID()
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        // eslint-disable-next-line no-bitwise
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
      );
}

export function getWebClientId(baseUrl = '') {
  return async dispatch => {
    try {
      const response = await fetch(`${baseUrl}/clientId`, {
        credentials: 'same-origin',
      });
      if (!response.ok) {
        throw new Error(`HTTP status: ${response.status}`);
      }
      const clientId = await response.text();
      dispatch(setDeviceInfo({ clientId: clientId.trim() }));
    } catch (err) {
      console.warn(`Error fetching clientId`, err);
      // Assign one on the client instead. Not ideal but better than nothing,
      // although we won't be able to restrict the number of google first click free accesses for this user.
      // We must have a clientId for the inline paywall to work.
      dispatch(setDeviceInfo({ clientId: `LOCAL-${uuidv4()}` }));
    }
  };
}

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rc-tooltip.rc-tooltip--plain .rc-tooltip-inner{background-color:#fff;box-shadow:none;color:#333}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .rc-tooltip.rc-tooltip--plain .rc-tooltip-inner{background-color:#000;color:#fff}}:root[data-theme=dark] .rc-tooltip.rc-tooltip--plain .rc-tooltip-inner{background-color:#000;color:#fff}.rc-tooltip.rc-tooltip--plain .rc-tooltip-arrow{display:none}\n", ""]);

// exports
exports.locals = {
	"rc-tooltip": "rc-tooltip",
	"rc-tooltip--plain": "rc-tooltip--plain",
	"rc-tooltip-inner": "rc-tooltip-inner",
	"rc-tooltip-arrow": "rc-tooltip-arrow"
};
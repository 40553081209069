import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';

const TTL = 60 * 1000;
const QUERY = gql`
  query GetFeatureToggles {
    FeatureToggles {
      id
      enabled
      user_permission
    }
  }
`;

let lastUpdated = -1;

function useFeatureToggle(id) {
  const fetchPolicy =
    lastUpdated > new Date().getTime() - TTL ? 'cache-first' : 'cache-and-network';
  const { data, loading, error } = useQuery(QUERY, {
    fetchPolicy,
    onCompleted: () => {
      if (fetchPolicy === 'cache-and-network') {
        lastUpdated = new Date().getTime();
      }
    },
  });
  const userPermissions = useSelector(state => state.user.permissions || []);
  const features = data?.FeatureToggles;
  let enabled = null;
  if (error) {
    console.warn('Could not fetch feature toggle data', error);
    return [null, false, error];
  }
  if (features) {
    const feature = features.find(f => f.id === id);
    if (feature) {
      enabled = feature.enabled;
      if (feature.user_permission && userPermissions.includes(feature.user_permission)) {
        enabled = true;
      }
    }
  }
  return [enabled, loading, error];
}

export default useFeatureToggle;

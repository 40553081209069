/* eslint-disable react/no-danger */

// node modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import { gql, useQuery } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import classNames from 'classnames';
import { toast } from 'react-toastify';

// app modules: actions
import consentToCookies from 'actions/user/consentToCookies';

// app modules: components
import ToastContents from 'components/ToastContents';

// app modules: hooks
import usePriceInjector from 'hooks/usePriceInjector';

// assets
import CssBanner from './Banner.scss';

const GET_PROMOS = gql`
  query GetPromoBanners($displayType: DisplayType) {
    PromoBanner(displayType: $displayType) {
      id
      message
      style
    }
  }
`;

function Banner(props) {
  const { overlay } = props;
  const containerRef = useRef(null);
  useStyles(CssBanner);
  usePriceInjector(containerRef);
  const dispatch = useDispatch();

  const displayType = useSelector(state => state.dimensions.displayType);
  const membershipStatus = useSelector(state => state.user.subscription_status || 'NM');
  const isInitialPage = useSelector(state => state.router.isInitialPage);
  const cookieConsent = useSelector(state => state.user.cookie_consent);
  const localStorageKey = `disable-banner-${membershipStatus}`;

  const [disabledBannerId, setDisabledBannerId] = useState('_loading');
  const [userHasDismissed, setUserHasDismissed] = useState(false);
  const [cookieBannerShown, setCookieBannerShown] = useState(false);
  const [hiding, setHiding] = useState(true);

  useEffect(
    () => {
      try {
        setHiding(false);
        setDisabledBannerId(localStorage.getItem(localStorageKey));
        const savedCookieConsent =
          localStorage.getItem('CookieConsent') === 'true' || !!localStorage.getItem('last_logout');
        if (cookieConsent !== 'dismissed' && savedCookieConsent) {
          dispatch(consentToCookies('dismissed'));
        }
      } catch (err) {
        console.warn('Failed to parse banner config from localstorage', err);
      }
    },
    [localStorageKey, cookieConsent, dispatch],
  );

  const { data } = useQuery(GET_PROMOS, {
    variables: { _authenticated: true, displayType },
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
    ssr: false,
    skip: disabledBannerId === '_loading',
    onCompleted: ({ PromoBanner }) => {
      const banner = PromoBanner.find(
        _banner => _banner.id !== 'CookieConsent' && _banner.id !== disabledBannerId,
      );
      if (banner) {
        setHiding(false);
      }
      if (cookieConsent !== 'dismissed' && !cookieBannerShown) {
        const cookieMessage = PromoBanner.find(_banner => _banner.id === 'CookieConsent');
        if (cookieMessage?.message) {
          toast.success(
            <ToastContents>
              <div
                ref={containerRef}
                className="banner__content"
                dangerouslySetInnerHTML={{
                  __html: cookieMessage.message,
                }}
              />
            </ToastContents>,
            {
              position: toast.POSITION.BOTTOM_LEFT,
              hideProgressBar: true,
              autoClose: false,
              onClose: () => {
                dispatch(consentToCookies('dismissed'));
                localStorage.setItem('CookieConsent', 'true');
              },
            },
          );
          setCookieBannerShown(true);
          dispatch(consentToCookies('displayed'));
        } else {
          dispatch(consentToCookies('not_required'));
        }
      }
    },
  });
  const activeBanner = data?.PromoBanner.find(banner => banner.id !== 'CookieConsent');

  const handleDismissPromoBanner = () => {
    setHiding(true);
    setUserHasDismissed(true);
  };

  const handlePromoBannerDismissed = () => {
    localStorage.setItem(localStorageKey, activeBanner.id);
    setDisabledBannerId(activeBanner.id);
  };

  let content = null;
  if (activeBanner && activeBanner.id !== disabledBannerId) {
    const { id, message, style } = activeBanner;

    content = (
      <div key={id} className={`banner banner--${style}`}>
        <div className="banner__left" />
        <div
          ref={containerRef}
          className="banner__content"
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
        <button type="button" className="banner__close" onClick={handleDismissPromoBanner} />
      </div>
    );
  }

  return (
    <div
      className={classNames('banner-wrapper', {
        'banner-wrapper--reserve-space': !overlay && isInitialPage && !userHasDismissed,
        'banner-wrapper--overlay': overlay,
      })}
    >
      <Collapse
        collapsedHeight={0}
        timeout={300}
        in={!hiding}
        onExited={() => handlePromoBannerDismissed()}
      >
        {content}
      </Collapse>
    </div>
  );
}

Banner.propTypes = {
  overlay: PropTypes.bool,
};

Banner.defaultProps = {
  overlay: false,
};

export default Banner;

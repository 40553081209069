/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';

import {
  ACCESS_LOG_POST_START,
  ACCESS_LOG_POST_SUCCESS,
  ACCESS_LOG_POST_ERROR,
} from '../constants';

export const ACCESS_TYPE_NORMAL = 'normal';
export const ACCESS_TYPE_EXTENDED = 'extended';
export const ACCESS_TYPE_PAYWALL = 'paywall';

export function logAccess(payload) {
  const variables = Object.assign({}, payload, {
    userAgent: navigator.userAgent,
    id: String(payload.id),
  });
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: ACCESS_LOG_POST_START,
      payload,
    });
    try {
      const { data } = await client.mutate({
        mutation: gql`
          mutation LogUserAccess(
            $id: String!
            $accessType: AccessType!
            $contentType: ContentType!
            $contentHash: String
            $isbn: String
            $userAgent: String!
            $resultCount: Int
            $clientId: String
            $alacarte: Boolean
          ) {
            LogUserAccess(
              id: $id
              accessType: $accessType
              contentType: $contentType
              contentHash: $contentHash
              isbn: $isbn
              userAgent: $userAgent
              resultCount: $resultCount
              clientId: $clientId
              alacarte: $alacarte
            )
          }
        `,
        variables,
        operationName: 'LogUserAccess',
      });

      dispatch({
        type: ACCESS_LOG_POST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ACCESS_LOG_POST_ERROR,
        payload: {
          error,
        },
      });
    }
  };
}

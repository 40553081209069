exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footer{background-color:#fff;margin:0 auto;max-width:1024px;padding:25px 0;position:relative;width:100%;z-index:2}.footer::after{clear:both;content:' ';display:block;font-size:0;height:0;overflow:hidden;width:100%}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .footer{background-color:#000}}:root[data-theme=dark] .footer{background-color:#000}.footer__logo{float:left}.footer__logo__image{display:block;height:30px;width:91px}.footer__navigation{float:right;margin-top:5px}.footer__menu::after{clear:both;content:' ';display:block;font-size:0;height:0;overflow:hidden;width:100%}.footer__menu__item{float:left;margin-left:45px}.footer__menu__item:first-child{margin-left:0}.footer__menu__link{border-bottom:2px solid transparent;font-family:\"Bebas Neue\",\"bebas-fallback\",\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif;font-size:1.286em}.footer__menu__link:hover{border-bottom-color:#bfbfbf}@media (max-width: 1223px){.footer__navigation{margin-right:calc((1224px - 100vw)/2)}}@media (max-width: 1023px){.footer{padding-left:15px;padding-right:15px}.footer__navigation{margin-right:85px}}@media (max-width: 767px){.footer__logo{float:none}.footer__navigation{float:left;margin-right:0;margin-top:20px;width:100%}}@media (max-width: 639px){.footer__menu__item{margin-bottom:10px;margin-left:0;width:50%}}@media (prefers-color-scheme: dark){:root:not([data-theme='light']):not([data-theme='dark']) .footer{background-color:#000;color:#fff}}:root[data-theme='dark'] .footer{background-color:#000;color:#fff}\n", ""]);

// exports
exports.locals = {
	"footer": "footer",
	"footer__logo": "footer__logo",
	"footer__logo__image": "footer__logo__image",
	"footer__navigation": "footer__navigation",
	"footer__menu": "footer__menu",
	"footer__menu__item": "footer__menu__item",
	"footer__menu__link": "footer__menu__link"
};
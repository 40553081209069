import { MODALS } from '../constants/view';
import { MODAL_CLOSE, MODAL_OPEN } from '../constants';

export default function modalsReducers(state = {}, action) {
  switch (action.type) {
    case MODAL_OPEN:
      return Object.assign({}, state, {
        openModalName: action.payload.name,
        content: action.payload.content,
        props: action.payload.props,
      });

    case MODAL_CLOSE:
      return Object.assign({}, state, {
        openModalName: MODALS.NULL,
        content: null,
        props: null,
      });

    default:
      return state;
  }
}

import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import auth from './auth';
import advertReducers from './adverts';
import avatarMenuReducers from './avatarMenu';
import cookieReducers from './cookies';
import configReducers from './config';
import chaptersReducers from './chapters';
import deviceReducers from './device';
import dimensionsReducers from './dimensions';
import entitiesListReducer from './entitiesList';
import featureUsageReducer from './featureUsage';
import favouritesReducers from './favourites';
import homeConnectReducers from './homeconnect';
import inAppPaymentReducers from './inAppPayment';
import meteredAccessReducers from './meteredAccess';
import modalsReducers from './modals';
import paywallReducers from './paywall';
import routerReducers from './router';
import searchReducers from './search';
import sidemenuReducers from './sidemenu';
import tableOfContentsReducers from './tableOfContents';
import userReducers from './user';
import conversionReducer from './conversion';

export default function createRootReducer() {
  return combineReducers({
    adverts: advertReducers,
    auth,
    avatarMenu: avatarMenuReducers,
    config: configReducers,
    cookies: cookieReducers,
    chapters: chaptersReducers,
    device: deviceReducers,
    dimensions: dimensionsReducers,
    entitiesList: entitiesListReducer,
    featureUsage: featureUsageReducer,
    favourites: favouritesReducers,
    form,
    homeConnect: homeConnectReducers,
    inAppPayment: inAppPaymentReducers,
    meteredAccess: meteredAccessReducers,
    modals: modalsReducers,
    paywall: paywallReducers,
    router: routerReducers,
    search: searchReducers,
    sidemenu: sidemenuReducers,
    tableOfContents: tableOfContentsReducers,
    user: userReducers,
    conversion: conversionReducer,
  });
}

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".hamburger{background-color:transparent;border:0;color:inherit;cursor:pointer;display:flex;justify-content:center;align-items:center;font:inherit;margin:0;overflow:visible;padding:5px 0;text-transform:none;transition-duration:.15s;transition-property:opacity, -webkit-filter;transition-property:opacity, filter;transition-property:opacity, filter, -webkit-filter;transition-timing-function:linear}.hamburger:hover{opacity:.7}@media (min-width: 769px){.hamburger{display:none}}.hamburger-box{display:inline-block;height:19px;position:relative;vertical-align:top;width:30px}.hamburger-inner{display:block;margin-top:-1.5px;top:50%}.hamburger-inner,.hamburger-inner::before,.hamburger-inner::after{background-color:#000;border-radius:4px;height:3px;position:absolute;transition-duration:0.15s;transition-property:transform;transition-timing-function:ease;width:30px}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .hamburger-inner,:root:not([data-theme=light]):not([data-theme=dark]) .hamburger-inner::before,:root:not([data-theme=light]):not([data-theme=dark]) .hamburger-inner::after{background-color:#fff}}:root[data-theme=dark] .hamburger-inner,:root[data-theme=dark] .hamburger-inner::before,:root[data-theme=dark] .hamburger-inner::after{background-color:#fff}.hamburger-inner::before,.hamburger-inner::after{content:'';display:block}.hamburger-inner::before{top:-8px}.hamburger-inner::after{bottom:-8px}\n", ""]);

// exports
exports.locals = {
	"hamburger": "hamburger",
	"hamburger-box": "hamburger-box",
	"hamburger-inner": "hamburger-inner"
};
/* eslint-disable import/prefer-default-export */

import { DIMENSIONS_SET } from '../constants';

export function setDimensions(dimensions) {
  return dispatch => {
    dispatch({
      type: DIMENSIONS_SET,
      payload: {
        dimensions,
      },
    });
  };
}

/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';
import {
  SEARCH_IS_EMPTY,
  SEARCH_IS_ACTIVE,
  SEARCH_IS_LOADING,
  SEARCH_EXAMPLES_GET_START,
  SEARCH_EXAMPLES_GET_SUCCESS,
  SEARCH_EXAMPLES_GET_ERROR,
  SEARCH_VIEW_REQUIRED,
} from '../constants';

const GET_SEARCH_EXAMPLES = gql`
  query GetSampleSearches {
    SampleSearches {
      text
      url
      note
      display_index
    }
  }
`;

const SEARCH_EXAMPLES_TTL = 30 * 1000;

export function searchIsEmpty(isEmpty) {
  return dispatch => {
    dispatch({
      type: SEARCH_IS_EMPTY,
      payload: {
        isEmpty,
      },
    });
  };
}

export function searchIsActive(isActive) {
  return dispatch => {
    dispatch({
      type: SEARCH_IS_ACTIVE,
      payload: {
        isActive,
      },
    });
  };
}

export function searchIsLoading(isLoading) {
  return dispatch => {
    dispatch({
      type: SEARCH_IS_LOADING,
      payload: {
        isLoading,
      },
    });
  };
}

export function loadSearchView() {
  return dispatch => {
    dispatch({
      type: SEARCH_VIEW_REQUIRED,
    });
  };
}

export function getSearchExamples() {
  return async (dispatch, getState, { client }) => {
    const { search } = getState();

    if (
      search.examples.state === 'loaded' &&
      search.examples.fetched_at > new Date().getTime() - SEARCH_EXAMPLES_TTL
    ) {
      return Promise.resolve();
    }

    dispatch({
      type: SEARCH_EXAMPLES_GET_START,
    });

    try {
      const { data } = await client.query({
        query: GET_SEARCH_EXAMPLES,
        fetchPolicy: 'network-only',
      });

      return dispatch({
        type: SEARCH_EXAMPLES_GET_SUCCESS,
        payload: {
          examples: data.SampleSearches,
        },
      });
    } catch (error) {
      return dispatch({
        type: SEARCH_EXAMPLES_GET_ERROR,
        payload: {
          error,
        },
      });
    }
  };
}

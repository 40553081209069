// node modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ToastContents extends Component {
  static propTypes = {
    headline: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    headline: '',
  };

  render() {
    const { headline, children } = this.props;
    return (
      <div>
        {headline && <strong>{headline}</strong>}
        <div>{children}</div>
      </div>
    );
  }
}

export default ToastContents;

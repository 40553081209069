import { isApp } from './environment';
import { getState } from '../store/configureStore';
import NativeLogsError from './errors/nativeLogsError';
import EmailComposerError from './errors/emailComposerError';

export function sendEvent(event) {
  if (window) {
    const { user } = getState();
    if (isApp()) {
      if (window.cordova?.plugins?.firebase?.analytics) {
        const { event: eventName, ...params } = event;
        window.cordova.plugins.firebase.analytics.logEvent(eventName, params);
      }
    } else {
      window.dataLayer = window.dataLayer || [];
      if (user) {
        window.dataLayer.push({
          ...event,
          userId: user.id > 0 ? user.id : undefined,
          subscriptionStatus: user.id > 0 ? user.subscription_status : undefined,
        });
      } else {
        window.dataLayer.push(event);
      }
    }
  }
}

export function sendPaddleEvent(event) {
  // Legacy way - send the events to GTM and let it map them.
  // Still needed for the Facebook and UA integration?
  sendEvent(event);
  // New way - using the method defined in the GA4 docs
  try {
    const { user } = getState();
    if (window && event.eventData) {
      let eventName;
      switch (event.event) {
        case 'Checkout.Loaded':
          eventName = 'begin_checkout';
          break;
        case 'Checkout.PaymentMethodSelected':
          eventName = 'add_payment_info';
          break;
        default:
          eventName = undefined;
      }
      if (event.eventData.checkout) {
        const { eventData } = event;
        const { checkout } = eventData;

        let alacarteItemId = null;
        if (checkout?.passthrough) {
          const { isbn, type } = JSON.parse(checkout.passthrough);
          if (type === 'alacarte') {
            alacarteItemId = isbn;
          }
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: eventName,
          ecommerce: {
            currency: checkout.prices?.customer?.currency,
            value: parseFloat(checkout.prices?.customer?.total || 0),
            coupon: checkout.coupon?.coupon_code,
            payment_type: eventData.payment_method,
            items: checkout.prices?.customer?.items?.map((item, index) => ({
              item_id: alacarteItemId || item.product_id,
              item_name: item.name,
              affiliation: 'paddle',
              coupon: item.discounts?.length ? item.discounts[0].code : undefined,
              discount: item.discounts?.length ? item.discounts[0].gross_discount : undefined,
              index,
              price: parseFloat(item.line_price?.gross_after_discount || 0),
              quantity: item.quantity,
            })),
          },
          userId: user?.id || null,
          subscriptionStatus: user?.subscription_status || null,
        });
      }
    }
  } catch (e) {
    console.error('Failed to update ecommerce data', e);
  }
}

export function getGoogleAnalyticsClientId() {
  if (window?.dataLayer) {
    const [, measurementId] =
      document
        .querySelector('script[src*="googletagmanager.com/gtag/js"]')
        ?.src?.match(/id=([^&]+)&/) || [];
    if (measurementId) {
      return new Promise(resolve =>
        // eslint-disable-next-line func-names
        (function() {
          // eslint-disable-next-line prefer-rest-params
          window.dataLayer.push(arguments);
        })('get', measurementId, 'client_id', resolve),
      );
    }
  }
  return null;
}

// eslint-disable-next-line consistent-return
export async function sendTechEvent(name, properties = {}) {
  // TODO send this to a collector on the server
  console.info('Event:', name, properties);
}

export function sendIntercomEvent(name, payload) {
  if (window.Intercom) {
    // Web SDK
    window.Intercom('trackEvent', name, payload);
    return true;
  } else if (window.intercom) {
    // Native SDK via cordova plugin
    window.intercom.logEvent(name, payload);
    return true;
  }
  return false;
}

export function openComposerEmailComposerPlugin(message, subject, to, cc, bcc) {
  if (!window || !window.email) {
    console.error('EmailComposer is not installed');

    return Promise.reject(new EmailComposerError('EmailComposer is not installed'));
  }

  return new Promise(resolve => {
    window.email.open({
      to, // email addresses for TO field
      cc, // email addresses for CC field
      bcc, // email addresses for BCC field
      subject, // subject of the email
      body: message, // email body
      resolve,
    });
  });
}

export function getLogNativeLogsPlugin() {
  if (!window || !window.NativeLogs) {
    console.error('NativeLogs is not installed');

    return Promise.reject(new NativeLogsError('NativeLogs is not installed'));
  }

  return new Promise((resolve, reject) => {
    window.NativeLogs.getLog(1000, false, resolve, err => reject(new NativeLogsError(err)));
  });
}

export function hasAccountEmailComposerPlugin() {
  if (!window || !window.email) {
    console.error('EmailComposer is not installed');

    return Promise.reject(new EmailComposerError('EmailComposer is not installed'));
  }

  return new Promise((resolve, reject) => {
    window.email.hasAccount(result => {
      console.info('has account res = ', result);
      if (result) {
        resolve();

        return;
      }

      reject(new EmailComposerError('Device have no email account'));
    });
  });
}

export async function sendLogsByEmail(extraInfo) {
  const { device, user } = getState();

  try {
    const logs = await getLogNativeLogsPlugin();

    const subject = 'ckbk application logs';
    const message = `Dear ckbk,
                              Please find attached my application logs.

                              ${extraInfo ? `${extraInfo}\n` : ''}
                              [Add further information about the problem here if you wish...]

                              --
                              ID: ${user.id}
                              Email: ${user.email}
                              Membership Status: ${user.subscription_status}
                              Login at: ${user.last_login_at}
                              User Agent: ${navigator.userAgent}
                              App version: ${window.App.version}
                              App build: ${window.App.build}
                              Connection Type: ${device.connectionType}

                              ________________________________________________________
                              __________________________LOGS__________________________
                              ________________________________________________________

                              ${logs}
                              `;
    const to = ['alerts@ckbk.com'];
    const cc = null;
    const bcc = null;

    await openComposerEmailComposerPlugin(message, subject, to, cc, bcc);
  } catch (e) {
    console.error('Failed to get sendLogsByEmail = ', e);
  }
}

export default {
  sendEvent,
  sendTechEvent,
  sendLogsByEmail,
  sendIntercomEvent,
};

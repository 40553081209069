import React, { useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// app: components
import EzoicPlaceholder from 'components/EzoicPlaceholder';

// app: hooks
import useExperiment from 'hooks/useExperiment';
import useFeatureToggle from 'hooks/useFeatureToggle';

// app: services
import assets from 'services/assets';
import { isApp } from 'services/environment';

// app: constants
import { BREAKPOINTS } from 'constants/index';

import AdSense from './AdSense';
import CssAdSense from './Advert.scss';

function Advert(props) {
  const { className, format, slotName, hideOnMobile, ezoicPlaceholderId, lazy } = props;
  useStyles(CssAdSense);
  const [filled, setFilled] = useState(null);
  const ezoicPercentage = useSelector(state => state.config.adverts.ezoicPercentage);
  const [ezoicFeatureToggleEnabled] = useFeatureToggle('ezoicJs');
  const [advertsEnabled] = useFeatureToggle('adverts');
  const ezoicEnabled = useExperiment({
    id: 'ezoic',
    percentage: ezoicPercentage,
  });
  const slot = useSelector(state => state.config.adverts.slots[slotName]);
  // eslint-disable-next-line camelcase
  const hasValidSubscription = useSelector(state => state.user.subscription?.is_valid || false);
  const contentAllowsAds = useSelector(state => state.adverts.visible);
  const isCorporate = useSelector(state => state.user.geolocation.corporate);

  const handleAdReady = advert => {
    setFilled(advert.filled);
  };

  if (hasValidSubscription || !contentAllowsAds || !advertsEnabled || isCorporate) {
    return null;
  }

  // Integration point for AdSense and other ad providers
  let advert = null;
  if (!isApp()) {
    if (slot && (!ezoicEnabled || !ezoicFeatureToggleEnabled)) {
      advert = (
        <AdSense
          onReady={handleAdReady}
          slot={slot}
          hideIfUnfilled={!(slot && slot.fallbackImage && slot.fallbackLink)}
          {...props}
        />
      );
    }
    if (ezoicPlaceholderId && ezoicFeatureToggleEnabled) {
      advert = (
        <EzoicPlaceholder
          enabled={ezoicEnabled}
          id={ezoicPlaceholderId}
          lazy={lazy}
          className={`advert--${format}`}
        >
          {advert}
        </EzoicPlaceholder>
      );
    }
  }
  if (advert) {
    let fallback;
    if (slot && slot.fallbackImage && slot.fallbackLink && filled === false) {
      fallback = (
        <div className="advert__fallback">
          <a href={slot.fallbackLink} rel="noopener noreferrer" target="_blank">
            <picture>
              <source
                media={`(max-width: ${BREAKPOINTS.TABLET_VERTICAL}px)`}
                srcSet={assets.srcSet(
                  slot.fallbackImageMobile,
                  [
                    { w: 320, h: 50, resize: 'fit-in' },
                    { w: 320 * 2, h: 50 * 2, resize: 'fit-in' },
                  ],
                  assets.adUrl,
                )}
              />
              <source
                media={`(min-width: ${BREAKPOINTS.TABLET_VERTICAL + 1}px)`}
                srcSet={assets.srcSet(
                  slot.fallbackImage,
                  [
                    { w: 728, h: 90, resize: 'fit-in' },
                    { w: 728 * 2, h: 90 * 2, resize: 'fit-in' },
                  ],
                  assets.adUrl,
                )}
              />
              <img
                className={`advert__fallback--${format}`}
                src={assets.adUrl(slot.fallbackImage, {
                  resize: 'fit-in',
                  width: 728,
                  height: 90,
                })}
                alt="Advertisement"
              />
            </picture>
          </a>
        </div>
      );
    }
    return (
      <div
        className={classNames('advert', className, {
          'advert--hide-on-mobile': hideOnMobile,
        })}
      >
        {ezoicEnabled ? null : (
          <p className="advert__disclosure" hidden={filled === false && !fallback}>
            Advertisement
          </p>
        )}
        {fallback || advert}
      </div>
    );
  }
  return null;
}

Advert.propTypes = {
  className: PropTypes.string,
  ezoicPlaceholderId: PropTypes.number,
  format: PropTypes.string,
  fullWidthResponsive: PropTypes.bool,
  hideOnMobile: PropTypes.bool,
  lazy: PropTypes.bool,
  slotName: PropTypes.string,
};

Advert.defaultProps = {
  className: '',
  ezoicPlaceholderId: null,
  format: 'default',
  fullWidthResponsive: null,
  hideOnMobile: false,
  lazy: false,
  slotName: null,
};

export default React.memo(Advert);

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".territorial-restriction{margin-top:30px;text-align:center}.territorial-restriction__paragraph{font-size:22.864px;font-size:1.429rem}\n", ""]);

// exports
exports.locals = {
	"territorial-restriction": "territorial-restriction",
	"territorial-restriction__paragraph": "territorial-restriction__paragraph"
};
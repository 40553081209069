/* eslint-disable import/prefer-default-export */
import { SET_ADVERT_VISIBILITY } from 'constants/index';

export function setAdvertVisibility(payload) {
  return dispatch => {
    dispatch({
      type: SET_ADVERT_VISIBILITY,
      payload,
    });
  };
}

import { CHAPTERS_GET_START, CHAPTERS_GET_SUCCESS, CHAPTERS_GET_ERROR } from '../constants';

export default function chaptersReducer(state = {}, action) {
  const data = {};

  switch (action.type) {
    case CHAPTERS_GET_START:
      return {
        [action.payload.isbn]: {
          state: 'loading',
          chapters: [],
        },
      };

    case CHAPTERS_GET_SUCCESS:
      data[action.payload.isbn] = {
        state: 'loaded',
        chapters: action.payload.chapters,
      };
      return Object.assign({}, state, data);

    case CHAPTERS_GET_ERROR:
      data[action.payload.isbn] = {
        state: 'error',
        chapters: [],
      };
      return Object.assign({}, state, data);

    default:
      return state;
  }
}

import { BASE_FONT_SIZE, BASE_FONT_SIZE_DESKTOP } from '../../constants';

function adjustFontSizes(multiplier, isOnDesktop = false) {
  const baseFont = isOnDesktop ? BASE_FONT_SIZE_DESKTOP : BASE_FONT_SIZE;
  const newSize = multiplier > 0 ? baseFont * multiplier : baseFont / Math.abs(multiplier);

  if (multiplier === 1 && document.body.attributes.getNamedItem('style')) {
    document.body.attributes.removeNamedItem('style');
  } else {
    document.body.style.fontSize = `${newSize}px`;
  }
}

export default adjustFontSizes;

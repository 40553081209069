import PropTypes from 'prop-types';

export default PropTypes.shape({
  isOpen: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string,
    chapter: PropTypes.number,
    isbn: PropTypes.string,
  }),
});

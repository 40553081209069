/* eslint-disable import/first */

// node modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  SearchkitProvider,
  ResetSearchAccessor,
  BoolMust,
  BoolShould,
  TermQuery,
  TermsQuery,
  MenuFilter,
  ResetFilters,
  SortingSelector,
} from 'searchkit';
import qs from 'qs';
import isEqual from 'lodash/isEqual';

// app modules: actions
import { searchIsEmpty, searchIsLoading } from 'actions/search';
import { featureUsed } from 'actions/featureUsage';
import { logAccess, ACCESS_TYPE_NORMAL } from 'actions/logging';

// app modules: components
import withFeatureToggle from 'components/FeatureToggleHoC';

// app modules: services
import { sendEvent } from 'services/analytics';
import history from 'services/history';
import search from 'services/search';
import SearchkitManagerCustom from 'services/SearchkitManagerCustom';

// app modules: constants
import { FILTERS, SORT_OPTIONS } from 'constants/search';
import { CONTENT_TYPES } from 'constants/index';

export const SearchkitContext = React.createContext();
class SearchProvider extends Component {
  static propTypes = {
    alacarteEnabled: PropTypes.bool.isRequired,
    allowEmptySearch: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    connected: PropTypes.bool,
    defaultQuery: PropTypes.func,
    featureUsed: PropTypes.func.isRequired,
    getLocation: PropTypes.func,
    includeTags: PropTypes.arrayOf(PropTypes.string),
    logAccess: PropTypes.func.isRequired,
    minimumSearchLength: PropTypes.number,
    onResultsChange: PropTypes.func,
    ownedBooks: PropTypes.arrayOf(PropTypes.string),
    router: PropTypes.shape({
      state: PropTypes.string,
    }).isRequired,
    search: PropTypes.shape({ isLoading: PropTypes.bool }),
    searchEndpoint: PropTypes.string,
    searchIsEmpty: PropTypes.func.isRequired,
    searchIsLoading: PropTypes.func.isRequired,
    searchOnLoad: PropTypes.bool,
    showAlacarte: PropTypes.bool,
    userCountryCode: PropTypes.string,
    userIsLoading: PropTypes.bool,
  };

  static defaultProps = {
    allowEmptySearch: false,
    connected: true,
    defaultQuery: query =>
      query.addFilter(
        'available',
        BoolMust([TermQuery('is_licensed', true), TermQuery('is_indexable', true)]),
      ),
    getLocation: () => ({
      pathname: history.location.pathname === '/search/fulltext' ? '/search/fulltext' : '/search',
      search: history.location.search,
    }),
    includeTags: null,
    minimumSearchLength: 1,
    onResultsChange: () => {},
    ownedBooks: [],
    searchEndpoint: '/es-search',
    searchOnLoad: true,
    search: {
      isLoading: false,
    },
    showAlacarte: false,
    userCountryCode: null,
    userIsLoading: false,
  };

  constructor(props) {
    super(props);

    this.handler = {
      onChange: null,
      onResultsChange: null,
    };

    this.bound = {
      queryProcessor: this.queryProcessor.bind(this),
      onChange: this.onChange.bind(this),
      onResultsChange: this.onResultsChange.bind(this),
      onSearchSessionChange: this.onSearchSessionChange.bind(this),
    };

    this.searchkit = new SearchkitManagerCustom(props.searchEndpoint, {
      useHistory: !!process.env.BROWSER,
      createHistory: () => history,
      searchOnLoad: props.searchOnLoad,
      getLocation: props.getLocation,
    });

    this.searchkit.setQueryProcessor(this.bound.queryProcessor);
    this.searchkit.addDefaultQuery(this.props.defaultQuery);
    this.searchkit.setSearchSessionListener(this.bound.onSearchSessionChange);

    this.awaitingInitialResults = history.location.pathname === '/search';
  }

  componentDidMount() {
    this.handler.onChange = this.searchkit.emitter.addListener(this.bound.onChange);
    this.handler.onResultsChange = this.searchkit.addResultsListener(this.bound.onResultsChange);
  }

  componentDidUpdate(prevProps) {
    const query = qs.parse(history.location.search.replace(/^\?/, ''));
    const sameQuery = Object.keys(query).reduce((previousResult, key) => {
      if (previousResult) {
        return isEqual(query[key], this.searchkit.state[key]);
      }
      return previousResult;
    }, true);

    // When should we trigger a reset of the query?
    const isSearchView = history.location.pathname === '/search';

    const isNewSearch = isSearchView && !sameQuery;
    const navigationComplete =
      prevProps.router.state === 'loading' && this.props.router.state === 'loaded';
    if (this.props.connected && navigationComplete && isNewSearch && !this.props.userIsLoading) {
      const resetAccessor = this.searchkit.accessors.getAccessorByType(ResetSearchAccessor);
      if (resetAccessor) {
        resetAccessor.performReset();
        if (history.location.search) {
          this.searchkit.searchFromUrlQuery(history.location.search);
        }
      }
    }
  }

  componentWillUnmount() {
    this.handler.onChange();
    this.handler.onResultsChange();
  }

  /**
   * `onChange` handler. Triggered before and after search.
   */
  onChange() {
    if (this.props.search.isLoading !== this.searchkit.loading) {
      this.props.searchIsLoading(this.searchkit.loading);
    }
  }

  onSearchSessionChange(session) {
    this.logSearch(session.state, session.results);
  }

  onResultsChange() {
    const { accessors } = this.searchkit;
    const { statefulAccessors } = accessors;
    if (this.awaitingInitialResults && this.searchkit.results) {
      this.logSearch(this.searchkit.state, this.searchkit.results);
      this.awaitingInitialResults = false;
    }

    const areFiltersEmpty = Object.values(FILTERS).every(filter =>
      search.isAccessorEmpty(statefulAccessors[filter]),
    );

    if (areFiltersEmpty === false) {
      this.props.featureUsed('feature_search_filters');
    }

    this.props.searchIsEmpty(this.isEmpty());

    const changeHook = this.props.onResultsChange;
    changeHook(this.searchkit.results, this.searchkit);
  }

  async logSearch(state, results) {
    let resultCount = null;
    if (results) {
      resultCount = results.hits.total;
    }
    if (state.q) {
      try {
        await this.props.logAccess({
          accessType: ACCESS_TYPE_NORMAL,
          contentType: CONTENT_TYPES.SEARCH,
          id: JSON.stringify(state),
          resultCount,
        });
        sendEvent({
          event: 'Search.Query',
          query: state,
          resultCount,
        });
      } catch (err) {
        console.error('Cannot log search', err);
      }
    }
  }

  isEmpty() {
    const { minimumSearchLength } = this.props;
    const { accessors } = this.searchkit;
    return search.isEmptyState(accessors, FILTERS, minimumSearchLength);
  }

  queryProcessor(queryObject) {
    const {
      alacarteEnabled,
      allowEmptySearch,
      minimumSearchLength,
      includeTags,
      ownedBooks,
      showAlacarte,
      userCountryCode,
    } = this.props;
    const { accessors } = this.searchkit;

    // Deep copy the query. Searchkit does strange things if you mutate the queryObject
    const newQueryObject = JSON.parse(JSON.stringify(queryObject));

    // Filter out hidden content.
    // The user might have some tags that they want to query on, and
    // content matching those tags should override the hidden filter.
    const filters = [];
    if (includeTags && includeTags.length) {
      // Remove hidden content, but include some tags which may include hidden content.
      filters.push(
        BoolShould([
          TermQuery('is_hidden', false),
          ...includeTags.map(tag => TermQuery('book_tags', tag)),
        ]),
      );
    } else {
      // Just remove hidden content without querying tags
      filters.push(TermQuery('is_hidden', false));
    }
    // By default filter out alacarte content unless the user owns it
    const alacarteFilters = [
      // Always show content that is not alacarte
      TermQuery('is_alacarte', false),
      // always show content that you own
      TermsQuery('book_isbn', ownedBooks || []),
      // always show authors and collections regardless of the alacarte setting
      TermsQuery('label.raw', ['book', 'person', 'collection']),
    ];
    if (showAlacarte) {
      // If the user settings allow unpurchased alacarte then we will include it, but only items that can be purchased in that country
      const showAlacarteForRegion = [TermQuery('is_alacarte', true)];
      if (userCountryCode) {
        showAlacarteForRegion.push(TermQuery('allow_purchase_from', userCountryCode));
      }
      alacarteFilters.push(BoolMust(showAlacarteForRegion));
    }
    if (alacarteEnabled) {
      filters.push(BoolShould(alacarteFilters));
    }
    // We need to add that filter to the main post_filter and the filters for all aggregations
    const filterLists = [newQueryObject.post_filter?.bool?.must];
    filterLists.push(...Object.values(newQueryObject.aggs).map(agg => agg.filter?.bool?.must));
    filterLists.forEach(filterList => {
      if (Array.isArray(filterList)) {
        filterList.push(...filters);
      }
    });

    // Recipes that are Vegetarian or Vegan qualify to be displayed for the Pescatarian filter.
    // However, if I set the diet type filter to be Pescatarian on its own then I'm probably looking for fish.
    // We can put a negative boost on to documents in the results that match 'diet-type=Vegetarian' (or Vegan) in these cases.
    // See https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-boosting-query.html
    const negativeBoosts = [];

    const dietTypeAccessor = accessors.statefulAccessors.diet_type;
    if (dietTypeAccessor) {
      const queryText = accessors.queryAccessor.state.value;
      const dietTypeFilter = dietTypeAccessor.state.value;
      const queryTextIsPescatarian =
        queryText &&
        (queryText.toLowerCase().includes('pescatar') ||
          queryText.toLowerCase().includes('pescetar'));

      const dietTypeFilterIsPescatarian =
        Array.isArray(dietTypeFilter) && dietTypeFilter.includes('Pescatarian');

      if (queryTextIsPescatarian || dietTypeFilterIsPescatarian) {
        ['Vegetarian', 'Vegan'].forEach(dietTypeToSubdue => {
          if (!(dietTypeFilter && dietTypeFilter.includes(dietTypeToSubdue))) {
            negativeBoosts.push({ term: { [dietTypeAccessor.options.field]: dietTypeToSubdue } });
          }
        });
      }
    }
    if (negativeBoosts.length && newQueryObject.query && !newQueryObject.query.boosting) {
      // Wrap the regular query in a 'boosting' query with a negative section containing searches
      // for the documents we want to send deeper into the results.
      return {
        ...newQueryObject,
        query: {
          boosting: {
            positive: newQueryObject.query,
            negative: { bool: { should: negativeBoosts } },
            negative_boost: 0.5,
          },
        },
      };
    }

    if (!allowEmptySearch && search.isEmptyState(accessors, FILTERS, minimumSearchLength)) {
      // Stops search action
      return {
        size: 0,
        query: {
          match_none: {},
        },
      };
    }
    return newQueryObject;
  }

  render() {
    const { children } = this.props;
    return (
      <SearchkitProvider searchkit={this.searchkit}>
        <React.Fragment>
          <div style={{ display: 'none' }}>
            <MenuFilter id="label" field="label.raw" title="Label" />
            <ResetFilters />
            {/* There must be a sorting selector in the page to parse the query string sort parameter.
          This is hidden - another instance is rendered above the Recipe search results for the user to intract with.
          This is a workaround for the fact that the Recipes results are not yet rendered when the page loads. */}
            <SortingSelector options={SORT_OPTIONS} />
          </div>
          <SearchkitContext.Provider value={this.searchkit}>{children}</SearchkitContext.Provider>
        </React.Fragment>
      </SearchkitProvider>
    );
  }
}

const shouldShowAlaCarteContent = user => {
  if (typeof user.settings?.show_alacarte_content === 'boolean') {
    return user.settings?.show_alacarte_content;
  }

  if (typeof user.geolocation.corporate === 'boolean') {
    return !user.geolocation.corporate;
  }

  return true;
};

const mapStateToProps = state => ({
  connected: state.device.connected,
  includeTags: state.user.oven_brands?.map(brand => brand.toLowerCase()),
  ownedBooks: state.user.owned_books || [],
  router: state.router,
  search: state.search,
  searchEndpoint: `${state.config.apiUrl}/es-search`,
  showAlacarte: shouldShowAlaCarteContent(state.user),
  userCountryCode: state.user.geolocation.countryCode,
  userIsLoading: state.user.loading,
});

const mapDispatchToProps = {
  featureUsed,
  logAccess,
  searchIsEmpty,
  searchIsLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFeatureToggle(SearchProvider, 'alacarte'));

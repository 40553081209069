/* eslint-disable import/prefer-default-export */

import {
  AVATAR_MENU_OPEN,
  AVATAR_MENU_CLOSE,
  OVEN_MENU_OPEN,
  OVEN_MENU_CLOSE,
  MODAL_CLOSE,
} from '../constants';

export function openAvatarMenu() {
  return dispatch => {
    dispatch({
      type: AVATAR_MENU_OPEN,
    });
    dispatch({
      type: MODAL_CLOSE,
    });
  };
}

export function closeAvatarMenu() {
  return dispatch => {
    dispatch({
      type: AVATAR_MENU_CLOSE,
    });
  };
}

export function openOvenMenu() {
  return dispatch => {
    dispatch({
      type: OVEN_MENU_OPEN,
    });
    dispatch({
      type: MODAL_CLOSE,
    });
  };
}

export function closeOvenMenu() {
  return dispatch => {
    dispatch({
      type: OVEN_MENU_CLOSE,
    });
  };
}

// node modules
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import classNames from 'classnames';

// app modules: actions
import { openSidemenu, closeSidemenu } from 'actions/sidemenu';

// assets
import CssHamburger from './Hamburger.scss';
import CssHamburgerSqueeze from './Hamburger-squeeze.scss';

class Hamburger extends Component {
  static propTypes = {
    sidemenu: PropTypes.shape({
      isOpen: PropTypes.bool,
    }).isRequired,
    openSidemenu: PropTypes.func.isRequired,
    closeSidemenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.binded = {
      onClick: this.onClick.bind(this),
    };
  }

  onClick() {
    const { isOpen } = this.props.sidemenu;

    if (isOpen) {
      this.props.closeSidemenu();
    } else {
      this.props.openSidemenu();
    }
  }

  render() {
    const { isOpen } = this.props.sidemenu;
    const hamburgerClassName = classNames('hamburger', 'hamburger--squeeze', {
      'is-active': isOpen,
    });

    return (
      <button className={hamburgerClassName} onClick={this.binded.onClick} type="button">
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    );
  }
}

const mapStateToProps = state => ({
  sidemenu: state.sidemenu,
});

const mapDispatchToProps = {
  openSidemenu,
  closeSidemenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(CssHamburger, CssHamburgerSqueeze)(Hamburger));

import React from 'react';
import PropTypes from 'prop-types';

// app modules: services
// FIXME: We need to use relative paths here because this is imported from the tools which don't support 'services/history'
import history from '../../services/history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    referrer: PropTypes.string,
    section: PropTypes.string,
    children: PropTypes.node,
    onClick: PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    state: PropTypes.object,
    disabled: PropTypes.bool,
    replaceHistoryEntry: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    onClick: null,
    referrer: null,
    section: null,
    state: {},
    disabled: false,
    replaceHistoryEntry: false,
  };

  handleClick = event => {
    const { onClick, referrer, section, state, to, disabled, replaceHistoryEntry } = this.props;
    if (disabled) {
      return;
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (onClick) {
      if (onClick(event) === false) {
        return;
      }
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    const linkState = {
      ...state,
      referrer,
      section,
    };

    const goFn = replaceHistoryEntry ? history.replace : history.push;

    if (history.location.search && to.startsWith('#')) {
      // workaround https://github.com/ReactTraining/history/issues/443
      goFn(history.location.search + to, linkState);
    } else {
      goFn(to, linkState);
    }
  };

  render() {
    const { to, children, state, referrer, section, replaceHistoryEntry, ...props } = this.props;

    return (
      <a href={to} {...props} onClick={this.handleClick}>
        {children}
      </a>
    );
  }
}

export default Link;

/* eslint-disable import/prefer-default-export */

export const FILTERS = {
  AUTHORS: 'authors',
  BOOK_TITLE: 'book_title',
  BOOK_FULL_TITLE: 'book_full_title',
  COUNTRY: 'country',
  COURSE: 'course',
  DIET_TYPE: 'diet_type',
  ERA: 'era',
  SKILL: 'skill',
};

export const FIELDS = [
  'additional_keywords',
  'authors^15',
  'authors.synonym^15',
  'book_authors^12',
  'book_authors.synonym^12',
  'book_full_title^2',
  'book_full_title.synonym^2',
  'book_licensor',
  'book_licensor.synonym',
  'book_subtitle',
  'book_subtitle.synonym',
  'book_tags',
  'book_tags.synonym',
  'book_title^2',
  'book_title.synonym^2',
  'country^2',
  'country.synonym^2',
  'course',
  'course.synonym',
  'diet_type^2',
  'diet_type.synonym^2',
  'headings',
  'headings.synonym',
  'ingredients.exact',
  'ingredients',
  'ingredients.synonym',
  'name^10',
  'skill',
  'skill.synonym',
  'subtitle.exact',
  'subtitle^10',
  'subtitle.synonym^10',
  'title.exact',
  'title^30',
  'title.synonym^20',
];

export const OPERATOR = 'and';
export const MIN_SEARCH_LENGTH = 2;

export const SORT_OPTIONS = [
  {
    label: 'Relevance',
    field: '_score',
    key: 'relevance',
    order: 'desc',
    defaultOption: true,
  },
  {
    label: 'Popularity',
    key: 'popularity',
    fields: [
      { field: 'rank', options: { order: 'desc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Recently added',
    key: 'recentlyadded',
    fields: [
      { field: 'date_added', options: { order: 'desc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Publication (new)',
    key: 'publication_new',
    fields: [
      { field: 'date_published', options: { order: 'desc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Publication (old)',
    key: 'publication_old',
    fields: [
      { field: 'date_published', options: { order: 'asc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Highest rated',
    key: 'highest_rated',
    fields: [
      { field: 'star_rating_average', options: { order: 'desc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Most ratings',
    key: 'most_ratings',
    fields: [
      { field: 'star_rating_count', options: { order: 'desc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Most reviews',
    key: 'most_reviews',
    fields: [
      { field: 'review_count', options: { order: 'desc' } },
      { field: '_score', options: { order: 'desc' } },
    ],
  },
  {
    label: 'Book order',
    key: 'book',
    fields: [{ field: 'id', options: { order: 'asc' } }],
  },
];

export const INTERCOM_SEARCH_HELP_ARTICLE_ID = 2376673;

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".no-longer-available{display:block;font-family:\"Helvetica Neue\",\"Helvetica\",\"Arial\",sans-serif;margin:0 auto;text-align:center;width:65%}.no-longer-available__image{height:auto;max-width:100%}.no-longer-available__heading{font-size:45.712px;font-size:2.857rem;font-weight:700;line-height:1.2;text-align:left}.no-longer-available__paragraph{font-size:22.864px;font-size:1.429rem;text-align:left}@media (max-width: 639px){.no-longer-available{width:100%}.no-longer-available__image{max-width:50%}.no-longer-available__heading{font-size:1.786rem}}\n", ""]);

// exports
exports.locals = {
	"no-longer-available": "no-longer-available",
	"no-longer-available__image": "no-longer-available__image",
	"no-longer-available__heading": "no-longer-available__heading",
	"no-longer-available__paragraph": "no-longer-available__paragraph"
};
/* eslint-disable import/prefer-default-export */
export const MODALS = {
  COLLECTION_ADD_TO: 'COLLECTION_ADD_TO',
  COLLECTION_CONFIRM_DELETION: 'COLLECTION_CONFIRM_DELETION',
  COLLECTION_EDIT_UNSAVED_CHANGES: 'COLLECTION_EDIT_UNSAVED_CHANGES',
  COLLECTION_NEW: 'COLLECTION_NEW',
  COLLECTION_MANAGE_PRIVACY: 'COLLECTION_MANAGE_PRIVACY',
  IN_APP_PAYMENT: 'IN_APP_PAYMENT',
  NOTIFICATIONS: 'NOTIFICATIONS',
  NULL: 'NULL',
  RATE_US: 'RATE_US',
};

export const MIN_FONT_SCALE = 0.55;

export const MAX_FONT_SCALE = 1.45;

export const FONT_SCALE_STEP = 0.15;

export const FONT_SCALING_ARRAY = () =>
  Array.from({ length: 7 }, (_, i) => Number((MIN_FONT_SCALE + i * FONT_SCALE_STEP).toFixed(2)));

export const FORMS = {
  COLLECTION_EDIT: 'COLLECTION_EDIT',
  COLLECTION_NEW: 'COLLECTION_NEW',
  SETTINGS_HOMECONNECT: 'SETTINGS_HOMECONNECT',
  SETTINGS_MY_OVENS: 'SETTINGS_MY_OVENS',
};

import { useEffect, useState } from 'react';

const useRetainScrollPosition = location => {
  // We're trying to retain the scroll position a user get to before the page finishes loading.
  const [cachedPathname, setCachedPathname] = useState('');

  useEffect(
    () => {
      const { scrollY, scrollX } = window;
      // If the current page isn't the last page we remember and
      // there's actually some scrolling activities, let's not interfere
      if (cachedPathname === location.pathname && (scrollY !== 0 || scrollX !== 0)) {
        setCachedPathname(location.pathname);
        return;
      }

      // But if we do remember the page the pathname changed then let's reset the scroll.
      window.scrollTo(0, 0);

      setCachedPathname(location.pathname);
    },
    [location.pathname],
  );
};

export default useRetainScrollPosition;

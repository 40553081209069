/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-string-refs */

// node modules
import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import {
  SearchBox,
  SimpleQueryString,
  BoolShould,
  BoolMust,
  TermQuery,
  PaginationAccessor,
} from 'searchkit';
import throttle from 'lodash/throttle';
import foldToAscii from 'fold-to-ascii';

import { sendEvent } from 'services/analytics';
import formatter from 'services/formatter';

// services
import history from 'services/history';

// assets
import CssSearchBoxCustom from './SearchBoxCustom.scss';

class SearchBoxCustom extends SearchBox {
  static defaultProps = Object.assign({}, SearchBox.defaultProps, {
    queryBuilder: (query, options) => {
      // In order to boost exact matches add an additional search for a simplified version of the query
      // with all spaces, punctuation and negative terms removed.
      const fullTitleQuery = foldToAscii
        .fold(query)
        .toLowerCase()
        .replace(/[^\w\d]*-[\w\d]+/g, '') // Remove negative terms (like: -cheese)
        .replace(/[^\w\d]/g, ''); // Remove all punctuation

      const queryDsl = BoolShould([
        SimpleQueryString(`${query.trim()}`, options),
        // Exact matches should come in above all other results
        // boost weights will be added by the searchProxy
        {
          term: {
            full_title: {
              value: fullTitleQuery,
            },
          },
        },
        {
          term: {
            full_subtitle: {
              value: fullTitleQuery,
            },
          },
        },
      ]);

      if (query.match(/\brecipes?\b/)) {
        return BoolMust([queryDsl, TermQuery('label.raw', 'recipe')]);
      }

      return queryDsl;
    },
    onFocus: () => {},
    onBlur: () => {},
    onChange: () => {},
    progressClassName: 'sk-search-box__progress--light',
    disabled: false,
    autoFocus: true,
  });

  static dismissVirtualKeyboard() {
    if (
      document.activeElement &&
      document.activeElement.blur &&
      typeof document.activeElement.blur === 'function'
    ) {
      document.activeElement.blur();
    }
  }

  constructor(props) {
    super(props);
    this.state.zoomed = false;

    // override: do not update history on input change.
    this.throttledSearch = throttle(() => {
      this.searchQuery(this.accessor.getQueryString(), false);
    }, props.searchThrottleTime);

    this.bound = {
      onSubmit: this.onSubmit.bind(this),
      onChange: this.onChange.bind(this),
      onFocus: this.onFocus.bind(this),
      onBlur: this.onBlur.bind(this),
      onClick: this.onClick.bind(this),
      onIconClick: this.onIconClick.bind(this),
    };

    this.queryFieldRef = React.createRef();
  }

  onFocus(event) {
    const { onFocus } = this.props;
    this.setFocusState(true);
    onFocus(event);
  }

  onBlur(event) {
    const { onBlur } = this.props;
    this.setFocusState(false);
    onBlur(event);
    this.setState({ zoomed: false });
  }

  onSubmit(event) {
    event.preventDefault();
    this.searchQuery(this.getValue() || 'recipes');
    SearchBoxCustom.dismissVirtualKeyboard();
  }

  onChange(event) {
    const { onChange } = this.props;
    const newEvent = {
      target: {
        value: formatter.removeSmartCharacters(event.target.value),
      },
    };

    // Reset pagination when the query changes
    const paginationAccessor = this.searchkit.getAccessorByType(PaginationAccessor);
    if (paginationAccessor) {
      paginationAccessor.resetState();
    }

    super.onChange(newEvent);
    onChange(newEvent);
    this.setState({ zoomed: true });
  }

  onClick() {
    this.setState({ zoomed: true });
  }

  onIconClick() {
    history.push(`/search?q=${this.getValue()}`);
  }

  searchQuery(query, updateHistory = true) {
    const shouldResetOtherState = false;
    this.accessor.setQueryString(query, shouldResetOtherState);
    const now = +new Date();
    const newSearch = now - this.lastSearchMs <= 2000;
    this.lastSearchMs = now;
    this.searchkit.performSearch(newSearch, undefined, updateHistory);
    if (this.searchkit.accessors.statefulAccessors.label) {
      this.searchkit.accessors.statefulAccessors.label.state.value = [];
    }
    sendEvent({
      event: 'Search.Type',
      query,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    const { focussed } = this.props;
    const element = this.queryFieldRef.current;
    if (focussed && element) {
      element.focus();
      this.setState({ zoomed: true });
    }
    if (element) {
      const { value } = element;
      if (value) {
        this.onChange({ target: element });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.focussed && this.props.focussed) {
      const element = this.queryFieldRef.current;
      if (element) {
        this.setState({ zoomed: true });
        setTimeout(() => {
          if (this.queryFieldRef.current) {
            this.queryFieldRef.current.focus();

            // If we came in from the Home page Hero, its search box click handler will have created a dummy input
            // to help make sure iOS Safari displays the keyboard when focus occurs. We should remove that element
            // and hopefully the keyboard should remain open.
            const focusHelper = document.getElementById('search-box-focus-helper');
            if (focusHelper) {
              focusHelper.remove();
            }
          }
        }, 300);
      }
    }
  }

  render() {
    const { disabled, autoFocus } = this.props;
    const { focused, zoomed } = this.state;
    const block = this.bemBlocks.container;

    let placeholder = this.props.placeholder || this.translate('searchbox.placeholder');
    if (zoomed) {
      placeholder = '';
    }
    return (
      <div className={block().state({ focused })}>
        <form onSubmit={this.bound.onSubmit} action=".">
          <div
            className={`search__icon ${block('icon')}`}
            onClick={this.bound.onIconClick}
            onKeyPress={this.bound.onIconClick}
            role="button"
            tabIndex="0"
            aria-label="Search"
          >
            <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
          <div className={block('wrapper')}>
            <input
              type="search"
              data-qa="query"
              className={`${this.props.className} ${block('text')}`}
              placeholder={placeholder}
              value={formatter.removeSmartCharacters(this.accessor ? this.getValue() : '')}
              onFocus={this.bound.onFocus}
              onBlur={this.bound.onBlur}
              onClick={this.bound.onClick}
              ref={this.queryFieldRef}
              autoFocus={autoFocus}
              autoCapitalize="off"
              onChange={this.bound.onChange}
              disabled={disabled}
              spellCheck={false}
              autoComplete="off"
              autoCorrect="off"
              data-cy="searchbox"
            />
            <input
              type="submit"
              value={this.translate('searchbox.button')}
              className={block('action')}
              data-qa="submit"
            />
            <div className={`${this.props.progressClassName} ${block('progress')}`}>
              <div
                data-qa="loader"
                className={block('loader')
                  .mix('sk-spinning-loader')
                  .state({ hidden: !this.isLoading() })}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(CssSearchBoxCustom)(SearchBoxCustom);

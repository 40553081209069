/* eslint-disable jsx-a11y/anchor-has-content */
// node modules
import React, { PureComponent } from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';

// app modules: components
import Advert from 'components/Advert';
import Link from 'components/Link';

// assets
import CssFooter from './Footer.scss';

class Footer extends PureComponent {
  static propTypes = {
    withLeaderboardAd: PropTypes.bool,
  };

  static defaultProps = {
    withLeaderboardAd: true,
  };
  render() {
    const { withLeaderboardAd } = this.props;
    return (
      <React.Fragment>
        {withLeaderboardAd ? (
          <Advert
            className="margin-bottom-40 margin-top-10"
            slotName="leaderboard-footer"
            format="leaderboard"
            fullWidthResponsive={false}
            ezoicPlaceholderId={102}
          />
        ) : null}
        <footer className="footer">
          <Link to="/" className="footer__logo">
            <img
              src="images/logo.png"
              className="footer__logo__image"
              alt="Logo"
              height="30"
              width="91"
              loading="lazy"
            />
          </Link>

          <nav className="footer__navigation">
            <ul className="footer__menu">
              <li className="footer__menu__item">
                <a
                  href="https://www.ckbk.com/about/"
                  className="footer__menu__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  About
                </a>
              </li>
              <li className="footer__menu__item">
                <a
                  href="https://intercom.help/ckbk/faq"
                  className="footer__menu__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  faq
                </a>
              </li>
              <li className="footer__menu__item">
                <a
                  href="https://www.ckbk.com/contact/"
                  className="footer__menu__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact
                </a>
              </li>
              <li className="footer__menu__item">
                <a
                  href="https://www.ckbk.com/terms/"
                  className="footer__menu__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms
                </a>
              </li>
              <li className="footer__menu__item">
                <a
                  href="https://www.ckbk.com/privacy/"
                  className="footer__menu__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy
                </a>
              </li>
              <li className="footer__menu__item">
                <a
                  href="https://join.ckbk.com/gift/"
                  className="footer__menu__link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Gifts
                </a>
              </li>
            </ul>
          </nav>
        </footer>
      </React.Fragment>
    );
  }
}

export default withStyles(CssFooter)(Footer);

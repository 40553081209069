import {
  SEARCH_IS_EMPTY,
  SEARCH_IS_ACTIVE,
  SEARCH_IS_LOADING,
  SEARCH_EXAMPLES_GET_START,
  SEARCH_EXAMPLES_GET_SUCCESS,
  SEARCH_EXAMPLES_GET_ERROR,
  SEARCH_VIEW_REQUIRED,
} from '../constants';

export default function searchReducer(state = {}, action) {
  switch (action.type) {
    case SEARCH_IS_EMPTY:
      return Object.assign({}, state, {
        isEmpty: action.payload.isEmpty,
      });

    case SEARCH_IS_ACTIVE:
      return Object.assign({}, state, {
        isActive: action.payload.isActive,
      });

    case SEARCH_IS_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload.isLoading,
      });

    case SEARCH_VIEW_REQUIRED:
      return Object.assign({}, state, {
        searchViewRequired: true,
      });

    case SEARCH_EXAMPLES_GET_START:
      return Object.assign({}, state, {
        examples: {
          state: 'loading',
          fetched_at: null,
          items: [],
        },
      });

    case SEARCH_EXAMPLES_GET_SUCCESS:
      return Object.assign({}, state, {
        examples: {
          state: 'loaded',
          fetched_at: new Date().getTime(),
          items: action.payload.examples,
        },
      });

    case SEARCH_EXAMPLES_GET_ERROR:
      return Object.assign({}, state, {
        examples: {
          state: 'error',
          fetched_at: null,
          items: [],
        },
      });

    default:
      return state;
  }
}

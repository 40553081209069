/* eslint-disable jsx-a11y/anchor-is-valid */
// node modules
import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Tooltip from 'rc-tooltip';
import NoSsr from '@material-ui/core/NoSsr';

// app modules: actions
import { closeSidemenu } from 'actions/sidemenu';
import { openAvatarMenu, closeAvatarMenu } from 'actions/avatarMenu';

// app modules: components
import LayoutUserMenu from 'components/LayoutUserMenu';
import Link from 'components/Link';
import Icon from 'components/Icon';
import Image from 'components/Image';

// app modules: constants
import { COLOURS } from 'constants/index';

// app modules: props
import LoginProps from 'components/Login/LoginProps';

// app modules: services
import assets from 'services/assets';
import history from 'services/history';

// assets
import CssSidebar from './Sidebar.scss';

class Sidebar extends Component {
  static propTypes = {
    user: LoginProps.isRequired,
    router: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.string,
    }).isRequired,
    closeSidemenu: PropTypes.func.isRequired,
    avatarMenuVisible: PropTypes.bool.isRequired,
    sideMenuVisible: PropTypes.bool.isRequired,
    openAvatarMenu: PropTypes.func.isRequired,
    closeAvatarMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.bound = {
      handleAvatarMenuClick: this.handleAvatarMenuClick.bind(this),
    };
  }

  linkClassName(pathname) {
    const { router } = this.props;

    return classNames('sidebar__navigation__text', {
      'sidebar__navigation__text--active': router.pathname.indexOf(pathname) === 0,
    });
  }

  handleAvatarMenuClick() {
    const { avatarMenuVisible } = this.props;
    if (avatarMenuVisible) {
      this.props.closeAvatarMenu();
    } else {
      this.props.openAvatarMenu();
    }
  }

  handleLoginClick = () => {
    this.props.closeSidemenu();
    history.push('/login', { returnTo: history.location });
  };

  showIntercom = () => {
    if (window.Intercom) {
      window.Intercom('show');
    }
    this.props.closeSidemenu();
  };

  renderUser() {
    const { user, avatarMenuVisible, sideMenuVisible } = this.props;

    if (user.logged_in) {
      return (
        <Tooltip
          visible={avatarMenuVisible && sideMenuVisible}
          overlay={<LayoutUserMenu />}
          mouseEnterDelay={0.75}
          placement="rightBottom"
          overlayClassName="rc-tooltip--light"
        >
          <button className="sidebar__user" onClick={this.bound.handleAvatarMenuClick}>
            <Image
              image={assets.avatarUrl('/login/avatar.png', {
                resize: 'fit-in',
                width: 80,
                height: 80,
              })}
              className="sidebar__user__avatar"
              width="45"
              height="45"
              alt="Avatar"
              shape="circle"
            />
          </button>
        </Tooltip>
      );
    }

    return (
      <nav className="sidebar__login">
        <button className="sidebar__navigation__link" onClick={this.handleLoginClick}>
          Login
        </button>
      </nav>
    );
  }

  render() {
    const linksClassName = {
      search: this.linkClassName('/search'),
      authors: this.linkClassName('/authors'),
      books: this.linkClassName('/books'),
      collections: this.linkClassName('/collection'),
      features: this.linkClassName('/features'),
    };

    return (
      <div className="sidebar">
        <Link to="/" onClick={this.props.closeSidemenu}>
          <img src="images/logo.png" className="sidebar__logo" alt="Logo" />
        </Link>

        <nav className="sidebar__navigation">
          <ul>
            <li className="sidebar__navigation__item">
              <Link
                to="/search"
                className="sidebar__navigation__link"
                onClick={this.props.closeSidemenu}
              >
                <span className={linksClassName.search}>Search</span>
              </Link>
            </li>
            <li className="sidebar__navigation__item">
              <Link
                to="/authors"
                className="sidebar__navigation__link"
                onClick={this.props.closeSidemenu}
              >
                <span className={linksClassName.authors}>Authors</span>
              </Link>
            </li>
            <li className="sidebar__navigation__item">
              <Link
                to="/books"
                className="sidebar__navigation__link"
                onClick={this.props.closeSidemenu}
              >
                <span className={linksClassName.books}>Books</span>
              </Link>
            </li>

            <li className="sidebar__navigation__item">
              <Link
                to="/features"
                className="sidebar__navigation__link"
                onClick={this.props.closeSidemenu}
              >
                <span className={linksClassName.features}>Features &amp; Stories</span>
              </Link>
            </li>

            <li className="sidebar__navigation__item">
              <Link
                to="/collections"
                className="sidebar__navigation__link"
                onClick={this.props.closeSidemenu}
              >
                <span className={linksClassName.collections}>Collections</span>
              </Link>
            </li>
          </ul>
        </nav>
        <nav className="sidebar__navigation sidebar__navigation--bottom">
          <ul>
            <li className="sidebar__navigation__item">
              <button className="sidebar__navigation__link" onClick={this.showIntercom}>
                <span className="sidebar__navigation__text">Help and Support</span>{' '}
                <Icon
                  fill="currentColor"
                  name="help"
                  height="22"
                  width="22"
                  style={{ verticalAlign: 'middle' }}
                />
              </button>
            </li>
          </ul>
        </nav>
        {this.renderUser()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  router: state.router,
  avatarMenuVisible: state.avatarMenu.isOpen,
  sideMenuVisible: state.sidemenu.isOpen,
});

const mapDispatchToProps = {
  closeSidemenu,
  openAvatarMenu,
  closeAvatarMenu,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(CssSidebar)(Sidebar));

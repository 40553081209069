/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useScript from 'react-script-hook';

// https://trello.com/c/etuc9Rjs/
// https://support.ezoic.com/kb/article/how-to-wrap-your-ads
function EzoicPlaceholder(props) {
  const { id, children, lazy, className, enabled } = props;
  const shouldShowAds = useSelector(
    state => !state.user.subscription?.is_valid && state.adverts.visible,
  );

  useScript({
    src: shouldShowAds ? `https://www.ezojs.com/ezoic/sa.min.js` : null,
    checkForExisting: true,
  });

  useEffect(
    () => {
      if (shouldShowAds && window.ezstandalone) {
        window.ezstandalone.cmd.push(() => {
          const ezoic = window.ezstandalone;
          ezoic.DEBUG = false;
          if (enabled) {
            if (lazy) {
              ezoic.displayMore(id);
            } else {
              ezoic.define(Array.from(new Set([id, ...ezoic.placeholders])));
            }
          }
        });
        return () => {
          if (window.ezstandalone.enabled) {
            window.ezstandalone.destroyPlaceholders(id);
          }
        };
      }
      return undefined;
    },
    [id, shouldShowAds, lazy, enabled],
  );

  if (id) {
    return (
      <div id={`ezoic-pub-ad-placeholder-${id}`} className={className}>
        {children}
      </div>
    );
  }
  return <div className={className}>{children}</div>;
}

EzoicPlaceholder.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  lazy: PropTypes.bool,
  enabled: PropTypes.bool,
};

EzoicPlaceholder.defaultProps = {
  className: null,
  children: null,
  lazy: false,
  enabled: false,
};

export default React.memo(EzoicPlaceholder);

/* eslint-disable import/prefer-default-export */
import { sendPaddleEvent } from 'services/analytics';
import {
  PAYWALL_CONFIGURE,
  CHECKOUT_EVENT,
  APPLY_COUPON,
  APPLY_PRODUCT_START,
  APPLY_PRODUCT_SUCCESS,
  LOAD_PRODUCT_PRICES_START,
  LOAD_PRODUCT_PRICES_SUCCESS,
} from '../constants';

export function configurePaywall(payload) {
  return dispatch => {
    dispatch({
      type: PAYWALL_CONFIGURE,
      payload,
    });
  };
}

export function checkoutEvent(event) {
  sendPaddleEvent(event);
  return dispatch => {
    dispatch({
      type: CHECKOUT_EVENT,
      payload: event,
    });
  };
}

export function applyCoupon(couponCode) {
  return dispatch => {
    dispatch({
      type: APPLY_COUPON,
      payload: couponCode,
    });
  };
}

export function applyProduct(productId) {
  return async dispatch => {
    dispatch({
      type: APPLY_PRODUCT_START,
      payload: { productId },
    });
    if (typeof window !== 'undefined' && window.Paddle) {
      window.Paddle.Product.Prices(productId, prices => {
        dispatch({
          type: APPLY_PRODUCT_SUCCESS,
          payload: { productId, prices },
        });
      });
    }
  };
}

export function loadProductPrices(productId) {
  return async dispatch => {
    dispatch({
      type: LOAD_PRODUCT_PRICES_START,
      payload: { productId },
    });
    if (window && window.Paddle) {
      window.Paddle.Product.Prices(productId, prices => {
        dispatch({
          type: LOAD_PRODUCT_PRICES_SUCCESS,
          payload: { productId, prices },
        });
      });
    }
  };
}

/* eslint-disable camelcase */
import Cookies from 'js-cookie';
import qs from 'qs';
import { toast } from 'react-toastify';
import React from 'react';

// app modules: actions
import { applyCoupon, applyProduct, configurePaywall } from 'actions/paywall';
import { authError } from 'actions/auth/error';
import claimVoucher, { restoreVoucherCheckout } from 'actions/user/claimVoucher';
import { enableFeature } from 'actions/featureUsage';

// app: components
import ToastContents from 'components/ToastContents';

// app modules: services
import { voucherClaim } from './route';
import history from './history';

const COOKIE_NAME = 'ckbk_session';
const PENDING_COUPON_CODE_KEY = 'ckbk_pending_coupon';
const PENDING_COUPON_CODE_EXPIRY = 24 * 60 * 60 * 1000; // 1 day
const ARRIVAL_QUERY_STRING_KEY = 'ckbk_arrival_query_string';

/* 
  A session begins when the user loads the page. 
  We take signals from the url as to their intention (e.g, #join), and store parameters 
  that require deferred action. 
  
  An example is voucher codes, which require a user to go through the login flow before 
  they can be actioned. 
  
  The login/signup flow requires a page reload (due to redirection via auth0) - when we 
  detect that page load (via the preserveSession hint in the query string) we will restore 
  the existing session from the Cookie rather than start afresh.
*/
class SessionManager {
  constructor(store) {
    this.store = store;
    this.imageBaseUrl = store.getState().config.cdn.static.baseUrl || 'https://staticdemo.ckbk.com';
    this.session = JSON.parse(Cookies.get(COOKIE_NAME) || null);
    // Is this a continuation of the session. e.g, a return from auth
    this.isContinuation = false;
  }

  initiate() {
    this.query = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    if (!this.session || !this.query.preserveSession) {
      this._createSession();
      // Store in a session cookie so that we survive page reloads
      Cookies.set(COOKIE_NAME, JSON.stringify(this.session), { SameSite: 'Lax' });
    }
    this._restoreSessionState();

    let search = null;
    if (history.location.search) {
      const {
        preserveSession,
        code,
        features,
        error_description,
        msg,
        voucher,
        ...rest
      } = this.query;
      search = qs.stringify(rest);
      history.replace({
        search: `?${qs.stringify({ voucher, ...rest })}`,
        hash: history.location.hash,
        state: history.location.state,
      });
    }
    this._configureArrivalQueryString(search);
  }

  reset() {
    this.session = null;
    this.query = null;
    Cookies.remove(COOKIE_NAME);
  }

  handleUserLoaded() {
    this._handleVoucherCode(this.session.entryPoint.query);
  }

  handleLocationChange() {
    const { router } = this.store.getState();
    if (router.queryParams) {
      const { voucher, partner, pr, error_description, code } = router.queryParams;
      if (voucher) {
        this._handleVoucherCode({ voucher, partner });
      }
      if (pr || error_description) {
        this._configurePaywall({ pr, error_description });
      }
      if (code) {
        this._configureCheckoutCoupon({ code });
      }
    }
  }

  _createSession() {
    const { pathname, hash } = history.location;
    this.session = {
      entryPoint: {
        pathname,
        query: this.query,
        hash,
      },
    };
  }

  _restoreSessionState() {
    // Call the appropriate redux actions to update state.
    const { query } = this.session.entryPoint;
    const { code, features } = query;

    // Apply coupon code
    let pendingCouponCode;
    try {
      pendingCouponCode = JSON.parse(localStorage.getItem(PENDING_COUPON_CODE_KEY));
    } catch (err) {
      console.error('Could not restore coupon code from localStorage', err);
    }
    this._configureCheckoutCoupon({ code, pendingCouponCode });

    // Enable feature toggles
    if (features) {
      this.store.dispatch(enableFeature(features.split(',')));
    }

    if (query.msg === 'am') {
      toast.success(<ToastContents>You are already a ckbk Premium Member</ToastContents>, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }

    this._configurePaywall(this.session.entryPoint.query);
  }

  _configureArrivalQueryString(queryString) {
    let arrivalQueryString = null;
    if (this.query.preserveSession) {
      // Returning from auth... pull the original querystring from localstorage
      try {
        arrivalQueryString = localStorage.getItem(ARRIVAL_QUERY_STRING_KEY);
      } catch (err) {
        console.error('Could not retrieve the arrival query string from localstorage', err);
      }
    } else if (queryString) {
      // Store the original querystring because it can contain tracking codes that need passing to the Paddle checkout
      try {
        arrivalQueryString = queryString?.replace(/^\?/, '');
        if (arrivalQueryString) {
          localStorage.setItem(ARRIVAL_QUERY_STRING_KEY, arrivalQueryString);
        } else {
          localStorage.removeItem(ARRIVAL_QUERY_STRING_KEY);
        }
      } catch (err) {
        console.error('Could not save the arrival query string', err);
      }
    }
    this.store.dispatch(configurePaywall({ arrivalQueryString }));
  }

  _configureCheckoutCoupon({ code, pendingCouponCode }) {
    let hasExpired = false;
    if (pendingCouponCode) {
      hasExpired = Date.now() - pendingCouponCode.stored_at > PENDING_COUPON_CODE_EXPIRY;
      if (hasExpired) {
        try {
          localStorage.removeItem(PENDING_COUPON_CODE_KEY);
        } catch (err) {
          // Ignore
        }
      }
    }
    if (code) {
      console.info('Using Coupon Code from query string', code);
      this.store.dispatch(applyCoupon(code));
      try {
        localStorage.setItem(
          PENDING_COUPON_CODE_KEY,
          JSON.stringify({ code, stored_at: Date.now() }),
        );
      } catch (err) {
        console.error('Could not save the voucher checkout config', err);
      }
    } else if (pendingCouponCode && !hasExpired) {
      console.info('Using Coupon Code from local storage', pendingCouponCode);
      this.store.dispatch(applyCoupon(pendingCouponCode.code));
    }
  }

  _configurePaywall({ pr, error_description }) {
    const productId = parseInt(pr, 10) || null;
    if (productId) {
      this.store.dispatch(applyProduct(productId));
    }

    if (error_description) {
      this.store.dispatch(authError(error_description));
    }
  }

  _handleVoucherCode({ voucher, partner }) {
    if (!/^(?:\/w)?\/claim/.test(history.location.pathname)) {
      if (voucher) {
        const { user } = this.store.getState();
        if (user.logged_in) {
          this.store.dispatch(claimVoucher(voucher));
        } else {
          const brandingConfig = {};
          if (partner) {
            const message = 'Register or sign in to claim your free access to ckbk';
            brandingConfig.loginHeading = '';
            brandingConfig.signupHeading = '';
            brandingConfig.loginMessage = message;
            brandingConfig.signupMessage = message;
            brandingConfig.image = 'splash16_9.jpg';
            brandingConfig.logo = 'logo.png';
            brandingConfig.logoAlt = `${partner} logo`;
            brandingConfig.partner = partner;
            brandingConfig.backdrop = `backdrop.jpg`;
          } else {
            const message = 'Register or sign in to claim your complimentary Premium Membership';
            brandingConfig.loginMessage = message;
            brandingConfig.signupMessage = message;
          }
          // Tell the user that they need to register to use the voucher.
          // That is except if they are on certain screens that already contain this messaging.
          let voucherClaimToastId = null;
          if (
            !/^(?:\/w)?\/(?:recipe|reference|section|login|signup|claim|subscribe)/.test(
              history.location.pathname,
            )
          ) {
            const handleToastClick = () => {
              toast.dismiss(voucherClaimToastId);
              history.push(voucherClaim(voucher, partner));
            };
            voucherClaimToastId = toast.success(
              <ToastContents>
                <button onClick={handleToastClick} className="link link--energized">
                  Register
                </button>{' '}
                to claim your complimentary access to ckbk
              </ToastContents>,
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
                closeOnClick: false,
                hideProgressBar: true,
                onClick: handleToastClick,
              },
            );
          }
          this.store.dispatch(
            configurePaywall({
              voucherClaimPending: true,
              voucherClaimToastId,
              pendingVoucherCode: voucher,
              ...brandingConfig,
            }),
          );
        }
      } else {
        this.store.dispatch(restoreVoucherCheckout());
      }
      delete this.query.voucher;
      delete this.query.partner;
    }
  }

  _checkSession() {
    if (!this.session) {
      throw new Error('You must initiate() the session before accessing it');
    }
  }

  get voucher() {
    this._checkSession();
    return this.session.entryPoint.query.voucher;
  }

  get salesPartner() {
    this._checkSession();
    return this.session.entryPoint.query.partner;
  }

  get checkoutProduct() {
    this._checkSession();
    return parseInt(this.session.entryPoint.query.pr, 10);
  }

  get checkoutCoupon() {
    this._checkSession();
    return this.session.entryPoint.query.code;
  }
}

export default SessionManager;

/* eslint-disable import/prefer-default-export */
import { ROUTE_TRANSITION_START, ROUTE_TRANSITION_END, CONFIGURE_NAVIGATION } from '../constants';

export function routeTransitionStart(payload) {
  return dispatch => {
    dispatch({
      type: ROUTE_TRANSITION_START,
      payload,
    });
  };
}

export function routeTransitionEnd(payload) {
  return dispatch => {
    dispatch({
      type: ROUTE_TRANSITION_END,
      payload,
    });
  };
}

export function configureNavigation(payload) {
  return dispatch => {
    dispatch({
      type: CONFIGURE_NAVIGATION,
      payload,
    });
  };
}

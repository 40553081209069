import { FEATURE_USED, FEATURE_ENABLED } from '../constants';

export default function featureUsageReducer(state = {}, action) {
  switch (action.type) {
    case FEATURE_USED:
      return { ...state, usage: { ...state.usage, [action.payload.featureName]: true } };
    case FEATURE_ENABLED: {
      const newToggles = {};
      action.payload.features.forEach(feature => {
        newToggles[feature] = true;
      });
      const toggles = { ...state.toggles, ...newToggles };
      return { ...state, toggles };
    }
    default:
      return state;
  }
}

import { ENTITIES_LIST_SET_SORTING_OPTIONS } from '../constants';

export default function entitiesListReducer(state = {}, action) {
  const data = {};

  switch (action.type) {
    case ENTITIES_LIST_SET_SORTING_OPTIONS:
      data[action.payload.view] = action.payload.options;
      return Object.assign({}, state, data);

    default:
      return state;
  }
}

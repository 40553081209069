/* eslint-disable no-unreachable */
import { useMemo, useState, useEffect, useCallback, useRef } from 'react';

// Gets value from localstorage
function getValueFromLocalStorage(key) {
  if (typeof localStorage === 'undefined') {
    return null;
  }

  const storedValue = localStorage.getItem(key) || 'null';
  // eslint-disable-next-line no-restricted-globals
  if (!isNaN(storedValue)) {
    return Number(storedValue);
  }
  try {
    return JSON.parse(storedValue);
  } catch (error) {
    console.error(error);
  }

  return storedValue;
}

// Saves value to localstorage
function saveValueToLocalStorage(key, value) {
  if (typeof localStorage !== 'undefined') {
    // Only persist substantial values
    if (value && (value.length || Object.keys(value).length || typeof value === 'number')) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}

/**
 * @param key Key of the localStorage object
 * @param initialState Default initial value
 */
function initialize(key, initialState) {
  const valueLoadedFromLocalStorage = getValueFromLocalStorage(key);
  if (valueLoadedFromLocalStorage === null) {
    return initialState;
  }
  return valueLoadedFromLocalStorage;
}

/**
 * useLocalstorageState hook
 * Tracks a value within localStorage and updates it
 *
 * @param {string} key - Key of the localStorage object
 * @param {any} initialState - Default initial value
 * @see https://react-hooks.org/docs/useLocalstorageState
 */
function useLocalstorageState(key, initialState) {
  const [value, setValue] = useState(() => initialize(key, initialState));
  const [error, setError] = useState(null);
  const isUpdateFromCrossDocumentListener = useRef(false);
  const isUpdateFromWithinDocumentListener = useRef(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customEventTypeName = useMemo(() => `rooks-${key}-localstorage-update`, [key]);

  useEffect(
    () => {
      /**
       * We need to ensure there is no loop of
       * storage events fired. Hence we are using a ref
       * to keep track of whether setValue is from another
       * storage event
       */
      if (
        !isUpdateFromCrossDocumentListener.current ||
        !isUpdateFromWithinDocumentListener.current
      ) {
        try {
          saveValueToLocalStorage(key, value);
          setError(null);
        } catch (err) {
          setError(err);
        }
      }
    },
    [key, value],
  );

  const listenToCrossDocumentStorageEvents = useCallback(
    event => {
      if (event.storageArea === localStorage && event.key === key) {
        try {
          isUpdateFromCrossDocumentListener.current = true;
          const newValue = JSON.parse(event.newValue || 'null');
          if (value !== newValue) {
            setValue(newValue);
          }
        } catch (err) {
          console.warn(err);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, value],
  );

  // check for changes across documents
  useEffect(
    () => {
      // eslint-disable-next-line no-negated-condition
      if (typeof window !== 'undefined') {
        window.addEventListener('storage', listenToCrossDocumentStorageEvents);

        return () => {
          window.removeEventListener('storage', listenToCrossDocumentStorageEvents);
        };
      }
      console.warn('useLocalstorageState: window is undefined.');

      return () => {};
    },
    [listenToCrossDocumentStorageEvents],
  );

  const listenToCustomEventWithinDocument = useCallback(
    event => {
      try {
        isUpdateFromWithinDocumentListener.current = true;
        const { newValue } = event.detail;
        if (value !== newValue) {
          setValue(newValue);
        }
      } catch (err) {
        console.warn(err);
      }
    },
    [value],
  );

  // check for changes within document
  useEffect(
    () => {
      // eslint-disable-next-line no-negated-condition
      if (typeof document !== 'undefined') {
        document.addEventListener(customEventTypeName, listenToCustomEventWithinDocument);

        return () => {
          document.removeEventListener(customEventTypeName, listenToCustomEventWithinDocument);
        };
      }
      console.warn('[useLocalstorageState] document is undefined.');

      return () => {};
    },
    [customEventTypeName, listenToCustomEventWithinDocument],
  );

  const broadcastValueWithinDocument = useCallback(
    newValue => {
      // eslint-disable-next-line no-negated-condition
      if (typeof document !== 'undefined') {
        const event = new CustomEvent(customEventTypeName, { detail: { newValue } });
        document.dispatchEvent(event);
      } else {
        console.warn('[useLocalstorageState] document is undefined.');
      }
    },
    [customEventTypeName],
  );

  const set = useCallback(
    newValue => {
      isUpdateFromCrossDocumentListener.current = false;
      isUpdateFromWithinDocumentListener.current = false;
      setValue(newValue);
      broadcastValueWithinDocument(newValue);
    },
    [broadcastValueWithinDocument],
  );

  const remove = useCallback(
    () => {
      localStorage.removeItem(key);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  );

  return [value, set, remove, error];
}

export default useLocalstorageState;

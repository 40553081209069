import { decamelize } from 'humps';
import capitalize from 'lodash/capitalize';
import {
  GET_APPLIANCES_START,
  GET_APPLIANCES_SUCCESS,
  GET_APPLIANCES_ERROR,
  GET_APPLIANCE_STATUS_START,
  GET_APPLIANCE_STATUS_ERROR,
  GET_APPLIANCE_STATUS_SUCCESS,
  GET_APPLIANCE_PROGRAMS_START,
  GET_APPLIANCE_PROGRAMS_ERROR,
  GET_APPLIANCE_PROGRAMS_SUCCESS,
  APPLIANCE_ADJUSTMENT_START,
  APPLIANCE_ADJUSTMENT_SUCCESS,
  APPLIANCE_ADJUSTMENT_ERROR,
  STOP_APPLIANCE_START,
  STOP_APPLIANCE_SUCCESS,
  STOP_APPLIANCE_ERROR,
  HOME_CONNECT_DISCONNECT,
  HOME_CONNECT_EVENT_CHANNEL_OPEN,
  HOME_CONNECT_NOTIFICATION,
  HOME_CONNECT_EVENT_CHANNEL_CONNECTING,
  HOME_CONNECT_EVENT_CHANNEL_ERROR,
  HOME_CONNECT_EVENT_CHANNEL_CLOSE,
  HOME_CONNECT_SET_RECIPE,
  HOME_CONNECT_CLEAR_RECIPE,
  HOME_CONNECT_NEXT_STEP,
  HOME_CONNECT_SET_CURRENT_STEP,
  HOME_CONNECT_RESET,
  SET_PRIMARY_OVEN,
  HOME_CONNECT_PROMO_CLAIM_START,
  HOME_CONNECT_PROMO_CLAIM_ERROR,
  HOME_CONNECT_PROMO_CLAIM_ALLOWED,
  HOME_CONNECT_PROMO_CLAIM_DENIED,
} from '../constants';

function updateAppliances(state, haId, update) {
  const appliance = {
    ...state.appliances[haId],
    ...update,
  };
  // Was the update to the primary oven? If so update it to point at the new appliance object.
  const primaryOven =
    state.primaryOven && state.primaryOven.haId === haId ? appliance : state.primaryOven;
  return {
    appliances: {
      ...state.appliances,
      [haId]: appliance,
    },
    primaryOven,
  };
}

function getProgramName(programKey, availablePrograms) {
  if (!programKey) {
    return null;
  }

  if (availablePrograms) {
    // Sometimes HomeConnect can tell us the name of the program
    const currentProgram = availablePrograms.find(program => program.key === programKey);
    if (currentProgram && currentProgram.name) {
      return currentProgram.name;
    }
  }

  // Last resort just use the last part of the program key
  return capitalize(decamelize(programKey.split('.').splice(-1)[0], { separator: ' ' }));
}

function getProgramIcon(programKey) {
  switch (programKey) {
    case 'Cooking.Oven.Program.HeatingMode.PreHeating':
      return 'rapidheat.png';

    case 'Cooking.Oven.Program.HeatingMode.PizzaSetting':
    case 'Cooking.Oven.Program.HeatingMode.IntensiveHeat':
      return 'pizza.png';

    case 'Cooking.Oven.Program.HeatingMode.BreadBaking':
      return 'breadbaking.png';

    case 'Cooking.Oven.Program.HeatingMode.Proof':
    case 'Cooking.Oven.Program.SteamModes.DoughProving':
      return 'proof.png';

    case 'Cooking.Oven.Program.HeatingMode.TopBottomHeating':
      return 'topandbottom.png';

    case 'Cooking.Oven.Program.HeatingMode.BottomHeating':
      return 'bottomheat.png';

    case 'Cooking.Oven.Program.HeatingMode.TopHeating':
      return 'topheat.png';

    case 'Cooking.Oven.Program.HeatingMode.SlowCook':
      return 'slowcook.png';

    case 'Cooking.Oven.Program.HeatingMode.HotAir':
      return 'circotherm.png';

    case 'Cooking.Oven.Program.SteamModes.SousVide':
      return 'sousvide.png';

    case 'Cooking.Oven.Program.SteamModes.Steam':
    case 'Cooking.Oven.Program.HeatingMode.SteamWithSteamSet':
      return 'steamassist.png';

    case 'Cooking.Oven.Program.HeatingMode.HotAirGrilling':
      return 'circoroast.png';

    case 'Cooking.Oven.Program.HeatingMode.GrillSmallArea':
    case 'Cooking.Oven.Program.HeatingMode.GrillLargeArea':
    case 'Cooking.Oven.Program.HeatingMode.Grill':
      return 'grilllarge.png';

    default:
      return null;
  }
}

function updateAppliance(options, oldStatus = []) {
  const result = {};

  // operationState
  const operationState = options['BSH.Common.Status.OperationState'];
  if (operationState) {
    result.isReady = operationState.value === 'BSH.Common.EnumType.OperationState.Ready';
    result.operationState = operationState.value.split('.').splice(-1)[0];
    const remoteStartAllowed = oldStatus['BSH.Common.Status.RemoteControlStartAllowed']
      ? oldStatus['BSH.Common.Status.RemoteControlStartAllowed'].value
      : false;
    result.awaitingManualStart =
      operationState.value === 'BSH.Common.EnumType.OperationState.Ready' && !remoteStartAllowed;
  }
  // remoteControlActive
  const remoteControlActive = options['BSH.Common.Status.RemoteControlActive'];
  if (remoteControlActive) {
    result.remoteControlActive = remoteControlActive.value;
  }
  // remoteStartAllowed
  const remoteStartAllowed = options['BSH.Common.Status.RemoteControlStartAllowed'];
  if (remoteStartAllowed) {
    result.remoteStartAllowed = remoteStartAllowed.value;
    if (remoteStartAllowed.value) {
      result.awaitingManualStart = false;
    }
  }
  // localControlActive
  const localControlActive = options['BSH.Common.Status.LocalControlActive'];
  if (localControlActive) {
    result.localControlActive = localControlActive.value;
  }
  // currentTemperature
  const currentTemperature = options['Cooking.Oven.Status.CurrentCavityTemperature'];
  if (currentTemperature && currentTemperature.value) {
    result.currentTemperature = Math.round(currentTemperature.value);
  }
  if (currentTemperature && currentTemperature.unit) {
    result.currentTemperatureUnit = currentTemperature.unit;
  }
  // setPointTemperature
  const setPointTemperature = options['Cooking.Oven.Option.SetpointTemperature'];
  if (setPointTemperature && setPointTemperature.value) {
    result.setPointTemperature = Math.round(setPointTemperature.value);
  }
  if (setPointTemperature && setPointTemperature.unit) {
    result.setPointTemperatureUnit = setPointTemperature.unit;
  }
  // doorOpen
  const doorState = options['BSH.Common.Status.DoorState'];
  if (doorState) {
    result.doorOpen = doorState.value === 'BSH.Common.EnumType.DoorState.Open';
  }
  // programProgress
  const programProgress = options['BSH.Common.Option.ProgramProgress'];
  if (programProgress) {
    result.programProgress = programProgress.value;
  }
  // elapsedProgramTime
  const elapsedProgramTime = options['BSH.Common.Option.ElapsedProgramTime'];
  if (elapsedProgramTime) {
    result.elapsedProgramTime = elapsedProgramTime.value;
  }
  // remainingProgramTime
  const remainingProgramTime = options['BSH.Common.Option.RemainingProgramTime'];
  if (remainingProgramTime) {
    result.remainingProgramTime = remainingProgramTime.value;
  }
  // duration
  const duration = options['BSH.Common.Option.Duration'];
  if (duration) {
    result.duration = duration.value;
  }
  // fastPreHeat
  const fastPreHeat = options['Cooking.Oven.Option.FastPreHeat'];
  if (fastPreHeat) {
    result.fastPreHeat = fastPreHeat.value;
  }
  // cavity
  const cavity = options['Cooking.Oven.Option.CavitySelector'];
  if (cavity && cavity.value) {
    result.cavity = cavity.value.split('.').splice(-1)[0];
  }
  // powerState
  const powerState = options['BSH.Common.Setting.PowerState'];
  if (powerState && powerState.value) {
    result.powerState = powerState.value.split('.').splice(-1)[0];
  }
  // preHeatComplete
  const preheatComplete = options['Cooking.Oven.Event.PreheatFinished'];
  if (preheatComplete) {
    result.preheatComplete =
      preheatComplete.value === 'BSH.Common.EnumType.EventPresentState.Present';
  }
  return result;
}

function resetAppliance() {
  return {
    programProgress: null,
    elapsedProgramTime: null,
    remainingProgramTime: null,
    cavity: null,
    activeProgram: null,
    activeProgramIcon: null,
    selectedProgram: null,
    selectedProgramName: null,
    selectedProgramIcon: null,
    programIcon: null,
    preheatComplete: false,
    awaitingManualStart: false,
    setPointTemperature: null,
    duration: null,
  };
}

function reduceOptions(options) {
  return options.reduce(
    (_options, option) => ({
      ..._options,
      [option.key]: { value: option.value, unit: option.unit },
    }),
    {},
  );
}

function updateStatus(state, appliance, notification, activeProgram, selectedProgram, settings) {
  const { status } = appliance;

  let newStatus = {};
  if (Array.isArray(notification)) {
    newStatus = reduceOptions(notification);
  } else {
    newStatus[notification.key] = { value: notification.value, unit: notification.unit };
  }

  // Map the BSH specific keys to some easier (and vendor agnostic) keys
  const applianceUpdates = updateAppliance(newStatus, status);
  // Reset if we have reached a state that can't be resumed from.
  const needsReset = !['Pause', 'Run', 'ActionRequired'].includes(applianceUpdates.operationState);
  if (
    applianceUpdates.operationState &&
    appliance.operationState !== applianceUpdates.operationState &&
    needsReset
  ) {
    Object.assign(applianceUpdates, resetAppliance());
  }

  if (selectedProgram && selectedProgram.options) {
    Object.assign(
      applianceUpdates,
      updateAppliance(reduceOptions(selectedProgram.options), status),
    );
    applianceUpdates.selectedProgram = selectedProgram;
  }
  if (activeProgram && activeProgram.options) {
    Object.assign(applianceUpdates, updateAppliance(reduceOptions(activeProgram.options), status));
    applianceUpdates.activeProgram = activeProgram;
  }
  if (settings) {
    Object.assign(applianceUpdates, updateAppliance(reduceOptions(settings), status));
    applianceUpdates.settings = settings;
  }

  // activeProgramName
  const activeProgramName = newStatus['BSH.Common.Root.ActiveProgram'];
  if (activeProgramName) {
    applianceUpdates.activeProgramName = getProgramName(
      activeProgramName.value,
      appliance.programs,
    );
    applianceUpdates.activeProgram = activeProgramName;
    applianceUpdates.activeProgramIcon = getProgramIcon(activeProgramName.value);
  } else if (activeProgram && activeProgram.key) {
    applianceUpdates.activeProgramName = getProgramName(activeProgram.key, appliance.programs);
    applianceUpdates.activeProgramIcon = getProgramIcon(activeProgram.key);
  }
  // selectedProgramName
  const selectedProgramName = newStatus['BSH.Common.Root.SelectedProgram'];
  if (selectedProgramName) {
    applianceUpdates.selectedProgramName = getProgramName(
      selectedProgramName.value,
      appliance.programs,
    );
    applianceUpdates.selectedProgramIcon = getProgramIcon(selectedProgramName.value);
    applianceUpdates.selectedProgram = selectedProgramName;
  } else if (selectedProgram && selectedProgram.key) {
    applianceUpdates.selectedProgramName = getProgramName(selectedProgram.key, appliance.programs);
    applianceUpdates.selectedProgramIcon = getProgramIcon(selectedProgram.key);
  }

  return {
    status: { ...status, ...newStatus },
    ...applianceUpdates,
  };
}

function updateRecipeStepProgress(state, progressFields) {
  if (!state.recipe) {
    return null;
  }
  const newRecipe = {
    ...state.recipe,
    appliance_adjustments: [...state.recipe.appliance_adjustments],
  };

  const currentStep = state.recipe.appliance_adjustments[state.currentStepIndex];
  newRecipe.appliance_adjustments[state.currentStepIndex] = {
    ...currentStep,
    ...progressFields,
  };
  return newRecipe;
}

function setCurrentStep(state, currentStepIndex, activeStepIndex) {
  let currentStep = null;
  let { recipeInProgress } = state;
  if (
    state.recipe &&
    state.recipe.appliance_adjustments &&
    state.recipe.appliance_adjustments[currentStepIndex]
  ) {
    currentStep = {
      ...state.recipe.appliance_adjustments[currentStepIndex],
      programProgress: null,
      operationState: null,
    };
  } else {
    // Step doesn't exist
    // eslint-disable-next-line no-param-reassign
    currentStepIndex = null;
    recipeInProgress = false;
  }
  return {
    ...state,
    currentStep,
    currentStepIndex,
    activeStepIndex: activeStepIndex || state.activeStepIndex,
    recipeInProgress,
    recipe: updateRecipeStepProgress(state, { operationState: null, programProgress: null }),
    warnings: [],
    ...updateAppliances(state, state.primaryOven ? state.primaryOven.haId : null, {
      awaitingManualStart: false,
    }),
    adjustmentError: null,
  };
}

function updateTemperatureConstraints(programInfo) {
  if (programInfo) {
    const ovenSetTemp = programInfo.options.find(
      option => option.key === 'Cooking.Oven.Option.SetpointTemperature',
    );
    if (ovenSetTemp) {
      return {
        currentMaxTemp: ovenSetTemp.constraints.max,
        currentMinTemp: ovenSetTemp.constraints.min,
      };
    }
  }
  return {};
}

export default function homeConnectReducers(state = { promo: {} }, action) {
  const { type, payload } = action;
  switch (type) {
    case HOME_CONNECT_RESET:
      return { ...state, error: null, loading: false };

    case GET_APPLIANCES_START:
      return { ...state, error: null, loading: true };

    case GET_APPLIANCES_SUCCESS: {
      const appliances = { ...state.appliances };
      payload.appliances.forEach(appliance => {
        appliances[appliance.haId] = {
          ...state.appliances[appliance.haId],
          ...appliance,
          brand: appliance.brand === 'Neff' ? 'NEFF' : appliance.brand, // Fix for the API returning a lower case Neff
        };
      });
      return { ...state, error: null, loading: false, appliances };
    }

    case GET_APPLIANCE_STATUS_START:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          stateFetch: { inProgress: true },
        }),
      };

    case GET_APPLIANCE_STATUS_ERROR:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          stateFetch: { inProgress: false, success: false, error: payload.error },
        }),
      };

    case GET_APPLIANCE_STATUS_SUCCESS:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          ...updateStatus(
            state,
            state.appliances[payload.haId],
            payload.status,
            payload.activeProgram,
            payload.selectedProgram,
          ),
          ...updateTemperatureConstraints(payload.programInfo),
          stateFetch: { inProgress: true, success: true },
          connected: true,
        }),
        warnings: payload.warnings,
      };

    case GET_APPLIANCE_PROGRAMS_START:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          programsFetch: { inProgress: true },
        }),
      };

    case GET_APPLIANCE_PROGRAMS_ERROR:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          programsFetch: { inProgress: false, success: false, error: payload.error },
        }),
      };

    case GET_APPLIANCE_PROGRAMS_SUCCESS:
      // TODO we should update the active/selected program name here
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          programsFetch: { inProgress: false, success: true },
          programs: payload.programs,
        }),
      };

    case GET_APPLIANCES_ERROR:
      return { ...state, error: payload.error, loading: false };

    case APPLIANCE_ADJUSTMENT_START:
      return { ...state, adjustmentError: null, awaitingAdjustment: true, warnings: [] };

    case APPLIANCE_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        adjustmentError: null,
        warnings: payload.warnings,
        awaitingAdjustment: false,
        recipeInProgress: true,
        activeStepIndex: payload.activeStepIndex,
        ...updateAppliances(state, payload.haId, {
          ...updateStatus(state, state.appliances[payload.haId], payload.options),
          ...updateTemperatureConstraints(payload.programInfo),
          awaitingManualStart: payload.awaitingManualStart,
        }),
      };

    case APPLIANCE_ADJUSTMENT_ERROR:
      return { ...state, adjustmentError: payload.error, awaitingAdjustment: false, warnings: [] };

    case HOME_CONNECT_DISCONNECT:
      return {
        hostname: state.hostname,
        clientId: state.clientId,
        token: null,
        appliances: {},
        primaryOven: null,
      };

    case HOME_CONNECT_EVENT_CHANNEL_CONNECTING:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          monitoringChannel: { state: 'connecting' },
        }),
      };

    case HOME_CONNECT_EVENT_CHANNEL_OPEN:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, { monitoringChannel: { state: 'open' } }),
      };

    case HOME_CONNECT_NOTIFICATION: {
      const newState = {
        ...state,
        ...updateAppliances(
          state,
          payload.haId,
          updateStatus(state, state.appliances[payload.haId], payload.notification),
        ),
      };
      newState.recipe = updateRecipeStepProgress(state, {
        programProgress: newState.appliances[payload.haId].programProgress,
        operationState: newState.appliances[payload.haId].operationState,
      });
      return newState;
    }
    case HOME_CONNECT_EVENT_CHANNEL_ERROR:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          monitoringChannel: { state: 'error', error: payload.error },
        }),
      };

    case HOME_CONNECT_EVENT_CHANNEL_CLOSE:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, {
          monitoringChannel: { state: 'closed' },
        }),
      };

    case SET_PRIMARY_OVEN:
      return { ...state, primaryOven: state.appliances[payload.haId] };

    case STOP_APPLIANCE_START:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, { stopping: true }),
      };

    case STOP_APPLIANCE_SUCCESS:
    case STOP_APPLIANCE_ERROR:
      return {
        ...state,
        ...updateAppliances(state, payload.haId, { stopping: false }),
        activeStepIndex: null,
      };

    case HOME_CONNECT_SET_RECIPE: {
      if (state.recipeInProgress) {
        // Don't interrupt the currently running recipe
        return {
          ...state,
          viewingRecipe: payload,
        };
      }
      const recipeHasChanged = !state.recipe || state.recipe.id !== payload.id;
      const currentStepIndex = recipeHasChanged ? 0 : state.currentStepIndex;
      let currentStep;
      if (recipeHasChanged && payload.appliance_adjustments) {
        currentStep = payload.appliance_adjustments[0];
      } else {
        currentStep = state.currentStep;
      }
      return {
        ...state,
        recipe: payload,
        viewingRecipe: payload,
        recipeInProgress: !!payload.recipeInProgress,
        currentStepIndex,
        currentStep,
        adjustmentError: null,
      };
    }
    case HOME_CONNECT_CLEAR_RECIPE:
      return {
        ...state,
        adjustmentError: null,
        currentStepIndex: null,
        currentStep: null,
        recipeInProgress: false,
        recipe: null,
        viewingRecipe: null,
      };

    case HOME_CONNECT_NEXT_STEP:
      return setCurrentStep(
        state,
        typeof state.currentStepIndex === 'number' ? state.currentStepIndex + 1 : 0,
      );

    case HOME_CONNECT_SET_CURRENT_STEP:
      return setCurrentStep(state, payload.currentStepIndex, payload.activeStepIndex);

    case HOME_CONNECT_PROMO_CLAIM_START:
      return {
        ...state,
        promo: {
          ...state.promo,
          [payload.brand.toLowerCase()]: { reason: null, error: null, loading: true },
        },
      };

    case HOME_CONNECT_PROMO_CLAIM_ERROR:
      return {
        ...state,
        promo: { ...state.promo, [payload.brand.toLowerCase()]: { ...payload, loading: false } },
      };

    case HOME_CONNECT_PROMO_CLAIM_ALLOWED:
      return {
        ...state,
        promo: { ...state.promo, [payload.brand.toLowerCase()]: { ...payload, loading: false } },
      };

    case HOME_CONNECT_PROMO_CLAIM_DENIED:
      return {
        ...state,
        promo: { ...state.promo, [payload.brand.toLowerCase()]: { ...payload, loading: false } },
      };

    default:
      return state;
  }
}

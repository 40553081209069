/* eslint-disable import/prefer-default-export */

import { FEATURE_USED, FEATURE_ENABLED } from '../constants';

export function featureUsed(featureName) {
  return {
    type: FEATURE_USED,
    payload: { featureName },
  };
}

export function enableFeature(feature) {
  return {
    type: FEATURE_ENABLED,
    payload: { features: Array.isArray(feature) ? feature : [feature] },
  };
}

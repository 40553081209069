exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".user-menu{outline:none;padding-right:20px;position:relative}.user-menu::after{border-bottom:2px solid rgba(0,0,0,0.85);border-right:2px solid rgba(0,0,0,0.85);content:' ';display:block;height:7px;margin-top:-5px;position:absolute;right:2px;top:50%;transform:rotate(45deg);width:7px}.user-menu__fallback{height:30px;width:50px}.user-menu__login{height:30px;min-width:50px}\n", ""]);

// exports
exports.locals = {
	"user-menu": "user-menu",
	"user-menu__fallback": "user-menu__fallback",
	"user-menu__login": "user-menu__login"
};
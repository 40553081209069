/* eslint-disable jsx-a11y/anchor-has-content */

// node modules
import React, { PureComponent } from 'react';

// app modules: components
import Generic from 'components/Error/Generic';

class ForbiddenUnauthenticatedError extends PureComponent {
  render() {
    return (
      <Generic>
        <p>
          This private collection is only accessible by the owner. If you are the owner, you may
          need to{' '}
          <a href="/logout" className="link link--energized">
            log out
          </a>, and then log in again with the correct account.
        </p>
      </Generic>
    );
  }
}

export default ForbiddenUnauthenticatedError;

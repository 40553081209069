exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".link{border-bottom:1px solid transparent;margin-bottom:-1.2px;font-weight:600}.link--energized{color:#fa6422}.link--light{color:#fff}.link--black{color:#000}.link--underline{text-decoration:underline;-webkit-text-decoration-style:dotted;text-decoration-style:dotted}.link--underline:hover{color:#666666}.link:hover{border-bottom-color:initial}\n", ""]);

// exports
exports.locals = {
	"link": "link",
	"link--energized": "link--energized",
	"link--light": "link--light",
	"link--black": "link--black",
	"link--underline": "link--underline"
};
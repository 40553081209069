/* eslint-disable no-restricted-syntax */
/* eslint-disable no-cond-assign */
export default function checkMeasurementSystem({ ingredients, ingredientsListUnits = [] }) {
  let foundUS = false;
  let foundMetric = false;

  const lowerCaseUnits = (ingredientsListUnits || []).map(unit => unit.toLowerCase());

  if (lowerCaseUnits.includes('metric') && !lowerCaseUnits.includes('us')) {
    return 'onlyMetric';
  } else if (lowerCaseUnits.includes('us') && !lowerCaseUnits.includes('metric')) {
    return 'onlyUs';
  }

  if (!ingredients) {
    return 'neither';
  }

  for (const ingredient of ingredients) {
    const regex = /system="([^"]*)"/gi;
    let match;
    while ((match = regex.exec(ingredient)) !== null) {
      const systems = match[1].toLowerCase().split(',');
      if (systems.includes('us')) {
        foundUS = true;
      }
      if (systems.includes('metric')) {
        foundMetric = true;
      }
    }
  }

  if (foundUS && foundMetric) {
    return 'both';
  } else if (foundUS) {
    return 'onlyUs';
  } else if (foundMetric) {
    return 'onlyMetric';
  }
  return 'neither';
}

import { DEVICE_CONNECTION_STATE_CHANGE, DEVICE_SET_INFO } from '../constants';

function expandVersion(version) {
  if (version) {
    const [majorVersion, minorVersion] = version.split('.');
    return {
      majorVersion: parseInt(majorVersion, 10),
      minorVersion: parseInt(minorVersion, 10),
    };
  }
  return {};
}

export default function deviceReducer(state = {}, action) {
  const { payload } = action;

  switch (action.type) {
    case DEVICE_CONNECTION_STATE_CHANGE:
      return { ...state, connected: payload.connected, connectionType: payload.type };
    case DEVICE_SET_INFO:
      return { ...state, ...payload, ...expandVersion(payload.version) };

    default:
      return state;
  }
}

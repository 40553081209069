export const APP_STORE_REVIEW_SENT_TO_APP_STORE = 'AppStoreReviewSentToAppStore';

export const APP_STORE_REVIEW_HAPPY = 'AppStoreReviewHappy';

export const APP_STORE_REVIEW_UNHAPPY = 'AppStoreReviewUnhappy';

export const APP_STORE_REVIEW_INVITE = 'AppStoreReviewInvite';

export const GOOGLE_PLAY_REVIEW_INVITE = 'GooglePlayReviewInvite';

export const FIRST_TIME_LAUNCHED_ANDROID = 'FirstTimeLaunchedAndroid';

export const FIRST_TIME_LAUNCHED_IOS = 'FirstTimeLaunchedIOS';

exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".figure{display:inline-block;overflow:hidden;position:relative;vertical-align:top}.figure::after{border-radius:inherit;content:' ';height:100%;left:0;position:absolute;top:0;width:100%}.figure--circle{border-radius:50%}.figure--rounded{border-radius:3px}@media print{.figure::after{content:unset}}\n", ""]);

// exports
exports.locals = {
	"figure": "figure",
	"figure--circle": "figure--circle",
	"figure--rounded": "figure--rounded"
};
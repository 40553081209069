import React, { Suspense, useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import PropTypes from 'prop-types';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useQuery, gql } from '@apollo/client';
import NoSsr from '@material-ui/core/NoSsr';

import useLocalstorageState from 'hooks/useLocalstorageState';

import Icon from 'components/Icon';

import { openModal } from 'actions/modals';
import { MODALS } from 'constants/view';
import history from 'services/history';

// assets
import CssNotificationButton from './NotificationButton.scss';

const LazyNotificationDrawer = React.lazy(() =>
  import(/* webpackChunkName: 'notifications-menu' */ 'components/NotificationButton/NotificationDrawer'),
);

const GET_NOTIFICATIONS_COUNT = gql`
  query GetUnreadCount {
    User {
      id
      unread_notifications_count
    }
  }
`;

function NotificationButton(props) {
  useStyles(CssNotificationButton);
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.user.logged_in);
  const userLoaded = useSelector(state => state.user.id > 0);
  const isOpen = useSelector(state => state.router.pathname === '/user/notifications');
  const [loaded, setLoaded] = useState(false);
  const [ringing, setRinging] = useState(false);
  const deviceType = useSelector(state => state.dimensions.displayType);
  const pageIsVisible = usePageVisibility();
  const [lastUnreadCount, setLastUnreadCount, clearLastUnreadCount] = useLocalstorageState(
    `lastUnreadCount`,
    0,
  );

  const { data } = useQuery(GET_NOTIFICATIONS_COUNT, {
    variables: { _authenticated: true },
    ssr: false,
    skip: !userLoaded || !loggedIn || !pageIsVisible,
    fetchPolicy: 'cache-and-network',
    pollInterval: 30000,
  });
  const unreadCount = data?.User.unread_notifications_count;

  useEffect(
    () => {
      if (typeof unreadCount === 'number' && unreadCount !== lastUnreadCount) {
        setLastUnreadCount(unreadCount);
      }
      if (unreadCount > lastUnreadCount) {
        setRinging(true);
        setTimeout(() => {
          setRinging(false);
        }, 4000);
      }
    },
    [unreadCount, lastUnreadCount, setLastUnreadCount],
  );

  const loadNotifications = () => {
    if (deviceType !== 'mobile') {
      setLoaded(true);
    }
  };

  const handleButtonClick = () => {
    if (deviceType === 'mobile') {
      if (isOpen) {
        history.goBack();
      } else {
        clearLastUnreadCount();
        history.push('/user/notifications');
      }
    } else {
      setLoaded(true);
      clearLastUnreadCount();
      setTimeout(() => dispatch(openModal({ name: MODALS.NOTIFICATIONS })), 0);
    }
  };

  if (!loggedIn) {
    return null;
  }

  const iconProps = {
    strokeWidth: 1,
    height: 22,
    width: 22,
    className: `notification-button__icon ${
      isOpen ? 'notification-button__icon--open' : 'notification-button__icon--closed'
    }`,
  };

  const unreadIndicator =
    unreadCount > 0 ? (
      <div className="notification-button__unread">
        <div className="notification-button__dot" />
      </div>
    ) : null;
  const buttonClassNames = classNames('notification-button', props.className, {
    'notification-button--unread': unreadCount > 0,
    'notification-button--ringing': ringing,
  });
  const facade = (
    <button
      className={buttonClassNames}
      onClick={handleButtonClick}
      onMouseOver={loadNotifications}
      onFocus={loadNotifications}
      data-cy="notifications-menu-icon"
      aria-haspopup="true"
      aria-controls="notifications-feed"
    >
      <Icon name="bell" stroke="currentColor" fill="currentColor" {...iconProps} />
      {unreadIndicator}
    </button>
  );

  if (deviceType !== 'mobile' && loaded) {
    return (
      <NoSsr fallback={facade}>
        <Suspense fallback={facade}>
          <LazyNotificationDrawer
            iconProps={iconProps}
            unreadIndicator={unreadIndicator}
            {...props}
            className={buttonClassNames}
          />
        </Suspense>
      </NoSsr>
    );
  }
  return facade;
}

NotificationButton.propTypes = {
  className: PropTypes.string,
};

NotificationButton.defaultProps = {
  className: null,
};

export default NotificationButton;

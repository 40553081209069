let sendCount = 0;

function sendError(event) {
  if (event?.message?.startsWith('ResizeObserver') || event?.message?.startsWith('Script error')) {
    return false;
  }
  console.error('Received error', event);
  const { filename, lineno, colno, componentStack } = event;
  let { error } = event;
  if (!error && event.message) {
    error = event;
  }
  if (!error) {
    error = {
      code: 'UNKNOWN',
      message: JSON.stringify(event),
      name: 'UnknownError',
      stack: new Error().stack,
    };
  }
  const { code, message, name, stack } = error;

  const { version, build, meta, state } = window.App;
  const platform = window.cordova ? 'app' : 'web';
  const now = new Date().toISOString();
  const env = state?.config?.environment;

  let user;
  try {
    user = JSON.parse(localStorage.getItem('user'));
  } catch (err) {
    user = null;
  }

  const url =
    env === 'BETA' || env === 'PROD'
      ? 'https://lqf8gmijbf.execute-api.eu-west-1.amazonaws.com/prod/error'
      : 'https://3pm18b0gil.execute-api.eu-west-1.amazonaws.com/stage/error';

  try {
    const payload = {
      build,
      clientTime: now,
      code,
      colno,
      componentStack,
      env,
      filename,
      lineno,
      message,
      meta,
      name,
      platform,
      stack,
      version,
      url: window.location.href,
      userId: user?.id,
    };
    let sent = false;
    if (navigator.sendBeacon && sendCount < 10) {
      sent = navigator.sendBeacon(url, JSON.stringify(payload));
    }
    if (!sent) {
      console.warn('Failed to send payload to server', payload);
    } else {
      sendCount += 1;
      console.info('sent error with sendBeacon', payload);
    }
  } catch (err) {
    console.error('Error sending error to server', err);
  }
  return false;
}

window.sendError = sendError;
window.addEventListener('error', sendError);

function resetSendCount() {
  sendCount = 0;
  setTimeout(resetSendCount, 1000 * 60 * 10);
}

resetSendCount();

/* eslint-disable import/prefer-default-export */
import getCollections from 'actions/user/getCollections';
import { getFavouriteContent } from 'actions/favourites';
import { refreshUser } from 'actions/user/refreshUser';
import { USER_REFRESH_SUCCESS } from '../../constants';

export function loadUser(useLocalState) {
  return async (dispatch, getState) => {
    if (useLocalState) {
      // This mode is useful so we can access cached user state before deviceready has been called.
      // Fetching an access token requires the native http plugin to be initialized, so until that's
      // ready we'll use the previous user object.
      try {
        const cachedUserState = localStorage.getItem('user');
        console.info('Found cached user state');
        if (cachedUserState) {
          const user = JSON.parse(cachedUserState);
          if (user.logged_in) {
            user.from_local_storage = true;
            await dispatch({
              type: USER_REFRESH_SUCCESS,
              payload: { user },
            });
          }
        }
      } catch (err) {
        console.warn('Could not restore user state from local storage', err);
      }
      return;
    }
    // Network fetch - can only happen after deviceready.
    // First refresh the user - we need the id
    await dispatch(refreshUser(null, null, false));
    const { id } = getState().user;
    // Now we can blast out requests for other resources.
    // Perhaps we could combine these into a single GraphQL query?
    if (id > 0) {
      [getCollections(id), getFavouriteContent()].map(dispatch);
    }
  };
}

export default {
  isAccessorEmpty(accessor) {
    return !accessor || accessor.state.value === null || accessor.state.value.length === 0;
  },

  isEmptyState(accessors, filters, minimumChars = 1) {
    const { statefulAccessors, queryAccessor } = accessors;
    const areFiltersEmpty = Object.values(filters).every(filter =>
      this.isAccessorEmpty(statefulAccessors[filter]),
    );
    const isQueryEmpty = this.isAccessorEmpty(queryAccessor);
    const charsTyped = queryAccessor.state.getValue() ? queryAccessor.state.getValue().length : 0;

    return areFiltersEmpty && (isQueryEmpty || charsTyped < minimumChars);
  },
};

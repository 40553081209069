exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".rc-tooltip.rc-tooltip--light .rc-tooltip-inner{background-color:#fff;color:#333}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .rc-tooltip.rc-tooltip--light .rc-tooltip-inner{background-color:#000;color:#fff;box-shadow:0 0 4px rgba(255,255,255,0.4)}}:root[data-theme=dark] .rc-tooltip.rc-tooltip--light .rc-tooltip-inner{background-color:#000;color:#fff;box-shadow:0 0 4px rgba(255,255,255,0.4)}.rc-tooltip.rc-tooltip--light .rc-tooltip-arrow{background-color:#fff}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .rc-tooltip.rc-tooltip--light .rc-tooltip-arrow{background-color:#000;box-shadow:-1.5px -1.5px 1px 0 rgba(255,255,255,0.18)}}:root[data-theme=dark] .rc-tooltip.rc-tooltip--light .rc-tooltip-arrow{background-color:#000;box-shadow:-1.5px -1.5px 1px 0 rgba(255,255,255,0.18)}\n", ""]);

// exports
exports.locals = {
	"rc-tooltip": "rc-tooltip",
	"rc-tooltip--light": "rc-tooltip--light",
	"rc-tooltip-inner": "rc-tooltip-inner",
	"rc-tooltip-arrow": "rc-tooltip-arrow"
};
const switchToSystemPreferredFontZoom = async (value = true) => {
  if (!window.MobileAccessibility) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    window.MobileAccessibility.usePreferredTextZoom(value);
    setTimeout(() => {
      resolve();
    });
  });
};

export default switchToSystemPreferredFontZoom;

const getSystemFontZoom = async () => {
  if (!window.MobileAccessibility) {
    return parseFloat(getComputedStyle(document.documentElement).fontSize) / 16;
  }

  return new Promise(resolve => {
    window.MobileAccessibility.getTextZoom(textZoom => {
      resolve(textZoom / 100);
    });
  });
};

export default getSystemFontZoom;

import {
  ADD_FAVOURITE_CONTENT,
  REMOVE_FAVOURITE_CONTENT,
  FAVOURITES_GET_START,
  FAVOURITES_GET_SUCCESS,
  FAVOURITE_CONTENT_SAVE_ERROR,
  FAVOURITES_GET_ERROR,
  CONTENT_TYPES,
} from '../constants';

function updateFavouriteState(state, { results }) {
  const newState = Object.assign({}, state, { loaded: true });
  delete newState.loading;
  delete newState.error;
  if (results.FavouriteRecipes) {
    newState[CONTENT_TYPES.RECIPE] = results.FavouriteRecipes.map(item => item.id);
  }
  if (results.FavouriteReferences) {
    newState[CONTENT_TYPES.REFERENCE] = results.FavouriteReferences.map(item => item.id);
  }
  if (results.FavouriteSections) {
    newState[CONTENT_TYPES.SECTION] = results.FavouriteSections.map(item => item.id);
  }
  if (results.FavouriteBooks) {
    newState[CONTENT_TYPES.BOOK] = results.FavouriteBooks.map(item => item.isbn);
  }
  if (results.FavouritePeople) {
    newState[CONTENT_TYPES.PERSON] = results.FavouritePeople.map(item => item.id);
  }
  if (results.FavouriteCollections) {
    newState[CONTENT_TYPES.COLLECTION] = results.FavouriteCollections.map(item => `${item.id}`);
  }
  return newState;
}

function addFavouriteContent(state, { contentType, contentId }) {
  const content = {};
  if (state[contentType]) {
    content[contentType] = Array.from(new Set(state[contentType].concat(contentId)));
  } else {
    content[contentType] = [contentId];
  }
  return Object.assign({}, state, content);
}

function removeFavouriteContent(state, { contentType, contentId }) {
  const content = {};
  content[contentType] = state[contentType]
    ? state[contentType].filter(item => item !== contentId)
    : [];
  return Object.assign({}, state, content);
}

function updateErrorState(state, { error }) {
  const newState = Object.assign({}, state, { error });
  delete newState.loading;
  return newState;
}

export default function favouritesReducers(state = {}, action) {
  switch (action.type) {
    case FAVOURITES_GET_SUCCESS:
      return updateFavouriteState(state, action.payload);

    case ADD_FAVOURITE_CONTENT:
      return addFavouriteContent(state, action.payload);

    case REMOVE_FAVOURITE_CONTENT:
      return removeFavouriteContent(state, action.payload);

    case FAVOURITES_GET_START:
      return Object.assign({}, state, { loading: true });

    case FAVOURITE_CONTENT_SAVE_ERROR:
    case FAVOURITES_GET_ERROR:
      return updateErrorState(state, action.payload);

    default:
      return state;
  }
}

import { SIDEMENU_OPEN, SIDEMENU_CLOSE } from '../constants';

export default function sidemenuReducers(state = {}, action) {
  switch (action.type) {
    case SIDEMENU_OPEN:
      return Object.assign({}, state, {
        isOpen: true,
      });

    case SIDEMENU_CLOSE:
      return Object.assign({}, state, {
        isOpen: false,
      });

    default:
      return state;
  }
}

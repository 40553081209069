import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useScript from 'react-script-hook';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useMutationObserver } from 'rooks';

import CssAdSense from './Advert.scss';

function AdSense(props) {
  const { slot, onReady, format, hideIfUnfilled } = props;
  useStyles(CssAdSense);
  const containerRef = React.createRef();
  const enableTest = useSelector(state => state.config.adverts.testMode);
  const clientId = useSelector(state => state.config.adverts.adSenseClientId);
  const googleAdFormat = useSelector(state => slot.format || state.config.adverts.format);
  const fullWidthResponsive = useSelector(
    state =>
      typeof props.fullWidthResponsive === 'boolean'
        ? props.fullWidthResponsive
        : state.config.adverts.fullWidthResponsive,
  );

  const [loading, error] = useScript({
    src:
      clientId && clientId !== 'DISABLED'
        ? `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${clientId}`
        : null,
    checkForExisting: true,
    crossOrigin: 'anonymous',
  });
  const disabled =
    !slot || !slot.id || slot.id === 'DISABLED' || !clientId || clientId === 'DISABLED';

  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        if (!loading && !error && window.adsbygoogle?.loaded) {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (err) {
            // Ignore
          }
        }
        if (disabled || (error && !window.adsbygoogle?.loaded)) {
          onReady({ filled: false });
        }
      }
    },
    [loading, error, onReady, disabled],
  );

  // Observe changes in the 'data-ad-status' attribute so we know if the ad filled
  // See https://support.google.com/adsense/answer/10762946?hl=en-GB
  const handleStatusChange = useCallback(
    mutationList => {
      if (mutationList.length) {
        const { adStatus } = mutationList[0].target.dataset;
        onReady({ filled: adStatus === 'filled' });
      }
    },
    [onReady],
  );
  useMutationObserver(containerRef, handleStatusChange, {
    attributeFilter: ['data-ad-status'],
  });

  if (disabled) {
    return null;
  }

  return (
    <ins
      ref={containerRef}
      className={classNames(`advert--${format}`, 'adsbygoogle', {
        'adsbygoogle--hide-when-unfilled': hideIfUnfilled,
      })}
      data-ad-client={clientId}
      data-ad-slot={slot.id}
      data-ad-format={googleAdFormat}
      data-full-width-responsive={fullWidthResponsive ? 'true' : 'false'}
      data-adtest={enableTest ? 'on' : 'off'}
      data-ad-layout-key={slot.layoutKey}
    />
  );
}

AdSense.propTypes = {
  onReady: PropTypes.func,
  slot: PropTypes.shape({ id: PropTypes.string.isRequired }).isRequired,
  fullWidthResponsive: PropTypes.bool,
  format: PropTypes.string,
  hideIfUnfilled: PropTypes.bool,
};

AdSense.defaultProps = {
  onReady: () => {},
  fullWidthResponsive: null,
  format: 'default',
  hideIfUnfilled: true,
};

export default React.memo(AdSense);

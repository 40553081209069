import {
  AUTH_USER,
  AUTH_IN_PROGRESS,
  AUTH_ERROR,
  FORGOT_IN_PROGRESS,
  FORGOT_SUCCESS,
  SIGNUP_BY_EMAIL_IN_PROGRESS,
  SIGNUP_BY_EMAIL,
  SIGNUP_ERROR,
  AUTH_CLEARDOWN,
  SOCIAL_AUTH_IN_PROGRESS,
  SOCIAL_AUTH_COMPLETE,
  SOCIAL_AUTH_CANCEL,
} from '../constants';

const INIT = {
  forgotMessage: '',
  error: '',
  code: null,
  timestamp: '',
  loading: false,
  authenticated: false,
  socialAuthInProgress: null,
};

export default function(state = {}, action) {
  switch (action.type) {
    case AUTH_USER:
    case SIGNUP_BY_EMAIL:
      return { ...state, ...INIT, authenticated: true };
    case AUTH_IN_PROGRESS:
    case FORGOT_IN_PROGRESS:
    case SIGNUP_BY_EMAIL_IN_PROGRESS:
      return { ...state, ...INIT, loading: true };
    case FORGOT_SUCCESS:
      return { ...state, ...INIT, forgotMessage: action.message };
    case AUTH_ERROR:
    case SIGNUP_ERROR:
      return {
        ...state,
        ...INIT,
        error: action.error,
        timestamp: action.timestamp,
        code: action.code,
        socialAuthInProgress: null,
      };
    case AUTH_CLEARDOWN:
      return { ...INIT };
    case SOCIAL_AUTH_IN_PROGRESS:
      return { ...state, socialAuthInProgress: action.payload.provider, error: null };
    case SOCIAL_AUTH_COMPLETE:
    case SOCIAL_AUTH_CANCEL:
      return { ...state, socialAuthInProgress: null, error: null };
    default:
      return state;
  }
}

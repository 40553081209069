// node modules
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// app modules: props
import LoginProps from 'components/Login/LoginProps';

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

class Satsimeter extends Component {
  static propTypes = {
    writeKey: PropTypes.string.isRequired,
    user: LoginProps.isRequired,
  };

  componentDidMount() {
    this.initSatismeter();
  }

  /**
   * Unbinds events, shutdown Satismeter, removes global objects.
   */
  componentWillUnmount() {
    if (!canUseDOM || !window.satismeter) {
      return;
    }

    delete window.satismeter;
  }

  initSatismeter() {
    const { writeKey, user } = this.props;

    if (!writeKey || !canUseDOM || !user.id) {
      return;
    }
    /* The following code is from Satismeter */
    /* eslint-disable */
    window.satismeter =
      window.satismeter ||
      function() {
        (window.satismeter.q = window.satismeter.q || []).push(arguments);
      };
    window.satismeter.l = 1 * new Date();
    const script = document.createElement('script');
    const parent = document.getElementsByTagName('script')[0].parentNode;
    script.async = 1;
    script.src = 'https://app.satismeter.com/satismeter.js';
    parent.appendChild(script);

    window.satismeter({
      writeKey: writeKey,
      userId: user.id,
      traits: {
        name: user.display_name,
        email: user.email,
      },
      // To test: forceSurvey: true
    });
  }

  render() {
    return false;
  }
}

const mapStateToProps = state => ({
  writeKey: state.config.satismeter.writeKey,
  user: state.user,
});

export default connect(
  mapStateToProps,
  null,
)(Satsimeter);

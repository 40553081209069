import React from 'react';

// app: components
import NoLongerAvailable from 'components/Error/NoLongerAvailable';
import NotFound from 'components/Error/NotFound';
import TerritorialRestriction from 'components/Error/TerritorialRestriction';
import Forbidden from 'components/Error/Collection/Forbidden';

function ErrorRouter(props) {
  const { error } = props;
  const code =
    error?.code ||
    (error?.graphQLErrors?.length ? error?.graphQLErrors[0]?.extensions?.code : null);
  let errorDisplay;
  let statusCode;
  switch (code) {
    case 'TERRITORIAL_RESTRICTION':
      errorDisplay = <TerritorialRestriction />;
      statusCode = 200;
      break;
    case 'NO_LONGER_AVAILABLE':
      errorDisplay = <NoLongerAvailable />;
      statusCode = 404;
      break;
    case 'FORBIDDEN_COLLECTION':
      errorDisplay = <Forbidden />;
      statusCode = 403;
      break;
    case 'NOT_FOUND':
    default:
      errorDisplay = <NotFound />;
      statusCode = 404;
      break;
  }
  // For SSR, we throw the error to signal that failure of this query should abort the page render.
  // Currently there don't seem to be any better options in apollo client.
  // Further discussion: https://github.com/apollographql/apollo-client/issues/3897
  if (typeof window === 'undefined') {
    // Server side
    const ssrError = new Error(code || error.message);
    ssrError.status = statusCode;
    ssrError.component = errorDisplay;
    throw ssrError;
  } else {
    // Client side
    return errorDisplay;
  }
}

export default ErrorRouter;

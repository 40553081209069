/* eslint-disable import/prefer-default-export */
import { USER_LOGIN_STATUS, USER_LOGOUT, USER_UPDATE_INFO } from 'constants/index';

export function updateUserLoginStatus(payload) {
  return {
    type: USER_LOGIN_STATUS,
    payload,
  };
}

export function userLogout() {
  return {
    type: USER_LOGOUT,
  };
}

export function refreshUserInfo(payload) {
  return {
    type: USER_UPDATE_INFO,
    payload,
  };
}

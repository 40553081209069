import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

function IntercomForApp(props) {
  const { userData, loggedIn } = props;
  const deviceReady = useSelector(state => state.device.available);
  const [registered, setRegistered] = useState(false);

  useEffect(
    () => {
      if (window.intercom && deviceReady) {
        const { intercom } = window;
        if (loggedIn) {
          // eslint-disable-next-line camelcase
          const { user_id, email, ...custom_attributes } = userData;
          if (!registered) {
            console.info('Intercom: register');
            intercom.registerIdentifiedUser({
              userId: String(user_id),
              email,
            });
            console.info('Intercom: registerForPush');
            intercom.registerForPush();
            intercom.sendPushTokenToIntercom();
            setRegistered(true);
          } else {
            console.info('Intercom: update');
            intercom.updateUser({
              custom_attributes,
            });
          }
        } else if (registered && !loggedIn) {
          console.info('Intercom: logout');
          intercom.logout();
          setRegistered(false);
        }
      }
    },
    [deviceReady, loggedIn, registered, userData],
  );
  return null;
}

const areEqual = (prevProps, nextProps) =>
  prevProps.loggedIn === nextProps.loggedIn && isEqual(prevProps.userData, nextProps.userData);

export default React.memo(IntercomForApp, areEqual);

/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */

import pluralize from 'pluralize';
import get from 'lodash/get';

import {
  USER_CHANGE_SUBSCRIPTION_PLAN_ERROR,
  USER_CHANGE_SUBSCRIPTION_PLAN_START,
  USER_CHANGE_SUBSCRIPTION_PLAN_SUCCESS,
  USER_CLAIM_VOUCHER_CHECKOUT,
  USER_CLAIM_VOUCHER_ERROR,
  USER_CLAIM_VOUCHER_START,
  USER_CLAIM_VOUCHER_SUCCESS,
  USER_COLLECTION_ADD_ERROR,
  USER_COLLECTION_ADD_ITEM_TO_ERROR,
  USER_COLLECTION_ADD_ITEM_TO_START,
  USER_COLLECTION_ADD_ITEM_TO_SUCCESS,
  USER_COLLECTION_ADD_START,
  USER_COLLECTION_ADD_SUCCESS,
  USER_COLLECTION_DELETE_ERROR,
  USER_COLLECTION_DELETE_START,
  USER_COLLECTION_DELETE_SUCCESS,
  USER_COLLECTION_GET_ERROR,
  USER_COLLECTION_GET_ITEMS_SUCCESS,
  USER_COLLECTION_GET_START,
  USER_COLLECTION_GET_SUCCESS,
  USER_COLLECTION_MARK_ITEM,
  USER_COLLECTION_REMOVE_ITEM_ERROR,
  USER_COLLECTION_REMOVE_ITEM_START,
  USER_COLLECTION_REMOVE_ITEM_SUCCESS,
  USER_COLLECTION_TEMPORARY,
  USER_COLLECTION_UPDATE_ERROR,
  USER_COLLECTION_UPDATE_START,
  USER_COLLECTION_UPDATE_SUCCESS,
  USER_COLLECTIONS_GET_ERROR,
  USER_COLLECTIONS_GET_SUCCESS,
  USER_COOKIE_CONSENT,
  USER_LOGIN_STATUS,
  USER_LOGOUT,
  USER_POSITIVE_FEEDBACK_GET_SUCCESS,
  USER_REFRESH_ERROR,
  USER_REFRESH_START,
  USER_REFRESH_SUCCESS,
  USER_SETTINGS_CHANGE_ERROR,
  USER_SETTINGS_CHANGE_LOCAL,
  USER_SETTINGS_CHANGE_START,
  USER_SETTINGS_CHANGE_SUCCESS,
  USER_SUBSCRIPTION_STATUS,
  USER_INFO_UPDATE_START,
  USER_INFO_UPDATE_SUCCESS,
  USER_INFO_UPDATE_ERROR,
  USER_UPDATE_INFO,
} from '../constants';

const collectionKey = id => `collection-${id}`;

const INIT = {
  collections: {},
  logged_in: false,
  geolocation: {},
  cookie_consent: 'pending',
};

export default function userReducers(state = {}, action) {
  const data = {};

  switch (action.type) {
    case USER_LOGIN_STATUS:
      return { ...state, ...action.payload };

    case USER_LOGOUT:
      return { ...INIT };

    case USER_SUBSCRIPTION_STATUS:
      return { ...state, subscription: action.payload.subscription };

    case USER_COLLECTION_DELETE_START: {
      const { id } = action.payload;
      const key = collectionKey(id);
      const collections = { ...state.collections };
      data[key] = { ...collections[key], state: 'deleting' };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_DELETE_SUCCESS: {
      const { id } = action.payload;
      const key = collectionKey(id);
      const collections = { ...state.collections };
      delete collections[key];

      return {
        ...state,
        collections: Object.assign({}, collections),
      };
    }

    case USER_COLLECTION_DELETE_ERROR: {
      const { id } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);
      data[key] = { ...collections[key], state: 'error' };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_UPDATE_START: {
      const { id, ...rest } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);
      data[key] = { ...collections[key], state: 'loading', ...rest };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_INFO_UPDATE_START: {
      const { input } = action.payload;
      const user = { ...state };

      user.state = 'loading';
      user.error = { input };

      return {
        ...state,
        ...user,
      };
    }

    case USER_INFO_UPDATE_ERROR: {
      const { errors } = action.payload;
      const user = { ...state };

      user.state = 'error';
      user.error = {
        ...user.error,
        code: errors[0].extensions ? errors[0].extensions.code : errors[0].code,
      };

      return {
        ...state,
        ...user,
      };
    }

    case USER_INFO_UPDATE_SUCCESS: {
      const { input } = action.payload;
      const user = { ...state };

      user.email = input.email;
      user.error = null;
      user.state = 'loaded';

      localStorage.setItem('user', JSON.stringify({ ...user }));

      return {
        ...state,
        ...user,
      };
    }

    case USER_UPDATE_INFO: {
      const user = {
        ...state,
        ...action.payload,
      };

      localStorage.setItem('user', JSON.stringify({ ...user }));

      return {
        ...user,
      };
    }

    case USER_COLLECTION_UPDATE_SUCCESS: {
      const {
        id,
        input: { books, contents, people },
        name,
        description,
        visibility,
        share_key,
        cover_image,
        items_count,
        safe_description,
        custom_cover_image,
        custom_hero_image,
        updated_at,
        tags,
      } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);
      data[key] = {
        ...collections[key],
        name,
        description,
        safe_description,
        visibility,
        share_key,
        state: 'loaded',
        cover_image,
        custom_cover_image,
        custom_hero_image,
        items_count,
        updated_at,
        tags,
        items: collections[key].items ? { ...collections[key].items } : {},
      };
      data[key].items_count = items_count || {
        books: books ? books.length : data[key].items_count.books,
        contents: contents ? contents.length : data[key].items_count.contents,
        people: people ? people.length : data[key].items_count.people,
      };
      if (!items_count) {
        if (books || contents || people) {
          data[key].items_count.total =
            data[key].items_count.books +
            data[key].items_count.people +
            data[key].items_count.contents;
        }
      }

      if (books && get(data[key], 'items.books', false)) {
        data[key].items.books = data[key].items.books.filter(book => books.includes(book.isbn));
      }

      if (contents && get(data[key], 'items.contents', false)) {
        data[key].items.contents = data[key].items.contents.filter(content =>
          contents.includes(content.id),
        );
      }

      if (people && get(data[key], 'items.people', false)) {
        data[key].items.people = data[key].items.people.filter(person =>
          people.includes(person.id),
        );
      }

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_UPDATE_ERROR: {
      const { id } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);
      data[key] = { ...collections[key], state: 'error' };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_ADD_START: {
      data[USER_COLLECTION_TEMPORARY] = { state: 'loading' };
      return {
        ...state,
        collections: Object.assign({}, state.collections, data),
      };
    }

    case USER_COLLECTION_ADD_SUCCESS: {
      const { collection } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(collection.id);

      delete collections[USER_COLLECTION_TEMPORARY];
      data[key] = { ...collection, state: 'loaded' };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_ADD_ERROR: {
      const collections = { ...state.collections };
      const { errors } = action.payload;
      data[USER_COLLECTION_TEMPORARY] = { state: 'error', errors };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }
    case USER_COLLECTION_GET_START: {
      const { id } = action.payload;
      const key = collectionKey(id);
      return {
        ...state,
        collections: {
          ...state.collections,
          [key]: { ...state.collections[key], state: 'loading' },
        },
      };
    }

    case USER_COLLECTION_GET_SUCCESS: {
      return {
        ...state,
        collections: { ...state.collections, ...action.payload },
      };
    }

    case USER_POSITIVE_FEEDBACK_GET_SUCCESS: {
      return {
        ...state,
        positiveFeedbackCount: action.payload,
      };
    }

    case USER_COLLECTION_GET_ERROR: {
      const { errors } = action.payload;
      return {
        ...state,
        collections: {
          errors,
        },
      };
    }

    case USER_COLLECTIONS_GET_SUCCESS: {
      const { collections } = action.payload;
      return {
        ...state,
        collections,
      };
    }

    case USER_COLLECTIONS_GET_ERROR: {
      const { errors } = action.payload;
      return {
        ...state,
        collections: {
          errors,
        },
      };
    }

    case USER_COLLECTION_GET_ITEMS_SUCCESS: {
      const { id, items } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);

      data[key] = { ...collections[key] };
      data[key].items = items;

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_MARK_ITEM: {
      const { id, itemId, itemKey, itemType, isChecked } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);
      const itemsType = pluralize(itemType);

      data[key] = { ...collections[key] };
      const itemsOfType = data[key].items[itemsType];
      const itemIndex = itemsOfType.findIndex(obj => obj[itemKey] === itemId);
      data[key] = {
        ...data[key],
        items: {
          ...data[key].items,
          [itemsType]: [...data[key].items[itemsType]].map(
            (item, i) => (i === itemIndex ? { ...item, checked: isChecked } : item),
          ),
        },
      };
      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_ADD_ITEM_TO_START:
    case USER_COLLECTION_REMOVE_ITEM_START: {
      const { id } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);

      data[key] = { ...collections[key], state: 'loading' };

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_ADD_ITEM_TO_SUCCESS: {
      const { id, item_id, item_type, cover_image } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);

      data[key] = {
        content_ids: [],
        ...collections[key],
        items_count: collections[key].items_count
          ? { ...collections[key].items_count }
          : { total: 0 },
        state: 'loaded',
      };
      data[key].items_count.total += 1;
      data[key].items_count[pluralize(item_type)] += 1;
      data[key].cover_image = cover_image;
      data[key].updated_at = String(Date.now());
      if (item_type === 'content') {
        data[key].content_ids = [...data[key].content_ids, String(item_id)];
      }

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_ADD_ITEM_TO_ERROR:
    case USER_COLLECTION_REMOVE_ITEM_ERROR: {
      const collections = { ...state.collections };
      const { errors } = action.payload;
      const error = errors[0];
      const { id } = error.extensions.exception.data;
      const key = collectionKey(id);

      switch (error.extensions.code) {
        case 'BAD_USER_INPUT':
          data[key] = { ...collections[key], state: 'loaded' };
          break;

        default:
          data[key] = { ...collections[key], state: 'error' };
      }

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_COLLECTION_REMOVE_ITEM_SUCCESS: {
      const { id, item_id, item_type, cover_image } = action.payload;
      const collections = { ...state.collections };
      const key = collectionKey(id);

      data[key] = {
        content_ids: [],
        ...collections[key],
        items_count: collections[key].items_count
          ? { ...collections[key].items_count }
          : { total: 0 },

        state: 'loaded',
      };
      data[key].items_count.total -= 1;
      data[key].items_count[pluralize(item_type)] -= 1;
      data[key].cover_image = cover_image;
      data[key].updated_at = String(Date.now());
      if (item_type === 'content') {
        data[key].content_ids = data[key].content_ids.filter(_id => _id !== item_id);
      }

      return {
        ...state,
        collections: Object.assign({}, collections, data),
      };
    }

    case USER_CLAIM_VOUCHER_START:
      return {
        ...state,
        voucher_claim_in_progress: true,
      };

    case USER_CLAIM_VOUCHER_CHECKOUT:
      return {
        ...state,
        voucher_checkout: action.payload.checkout,
        subscription: { ...state.subscription, voucher_code: action.payload.code },
        voucher_error: null,
        voucher_claim_in_progress: false,
      };

    case USER_CLAIM_VOUCHER_ERROR:
      return {
        ...state,
        voucher_error: action.payload.error,
        voucher_claim_in_progress: false,
      };

    case USER_CLAIM_VOUCHER_SUCCESS:
    case USER_CHANGE_SUBSCRIPTION_PLAN_SUCCESS: {
      const { user, subscription, preview } = action.payload;

      const newSubscription = {
        ...subscription,
        cancellation_effective_date: subscription.cancellation_effective_date
          ? new Date(subscription.cancellation_effective_date)
          : null,
        created: new Date(subscription.created),
        next_retry_date: subscription.next_retry_date
          ? new Date(subscription.next_retry_date)
          : null,
        next_bill_date: subscription.next_bill_date ? new Date(subscription.next_bill_date) : null,
        voucher_code: action.payload.code,
      };
      if (preview) {
        return {
          ...state,
          subscription_update_preview: newSubscription,
          updating_subscription: false,
          voucher_error: null,
          voucher_claim_in_progress: false,
        };
      }
      return {
        ...state,
        ...user,
        subscription: newSubscription,
        updating_subscription: false,
        voucher_error: null,
        voucher_claim_in_progress: false,
      };
    }

    case USER_CHANGE_SUBSCRIPTION_PLAN_START:
      if (action.payload.preview) {
        return { ...state };
      }
      return { ...state, updating_subscription: true, subscription_update_error: null };

    case USER_CHANGE_SUBSCRIPTION_PLAN_ERROR:
      return {
        ...state,
        updating_subscription: false,
        subscription_update_error: action.payload.error,
      };

    case USER_REFRESH_START:
      return {
        ...state,
        loading: true,
        subscription: state.subscription ? { ...state.subscription, loading: true } : null,
      };

    case USER_REFRESH_SUCCESS:
      const { user } = action.payload;
      if (user && Object.entries(user).length) {
        return {
          ...state,
          ...user,
          loading: false,
          error: null,
          credentials: {
            ...state.credentials,
            ...(user.credentials
              ? { ...user.credentials, expires_at: new Date(user.credentials.expires_at) }
              : {}),
          },
          clock_offset:
            user.issued_at && !user.from_local_storage
              ? new Date().getTime() - new Date(user.issued_at).getTime()
              : 0,
          subscription: user.subscription
            ? {
                ...user.subscription,
                created: user.subscription.created ? new Date(user.subscription.created) : null,
                next_retry_date: user.subscription.next_retry_date
                  ? new Date(user.subscription.next_retry_date)
                  : null,
                next_bill_date: user.subscription.next_bill_date
                  ? new Date(user.subscription.next_bill_date)
                  : null,
                cancellation_effective_date: user.subscription.cancellation_effective_date
                  ? new Date(user.subscription.cancellation_effective_date)
                  : null,
                voucher_used_at: user.subscription.voucher_used_at
                  ? new Date(user.subscription.voucher_used_at)
                  : null,
              }
            : null,
        };
      }
      return state;

    case USER_REFRESH_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        subscription: state.subscription
          ? { ...state.subscription, loading: false, error: action.payload.error }
          : null,
      };

    case USER_SETTINGS_CHANGE_START:
    case USER_SETTINGS_CHANGE_SUCCESS:
      return { ...state, ...action.payload };
    case USER_SETTINGS_CHANGE_LOCAL:
      return { ...state, settings: { ...state.settings, ...action.payload } };

    case USER_COOKIE_CONSENT:
      return { ...state, cookie_consent: action.payload };
    case USER_SETTINGS_CHANGE_ERROR:
    default:
      return state;
  }
}

// node modules
import React, { PureComponent } from 'react';

// app modules: components
import Generic from 'components/Error/Generic';
import Link from 'components/Link';

class CollectionUnauthenticatedError extends PureComponent {
  render() {
    return (
      <Generic>
        <p>
          This private collection is only accessible by the owner - if you are the owner, please{' '}
          <Link to="#login" className="link link--energized">
            login
          </Link>.
        </p>
      </Generic>
    );
  }
}

export default CollectionUnauthenticatedError;

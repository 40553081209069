/* eslint-disable camelcase */
import qs from 'qs';
import { ROUTE_TRANSITION_START, ROUTE_TRANSITION_END, CONFIGURE_NAVIGATION } from '../constants';

export default function routerReducers(state = {}, action) {
  // Don't flip the initialPage flag for the initial redirect from /w/
  const isInitialPage =
    action.payload?.pathname?.startsWith('/w/') ||
    action.payload?.pathname === state.initialPathname
      ? state.isInitialPage
      : false;
  switch (action.type) {
    case ROUTE_TRANSITION_START:
      return Object.assign({}, state, {
        state: 'loading',
        isInitialPage,
        locationState: action.payload.state || {},
        queryParams: qs.parse(
          action.payload.search ? action.payload.search.replace(/^\?/, '') : {},
        ),
      });

    case ROUTE_TRANSITION_END:
      return Object.assign({}, state, {
        pathname: action.payload.pathname,
        search: action.payload.search,
        hash: action.payload.hash,
        // Retain state when completing navigation to /search.
        // This is because we push the search url into the history upon navigation to a result.
        // This means that there are 2 transitions running simultaneously, and the /search typically empty
        // state will clobber the useful state passed into the recipe link (used to pass image hints).
        locationState:
          action.payload.pathname === '/search' ? state.locationState : action.payload.state || {},
        state: 'loaded',
        isInitialPage,
        activeSection:
          action.payload.state && action.payload.state.section
            ? action.payload.state.section
            : state.activeSection,
      });

    case CONFIGURE_NAVIGATION:
      return Object.assign({}, state, { navigation: action.payload });

    default:
      return state;
  }
}

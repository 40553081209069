exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".notification-button{position:relative;transform-origin:50% 4px}.notification-button__icon{margin:5px 5px 0}.notification-button__icon--open{color:#fa6422 !important}.notification-button__icon--closed{color:#000}@media (prefers-color-scheme: dark){:root:not([data-theme=light]):not([data-theme=dark]) .notification-button__icon--closed{color:#fff}}:root[data-theme=dark] .notification-button__icon--closed{color:#fff}.notification-button--ringing{animation:ring 4s 0.7s ease-in-out 1}.notification-button__unread{align-items:flex-start;display:flex;flex-direction:row;height:100%;justify-content:flex-end;position:absolute;right:5px;top:5px;width:100%}.notification-button__dot{background-color:#fa6422;border-radius:50%;height:10px;width:10px}@keyframes ring{0%{transform:rotate(0)}1%{transform:rotate(30deg)}3%{transform:rotate(-28deg)}5%{transform:rotate(34deg)}7%{transform:rotate(-32deg)}9%{transform:rotate(30deg)}11%{transform:rotate(-28deg)}13%{transform:rotate(26deg)}15%{transform:rotate(-24deg)}17%{transform:rotate(22deg)}19%{transform:rotate(-20deg)}21%{transform:rotate(18deg)}23%{transform:rotate(-16deg)}25%{transform:rotate(14deg)}27%{transform:rotate(-12deg)}29%{transform:rotate(10deg)}31%{transform:rotate(-8deg)}33%{transform:rotate(6deg)}35%{transform:rotate(-4deg)}37%{transform:rotate(2deg)}39%{transform:rotate(-1deg)}41%{transform:rotate(1deg)}43%{transform:rotate(0)}100%{transform:rotate(0)}}\n", ""]);

// exports
exports.locals = {
	"notification-button": "notification-button",
	"notification-button__icon": "notification-button__icon",
	"notification-button__icon--open": "notification-button__icon--open",
	"notification-button__icon--closed": "notification-button__icon--closed",
	"notification-button--ringing": "notification-button--ringing",
	"ring": "ring",
	"notification-button__unread": "notification-button__unread",
	"notification-button__dot": "notification-button__dot"
};
/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  ADD_FAVOURITE_CONTENT,
  REMOVE_FAVOURITE_CONTENT,
  FAVOURITE_CONTENT_SAVE_ERROR,
  FAVOURITES_GET_START,
  FAVOURITES_GET_SUCCESS,
  FAVOURITES_GET_ERROR,
} from '../constants';

const FAVOURITE_LIMIT_PER_QUERY = 1000;

const QUERIES = {
  all: gql`
    query GetAllFavourites($limit: Int) {
      FavouriteRecipes(limit: $limit) {
        id
      }
      FavouriteReferences(limit: $limit) {
        id
      }
      FavouriteSections(limit: $limit) {
        id
      }
      FavouriteBooks(limit: $limit) {
        isbn
      }
      FavouritePeople(limit: $limit) {
        id
      }
      FavouriteCollections(limit: $limit) {
        id
      }
    }
  `,
  recipe: gql`
    query GetFavouriteRecipes($limit: Int) {
      FavouriteRecipes(limit: $limit) {
        id
      }
    }
  `,
  reference: gql`
    query GetFavouriteReferences($limit: Int) {
      FavouriteReferences(limit: $limit) {
        id
      }
    }
  `,
  section: gql`
    query GetFavouriteSections($limit: Int) {
      FavouriteSections(limit: $limit) {
        id
      }
    }
  `,
  book: gql`
    query GetFavouriteBooks($limit: Int) {
      FavouriteBooks(limit: $limit) {
        isbn
      }
    }
  `,
  person: gql`
    query GetFavoritePeople($limit: Int) {
      FavouritePeople(limit: $limit) {
        id
      }
    }
  `,
  collection: gql`
    query GetFavouriteCollections($limit: Int) {
      FavouriteCollection(limit: $limit) {
        id
      }
    }
  `,
};

export function favouritesGetStart(contentType) {
  return {
    type: FAVOURITES_GET_START,
    payload: {
      contentType,
    },
  };
}

export function favouritesGetSuccess(contentType, results) {
  return {
    type: FAVOURITES_GET_SUCCESS,
    payload: { contentType, results },
  };
}

export function favouritesGetError(contentType, error) {
  return {
    type: FAVOURITES_GET_ERROR,
    payload: { contentType, error },
  };
}

export function favouriteContentAdded(contentId, contentType) {
  return {
    type: ADD_FAVOURITE_CONTENT,
    payload: {
      contentId,
      contentType,
    },
  };
}

export function favouriteContentRemoved(contentId, contentType) {
  return {
    type: REMOVE_FAVOURITE_CONTENT,
    payload: {
      contentId,
      contentType,
    },
  };
}

export function favouriteContentSaveErrored(contentId, contentType, error) {
  return {
    type: FAVOURITE_CONTENT_SAVE_ERROR,
    payload: {
      contentId,
      contentType,
      error,
    },
  };
}

export function getFavouriteContent(contentType) {
  return async (dispatch, getState, { client }) => {
    dispatch(favouritesGetStart(contentType));

    try {
      const response = await client.query({
        query: QUERIES[contentType || 'all'],
        variables: {
          limit: FAVOURITE_LIMIT_PER_QUERY,
          _authenticated: true,
        },
        fetchPolicy: 'network-only',
      });
      return dispatch(favouritesGetSuccess(contentType, response.data));
    } catch (error) {
      return dispatch(favouritesGetError(contentType, error));
    }
  };
}

export function addFavouriteContent(contentId, contentHash, contentType) {
  return async (dispatch, getState, { client, i18n }) => {
    // Optimistic update
    dispatch(favouriteContentAdded(contentId, contentType));
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation AddFavourite(
            $contentId: String!
            $contentHash: String!
            $contentType: ContentType!
          ) {
            AddFavouriteContent(
              contentId: $contentId
              contentHash: $contentHash
              contentType: $contentType
            )
          }
        `,
        variables: { contentId, contentType, contentHash },
      });
      if (response.errors && response.errors.length > 0) {
        dispatch(favouriteContentRemoved(contentId, contentType));
        dispatch(
          favouriteContentSaveErrored(contentId, contentType, response.errors[0].graphQLErrors[0]),
        );
      } else {
        toast.success(`Added to ${i18n.t('favorites')}`);
      }
    } catch (error) {
      dispatch(favouriteContentRemoved(contentId, contentType));
      dispatch(favouriteContentSaveErrored(contentId, contentType, error));
      toast.error(`Could not add to ${i18n.t('favorites')}. Please try again`);
    }
  };
}

export function removeFavouriteContent(contentId, contentType) {
  return async (dispatch, getState, { client, i18n }) => {
    dispatch(favouriteContentRemoved(contentId, contentType));
    try {
      const response = await client.mutate({
        mutation: gql`
          mutation RemoveFavouriteContent($contentId: String!, $contentType: ContentType!) {
            RemoveFavouriteContent(contentId: $contentId, contentType: $contentType)
          }
        `,
        variables: { contentId, contentType },
      });
      if (response.errors && response.errors.length > 0) {
        dispatch(favouriteContentAdded(contentId, contentType));
        dispatch(
          favouriteContentSaveErrored(contentId, contentType, response.errors[0].graphQLErrors[0]),
        );
      } else {
        toast.success(`Removed from ${i18n.t('favorites')}`);
      }
    } catch (error) {
      dispatch(favouriteContentAdded(contentId, contentType));
      dispatch(favouriteContentSaveErrored(contentId, contentType, error));
      toast.error(`Could not remove from ${i18n.t('favorites')}. Please try again`);
    }
  };
}

// node modules
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet-async';
import { LIVE_SITE_URL } from 'constants/index';

const StructuredDataProps = PropTypes.shape({
  '@context': PropTypes.string,
  '@type': PropTypes.string,
  name: PropTypes.string,
  author: PropTypes.arrayOf(PropTypes.object),
  url: PropTypes.string,
  image: PropTypes.array,
  isAccessibleForFree: PropTypes.string,
  hasPart: PropTypes.shape({
    '@type': PropTypes.string,
    isAccessibleForFree: PropTypes.string,
    cssSelector: PropTypes.string,
  }),
  workExample: PropTypes.arrayOf(
    PropTypes.shape({
      '@type': PropTypes.string,
      bookFormat: PropTypes.array,
      isbn: PropTypes.string,
      potentialAction: PropTypes.shape({
        '@type': PropTypes.string,
        target: PropTypes.shape({
          '@type': PropTypes.string,
          urlTemplate: PropTypes.string,
        }),
        expectsAcceptanceOf: PropTypes.object,
      }),
    }),
  ),
});
class HeadContent extends Component {
  static propTypes = {
    contents: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      content: PropTypes.string,
      largeImage: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      url: PropTypes.string,
      robots: PropTypes.bool,
      common: PropTypes.bool,
      structuredData: PropTypes.oneOfType([
        PropTypes.string,
        StructuredDataProps,
        PropTypes.arrayOf(StructuredDataProps),
      ]),
    }),
    baseUrl: PropTypes.string.isRequired,
    facebookId: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  };

  static defaultProps = {
    contents: {},
  };

  componentDidUpdate(prevProps) {
    const { contents } = this.props;
    if (
      contents?.title &&
      contents.title !== prevProps?.contents?.title &&
      window?.cordova?.plugins?.firebase?.analytics
    ) {
      window.cordova.plugins.firebase.analytics.setCurrentScreen(contents?.title);
    }
  }

  fixUrl = url => {
    const { baseUrl } = this.props;
    return /^http[s]?:\/\//.test(url) ? url : `${baseUrl}${url}`;
  };

  renderCommonTags() {
    const { common, url } = this.props.contents;
    const { facebookId } = this.props;
    const { baseUrl, pathname } = this.props;

    const canonicalUrl = url ? this.fixUrl(url) : `${baseUrl}${pathname}`;

    if (common) {
      return (
        <Helmet>
          <meta name="site_name" content="ckbk" />
          <meta property="og:site_name" name="site_name" content="ckbk" />
          <meta property="og:type" content="website" />
          <meta name="twitter:site" content="@ckbkapp" />
          <meta property="fb:app_id" content={facebookId} />
          <meta name="url" content={canonicalUrl} />
          <meta property="og:url" content={canonicalUrl} />
        </Helmet>
      );
    }
    return null;
  }

  renderRobotsTag() {
    const { baseUrl } = this.props;
    const { robots } = this.props.contents;

    if (robots) {
      // For the live site, allow the site to be indexed but don't display the cached link.
      if (baseUrl === LIVE_SITE_URL) {
        return (
          <Helmet>
            <meta name="robots" content="noarchive, max-image-preview:large" />
          </Helmet>
        );
      }
      // Don't allow demo env to be indexed.
      return (
        <Helmet>
          <meta name="robots" content="none" />
        </Helmet>
      );
    }

    return null;
  }

  renderTitleTags() {
    const { title } = this.props.contents;

    if (title) {
      return (
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </Helmet>
      );
    }
    return null;
  }

  renderDescriptionTags() {
    const { description } = this.props.contents;

    if (description) {
      return (
        <Helmet>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </Helmet>
      );
    }
    return null;
  }

  renderImageContentTags() {
    const { content, width, height, largeImage } = this.props.contents;
    if (content) {
      const contentUrl = this.fixUrl(content);
      return (
        <Helmet>
          {largeImage ? <meta name="twitter:card" content="summary_large_image" /> : null}
          <meta name="twitter:image" content={this.fixUrl(largeImage || content)} />
          <meta name="image" content={contentUrl} />
          <meta property="og:image" content={contentUrl} />
          <meta name="image:height" content={height} />
          <meta property="og:image:height" content={height} />
          <meta name="image:width" content={width} />
          <meta property="og:image:width" content={width} />
        </Helmet>
      );
    }
    return null;
  }

  renderStructuredDataTag() {
    const { structuredData } = this.props.contents;

    if (structuredData) {
      if (Array.isArray(structuredData)) {
        return (
          <Helmet>
            {structuredData.map(data => (
              <script type="application/ld+json">
                {typeof data === 'string' ? data : JSON.stringify(data)}
              </script>
            ))}
          </Helmet>
        );
      }
      const structuredDataString =
        typeof structuredData === 'string' ? structuredData : JSON.stringify(structuredData);
      return (
        <Helmet>
          <script type="application/ld+json">{structuredDataString}</script>
        </Helmet>
      );
    }
    return null;
  }

  renderUrlTags() {
    const { url } = this.props.contents;
    if (url) {
      const fixedUrl = this.fixUrl(url);
      return (
        <Helmet>
          <meta name="url" content={fixedUrl} />
          <meta property="og:url" content={fixedUrl} />
        </Helmet>
      );
    }
    return null;
  }

  renderCanonicalUrl() {
    const { url } = this.props.contents;
    if (url) {
      return (
        <Helmet>
          <link rel="canonical" href={this.fixUrl(url)} />
        </Helmet>
      );
    }
    return null;
  }

  render() {
    const titleTags = this.renderTitleTags();
    const descriptionTags = this.renderDescriptionTags();
    const imageContentTags = this.renderImageContentTags();
    const structuredDataTag = this.renderStructuredDataTag();
    const robotsMetaTag = this.renderRobotsTag();
    const commonTags = this.renderCommonTags();
    const urlTags = this.renderUrlTags();
    const canonicalUrl = this.renderCanonicalUrl();

    return (
      <Fragment>
        {commonTags}
        {titleTags}
        {descriptionTags}
        {imageContentTags}
        {structuredDataTag}
        {robotsMetaTag}
        {urlTags}
        {canonicalUrl}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  baseUrl: state.config.baseUrl,
  pathname: state.router.pathname,
  facebookId: state.config.facebookId,
});

export default connect(
  mapStateToProps,
  null,
)(HeadContent);

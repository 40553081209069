/* eslint-disable no-param-reassign */
import { gql } from '@apollo/client';
import {
  USER_COLLECTIONS_GET_START,
  USER_COLLECTIONS_GET_SUCCESS,
  USER_COLLECTIONS_GET_ERROR,
} from 'constants/index';
import { genericAction } from 'actions/generic';
import CollectionFields from './CollectionFields.graphql';

const QUERY = {
  GET_COLLECTIONS: gql`
    query GetCollections($userId: Int!) {
      Collections(userId: $userId) {
        ...CollectionFields
      }
    }
    ${CollectionFields}
  `,
};

export default function getCollections(userId) {
  return genericAction({
    action: ({ client }) =>
      client.query({
        query: QUERY.GET_COLLECTIONS,
        fetchPolicy: 'network-only',
        variables: { userId },
      }),
    params: {},
    payload: data => {
      const collections = data.Collections.reduce((output, collection) => {
        output[`collection-${collection.id}`] = Object.assign({}, collection, {
          state: 'loaded',
        });

        return output;
      }, {});

      return {
        collections,
      };
    },
    states: {
      start: USER_COLLECTIONS_GET_START,
      success: USER_COLLECTIONS_GET_SUCCESS,
      error: USER_COLLECTIONS_GET_ERROR,
    },
  });
}

/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import { isApp } from 'services/environment';
import IntercomForApp from './IntercomForApp';
import IntercomForWeb from './IntercomForWeb';

function Intercom() {
  const user = useSelector(state => state.user);
  const featureUsage = useSelector(state => state.featureUsage.usage);

  let userData = {};
  if (user.logged_in && user.id > 0) {
    userData = {
      email: user.email,
      name: user.display_name,
      user_id: user.id,
      membership_status: user.subscription_status,
    };

    Object.keys(featureUsage).forEach(key => {
      if (featureUsage[key] === true) {
        const featureFlag = {};
        featureFlag[key] = true;
        Object.assign(userData, featureFlag);
      }
    });

    if (user.subscription) {
      const {
        status,
        next_bill_date,
        cancellation_effective_date,
        is_valid,
        provider,
        billing_frequency,
        currency,
        coupon,
        next_payment_amount,
        description,
        partner,
        voucher_code,
        voucher_duration,
        voucher_used_at,
      } = user.subscription;

      Object.assign(userData, {
        sales_partner: partner,
        billing_frequency,
        next_payment_amount,
        coupon,
        currency,
        description,
        subscription_provider: provider,
        subscription_status: status,
        subscription_is_valid: is_valid,
        next_bill_at: next_bill_date ? next_bill_date.getTime() / 1000 : null,
        cancellation_effective_at: cancellation_effective_date
          ? cancellation_effective_date.getTime() / 1000
          : null,
        voucher: voucher_code,
        voucher_duration,
        voucher_used_at: voucher_used_at ? voucher_used_at.getTime() / 1000 : null,
      });
      if (typeof window !== 'undefined') {
        if (window.App.version !== '0.0.0') {
          Object.assign(userData, { app_version: window.App.version });
        }
        if (window.App.build !== '0') {
          Object.assign(userData, { app_build: window.App.build });
        }
      }
    }
  }

  if (isApp()) {
    return <IntercomForApp userData={userData} loggedIn={user.logged_in} />;
  }
  return <IntercomForWeb userData={userData} loggedIn={user.logged_in} />;
}

export default Intercom;

/* eslint-disable camelcase */
import React from 'react';
import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

// actions
import { applyProduct } from 'actions/paywall';

// components
import Link from 'components/Link';
import ToastContents from 'components/ToastContents';

// services
import history from 'services/history';
import { voucherClaim } from 'services/route';

// constants
import {
  USER_CLAIM_VOUCHER_START,
  USER_CLAIM_VOUCHER_SUCCESS,
  USER_CLAIM_VOUCHER_ERROR,
  USER_CLAIM_VOUCHER_CHECKOUT,
  MARKETING_NUMBER_OF_RECIPES,
} from 'constants/index';
import SubscriptionFields from './SubscriptionFields.graphql';

const PENDING_VOUCHER_CHECKOUT_KEY = 'pending_voucher_checkout';
const PENDING_VOUCHER_CHECKOUT_EXPIRY = 24 * 60 * 60 * 1000; // 1 day

const MUTATION = gql`
  mutation ClaimVoucher($code: String) {
    VoucherClaim(code: $code) {
      is_extension
      subscription {
        ...SubscriptionFields
      }
      user {
        home_connect_enabled
        oven_brands
        subscription_status
      }
      checkout {
        passthrough
        annual {
          paddleProductId
        }
        monthly {
          paddleProductId
        }
      }
    }
  }
  ${SubscriptionFields}
`;

export function executeCheckoutVoucher(code, checkout) {
  return async (dispatch, getState) => {
    const { user, paywall } = getState();
    if (!user.logged_in) {
      return;
    }
    dispatch(applyProduct(checkout.monthly.paddleProductId));
    if (!/^(?:\/w)?\/claim/.test(history.location.pathname)) {
      history.push(voucherClaim(code, paywall.partner), {
        returnTo: history.location,
      });
    }
    try {
      localStorage.setItem(
        PENDING_VOUCHER_CHECKOUT_KEY,
        JSON.stringify({ checkout, code, stored_at: Date.now() }),
      );
    } catch (err) {
      console.error('Could not save the voucher checkout config', err);
    }
    dispatch({
      type: USER_CLAIM_VOUCHER_CHECKOUT,
      payload: { checkout, code },
    });
  };
}

export default function claimVoucher(code, options = {}) {
  return async (dispatch, getState, { client }) => {
    const { user } = getState();
    if (!user.logged_in) {
      console.warn('Voucher claimant must be logged in');
      return;
    }
    dispatch({
      type: USER_CLAIM_VOUCHER_START,
      payload: { code },
    });
    const toastSettings = {
      position: options.position || toast.POSITION.TOP_RIGHT,
      autoClose: 8000,
      hideProgressBar: true,
    };
    let toastId;
    if (!options.silent) {
      const message = options.message || `Claiming voucher ${code}...`;
      toastId = toast.success(<ToastContents>{message}</ToastContents>, toastSettings);
    }

    try {
      const { data, errors } = await client.mutate({
        mutation: MUTATION,
        variables: { code },
      });

      if (errors && errors.length > 0) {
        dispatch({
          type: USER_CLAIM_VOUCHER_ERROR,
          payload: {
            error: errors[0].graphQLErrors[0].message,
          },
        });
        if (typeof options.onError === 'function') {
          options.onError(errors[0].graphQLErrors[0].message);
        }
        if (options.removeVoucherFromUrl !== false) {
          history.replace({ hash: '' });
        }
        if (toastId) {
          toast.update(toastId, {
            type: toast.TYPE.ERROR,
            render: <ToastContents>{errors[0].graphQLErrors[0].message}</ToastContents>,
          });
        }
      } else {
        const { subscription, is_extension, checkout } = data.VoucherClaim;
        if (typeof options.onComplete === 'function') {
          options.onComplete();
        }
        if (checkout) {
          if (toastId) {
            toast.dismiss(toastId);
          }
          dispatch(executeCheckoutVoucher(code, checkout));
        } else {
          dispatch({
            type: USER_CLAIM_VOUCHER_SUCCESS,
            payload: { ...data.VoucherClaim, code },
          });
          if (is_extension) {
            const toastContents = (
              <ToastContents headline="Voucher code accepted">
                <Link to="/user/subscription" className="link--underline">
                  Your subscription
                </Link>{' '}
                has been extended until{' '}
                {dayjs(subscription.cancellation_effective_date).format('D MMM YYYY')}. Happy
                cooking!
              </ToastContents>
            );
            if (toastId) {
              toast.update(toastId, {
                type: toast.TYPE.DEFAULT,
                render: toastContents,
              });
            } else {
              toast.success(toastContents, toastSettings);
            }
          } else {
            const toastContents = (
              <ToastContents headline="You are now a ckbk Premium Member">
                You are now free to explore over{' '}
                <Link to="/search?q=recipes" className="link--energized">
                  {MARKETING_NUMBER_OF_RECIPES} recipes
                </Link>{' '}
                from the world&apos;s best{' '}
                <Link to="/books" className="link--energized">
                  cookbooks
                </Link>. Happy cooking!
              </ToastContents>
            );
            if (toastId) {
              toast.update(toastId, {
                type: toast.TYPE.SUCCESS,
                render: toastContents,
              });
            } else {
              toast.success(toastContents, toastSettings);
            }
          }
          if (options.removeVoucherFromUrl !== false) {
            history.replace({
              search: history.location.search.replace(`voucher=${encodeURIComponent(code)}`, ''),
            });
          }
        }
      }
    } catch (error) {
      dispatch({
        type: USER_CLAIM_VOUCHER_ERROR,
        payload: {
          error,
        },
      });
      if (options.removeVoucherFromUrl !== false) {
        history.replace({ hash: '' });
      }
      const niceError =
        error.graphQLErrors && error.graphQLErrors.length
          ? error.graphQLErrors[0].message
          : 'Something went wrong';
      if (typeof options.onError === 'function') {
        options.onError(niceError);
      }
      if (toastId) {
        toast.update(toastId, {
          type: toast.TYPE.ERROR,
          render: <ToastContents>{niceError}</ToastContents>,
        });
      }
    }
  };
}

export function restoreVoucherCheckout() {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!user.logged_in) {
      return;
    }

    let pendingVoucherCheckout;
    try {
      pendingVoucherCheckout = JSON.parse(localStorage.getItem(PENDING_VOUCHER_CHECKOUT_KEY));
    } catch (err) {
      console.error('Could not restore voucher checkout from localStorage', err);
    }
    if (pendingVoucherCheckout) {
      const hasExpired =
        Date.now() - pendingVoucherCheckout.stored_at > PENDING_VOUCHER_CHECKOUT_EXPIRY;
      if (hasExpired) {
        try {
          localStorage.removeItem(PENDING_VOUCHER_CHECKOUT_KEY);
        } catch (err) {
          // Ignore
        }
      } else {
        dispatch({
          type: USER_CLAIM_VOUCHER_CHECKOUT,
          payload: pendingVoucherCheckout,
        });
        dispatch(applyProduct(pendingVoucherCheckout.checkout.monthly.paddleProductId));
      }
    }
  };
}
